import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'

export default function ProfileMenu() {
  const [menu, setMenu] = useState(false);
  return (
    <div>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle caret className="btn header-item waves-effect" style={{backgroundColor: 'rgba(0, 0, 0, 0)'}}>
          <i className="mdi mdi-account-outline" style={{fontSize: 24}}></i>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <Link to="/logout">
            <DropdownItem>
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
              <span>Logout</span>
            </DropdownItem>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
