import React from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ActionCreators} from "../../actions/index";
import {pdfRawHtml} from "./pdfRawCannabisHtml";
import Helmet from "react-helmet";
import {getMemoryString, getAkt1String, getMetabolismString, renderCBDG} from "../../lib/genetics-helper";

const mapStateToProps = state => ({
  user: state.LoginStatus,
  surveys: state.Surveys,
  testResults: state.TestResults,
  initialLoad: state.InitialLoad
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function CannabisPdfReport() {
  const {search} = useLocation();
  const rawData = new URLSearchParams(search).get('data');
  const data = JSON.parse(rawData);
  const cyp2c19Obj = renderCBDG(data);
  const cyp2c9Obj = getMetabolismString(data);
  const akt1Obj = getAkt1String(data);
  const comtObj = getMemoryString(data);

  const display = {
    kitId: data.swab,
    date: data.date,
    initials: data.initials,
    location: data.location,
  };

  let html = pdfRawHtml();
  html = html.replaceAll('assets/', '/test/assets/');
  //Replace variable placeholders in html
  html = html.replaceAll('{{{display.kitId}}}', display.kitId);
  html = html.replaceAll('{{{display.date}}}', display.date);
  html = html.replaceAll('{{{display.initials}}}', display.initials);
  html = html.replaceAll('{{{display.location}}}', display.location);

  html = html.replaceAll('{{{display.cyp2c19.genotype}}}', cyp2c19Obj.genotype);
  html = html.replaceAll('{{{display.cyp2c19.genotypeDescriptive}}}', cyp2c19Obj.genotypeFull);
  html = html.replaceAll('{{{display.cyp2c19.phenotype}}}', cyp2c19Obj.phenotype);
  html = html.replaceAll('{{{display.cyp2c19.result}}}', cyp2c19Obj.short);
  html = html.replaceAll('{{{display.cyp2c19.resultDescriptive}}}', cyp2c19Obj.descriptive);
  html = html.replaceAll('{{{display.cyp2c19.icon}}}', cyp2c19Obj.short === 'Slow' || cyp2c19Obj.short === 'Very Slow' ? '<i class="fa fa-arrow-circle-o-down"></i>' : cyp2c19Obj.short === 'Increased' ? '<i class="fa fa-arrow-circle-o-up"></i>' : '');

  html = html.replaceAll('{{{display.cyp2c9.genotype}}}', cyp2c9Obj.genotype);
  html = html.replaceAll('{{{display.cyp2c9.genotypeDescriptive}}}', cyp2c9Obj.genotypeFull);
  html = html.replaceAll('{{{display.cyp2c9.phenotype}}}', cyp2c9Obj.phenotype);
  html = html.replaceAll('{{{display.cyp2c9.result}}}', cyp2c9Obj.short);
  html = html.replaceAll('{{{display.cyp2c9.resultDescriptive}}}', cyp2c9Obj.descriptive);
  html = html.replaceAll('{{{display.cyp2c9.icon}}}', cyp2c9Obj.short === 'Slow' || cyp2c9Obj.short === 'Very Slow' ? '<i class="fa fa-arrow-circle-o-down"></i>' : '');

  html = html.replaceAll('{{{display.comt.genotype}}}', comtObj.genotype);
  html = html.replaceAll('{{{display.comt.genotypeDescriptive}}}', comtObj.genotypeFull);
  html = html.replaceAll('{{{display.comt.result}}}', comtObj.short);
  html = html.replaceAll('{{{display.comt.resultDescriptive}}}', comtObj.descriptive);
  html = html.replaceAll('{{{display.comt.icon}}}', comtObj.short === 'Higher' ? '<i class="fa fa-arrow-circle-o-up"></i>' : '');

  html = html.replaceAll('{{{display.akt1.genotype}}}', akt1Obj.genotype);
  html = html.replaceAll('{{{display.akt1.genotypeDescriptive}}}', akt1Obj.genotypeFull);
  html = html.replaceAll('{{{display.akt1.result}}}', akt1Obj.short);
  html = html.replaceAll('{{{display.akt1.resultDescriptive}}}', akt1Obj.descriptive);
  html = html.replaceAll('{{{display.akt1.icon}}}', akt1Obj.short === 'Higher' ? '<i class="fa fa-arrow-circle-o-up"></i>' : '');

  ReactDOM.render(
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
        <title>HaluGen Psychedelics Genetic Test Report</title>
        <meta property="og:title" content="HaluGen Psychedelics Genetic Test Report" />
        <meta name="description" content="Psychedelics Genetic Test Report" />
        <meta property="og:type" content="website" />
        <link rel="icon" type="image/png" sizes="80x80" href="/test/assets/img/favicon.ico.png" />
        <link rel="stylesheet" href="/test/assets/bootstrap/css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins&amp;display=swap" />
        <link rel="stylesheet" href="/test/assets/fonts/fontawesome-all.min.css" />
        <link rel="stylesheet" href="/test/assets/fonts/font-awesome.min.css" />
        <link rel="stylesheet" href="/test/assets/fonts/fontawesome5-overrides.min.css" />
        <link rel="stylesheet" href="/test/assets/css/cannabis-styles-print-report.css" />
        <link rel="stylesheet" href="/test/assets/css/cannabis-styles.css" />
      </Helmet>
      <div dangerouslySetInnerHTML={{__html: html}} />
    </>
  , document.getElementById('root'));
  return false;
}

const cannabisPdf = connect(mapStateToProps, mapDispatchToProps)(CannabisPdfReport);

export default cannabisPdf
