import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { CenteredModal, setModal } from '../../components/Common/Modals';
import { EditPatientForm } from '../../components/Form/Forms';
import { TrialTabs } from '../../components/Trials/Tabs';

function TestResults() {
  const dispatch = useDispatch();
  const { trials } = useSelector(state => state.Trials);
  const { modals } = useSelector(state => state.Flags);
  return (
    <div className="page-content">
      <div className="pt-5">
        <Row>
          <Col>
            <h3 className="mb-5 mt-0">Test Results</h3>
          </Col>
        </Row>
        <Row>
          <Col xl="12" className="mt-4">
            <TrialTabs trials={trials} tables={['patients']} formModals={[]} />
          </Col>
        </Row>
      </div>
      <CenteredModal title="Create a new group" modals={modals} setModal={setModal} type="createTrial">
        <EditPatientForm trialId={trials.id} closeModal={() => setModal(dispatch, modals, 'createTrial', false)} />
      </CenteredModal>
    </div>
  );
}

export default withNamespaces()(TestResults);
