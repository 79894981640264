import {combineReducers} from 'redux'
import * as StartupReducers from './startup'
import * as AuthReducers from './auth'
import * as LayoutReducer from './layout'
import * as TrialsReducer from './trials'
import * as FlagsReducer from './flags'

export default combineReducers(
	Object.assign(
		StartupReducers,
		AuthReducers,
		LayoutReducer,
    TrialsReducer,
    FlagsReducer
	)
);
