import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { store } from "../store/store";
import { RefreshDataContext } from '../App';

function AppRoute({component: Component, layout: Layout, isAuthProtected, ...rest}) {
  const [ refreshData, setRefreshData, reportCanvas, setReportCanvas ] = useContext(RefreshDataContext);
  const reportCanvasStyle = {
    minHeight: "100%",
    width: "100%",
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 9999999,
    display: reportCanvas === "" ? "none" : "block"
  };

  return <Route
    {...rest}
    render={props => {
      function isValidUserJWT() {
        let token = store.getState().UserAccessToken;
        if(token && token.token) token = token.token;
        if (token && !(typeof token === 'object' && !Object.keys(token).length)) {
          const decodedJwt = jwtDecode(token);
          if (decodedJwt.exp * 1000 >= (new Date()).getTime()) {
          return true;
          }
        }
        return false;
      }
      if(isAuthProtected && !isValidUserJWT()){
        return (
          <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
        )
      }
      return (
        <>
          <Layout>
            <Component {...props} />
          </Layout>
          <div key="reportCanvas" id="reportCanvas" style={reportCanvasStyle} onClick={() => setReportCanvas('')}>
            {reportCanvas}
          </div>
        </>
      );
    }}
  />
}

export default AppRoute;
