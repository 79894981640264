import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Media, Row, Button } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { actionTabs } from '../../actions/flags';
import { RefreshDataContext } from '../../App';

function Dashboard() {
  const { trials, counts } = useSelector(state => state.Trials);
  const { dashboard } = counts;
  const allTrialLocations = [].concat.apply([], trials.map(t => t.trialLocations)).map(t => t.name);
  const allTrialLocationsNoDuplicates = Array.from(new Set(allTrialLocations));
  return (
    <div className="page-content">
      <div className="pt-5">
        <Row>
          <Col xl="12">
            <h3 className="mb-3">Overview</h3>
            <OverviewCards
              numberOfLocations={allTrialLocationsNoDuplicates.length}
              numberOfPatients={dashboard.patientCount}
              numberOfResults={dashboard.testResults}
              numberOfActiveScanners={dashboard.activeScanner}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

function OverviewCards(props) {
  const [ refreshData, setRefreshData, reportCanvas, setReportCanvas, triggerNewTrialModal, setTriggerNewTrialModal ] = useContext(RefreshDataContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { numberOfLocations, numberOfPatients, numberOfResults, numberOfActiveScanners } = props;
  return (
    <>
      <Row>
        <Col md="4" className='p-0 px-sm-2'>
          <Link to="/groups">
            <Card className="mini-stats-wid mb-2">
              <CardBody>
                <Media>
                  <Media body>
                    <p className="text-muted font-weight-medium">Number of locations</p>
                    <h4 className="mb-0">{numberOfLocations}</h4>
                  </Media>
                  <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                    <span className="avatar-title" style={{background: "linear-gradient(315deg, #3264F5, #7202BB)"}}>
                      <i className={"mdi mdi-adjust font-size-24"}></i>
                    </span>
                  </div>
                </Media>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col md="4" className='p-0 px-sm-2'>
          <Link to="/groups">
            <Card className="mini-stats-wid mb-2">
              <CardBody>
                <Media>
                  <Media body>
                    <p className="text-muted font-weight-medium">Number of subjects</p>
                    <h4 className="mb-0">{numberOfPatients}</h4>
                  </Media>
                  <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                    <span className="avatar-title" style={{background: "linear-gradient(315deg, #3264F5, #7202BB)"}}>
                      <i className={"mdi mdi-account-multiple font-size-24"}></i>
                    </span>
                  </div>
                </Media>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col md="4" className='p-0 px-sm-2'>
          <Link to="/test-results">
            <Card className="mini-stats-wid mb-2">
              <CardBody>
                <Media>
                  <Media body>
                    <p className="text-muted font-weight-medium">Number of test results</p>
                    <h4 className="mb-0">{numberOfResults}</h4>
                  </Media>
                  <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                    <span className="avatar-title" style={{background: "linear-gradient(315deg, #3264F5, #7202BB)"}}>
                      <i className={"mdi mdi-file-document font-size-24"}></i>
                    </span>
                  </div>
                </Media>
              </CardBody>
            </Card>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className='p-2'>
          <Link to="/scanners">
            <Card className="mini-stats-wid mb-3">
              <CardBody>
                <Media>
                  <Media body>
                    <p className="text-muted font-weight-medium">Number of active scanners</p>
                    <h4 className="mb-0">{numberOfActiveScanners}</h4>
                  </Media>
                  <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                    <span className="avatar-title" style={{background: "linear-gradient(315deg, #3264F5, #7202BB)"}}>
                      <i className={"mdi mdi-image-filter-center-focus font-size-24"}></i>
                    </span>
                  </div>
                </Media>
              </CardBody>
            </Card>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className='p-2'>
          <Card className="mini-stats-wid mb-3">
            <CardBody>
              <Media>
                <Media body>
                  <p className="text-muted font-weight-medium">Getting started</p>
                  <h4>Add a new group</h4>
                  <p>Get started with your clinical research by adding a new group. A group can be a clinical trial name or your organization. After adding a new group, you can add in locations and assign scanners to register swab kits at each location.</p>
                  <Button color="primary" className="actionButtonFullWidth btn mb-4 py-1 px-2"
                    onClick={() => {
                      setTriggerNewTrialModal(true);
                      dispatch(actionTabs('groups', 'main', ''));
                      history.push('/groups');
                    }}
                  >Add a new group</Button>
                  <p>Need help? Check out our helpful resources.</p>
                  <ul>
                    <li><a target="_blank" href="https://static1.squarespace.com/static/5f11e8f9eef960672094d1e4/t/62053bee82881159111ee115/1644510192105/Clinical+Platform+Guide+-+Halugen+-+Admin.pdf">Clinical Platform Guide</a></li>
                    <li><a target="_blank" href="https://static1.squarespace.com/static/5f11e8f9eef960672094d1e4/t/62053c04b3f56046a4d38ff9/1644510213061/Clinical+scanner+Guide+-+Halugen+-+Scanner.pdf">Clinical Scanner Guide</a></li>
                    <li><a target="_blank" href="https://static1.squarespace.com/static/5f11e8f9eef960672094d1e4/t/5f7371ff7243ec111c87e9f4/1601401344101/Lobo+Genetics+-+Cannabis+Genetics+White+Paper.pdf">Cannabis White Paper</a></li>
                    <li><a target="_blank" href="https://static1.squarespace.com/static/5f11e8f9eef960672094d1e4/t/624323e2c60c090ff0d7ad8e/1648567267707/HaluGen-Psychedelics-and-Genetic-Pre-Screening-White-Paper.pdf">Psychedelics White Paper</a></li>
                  </ul>
                  <a href="mailto:clinical@lobogene.com">Contact Support</a>
                </Media>
                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                  <span className="avatar-title" style={{background: "linear-gradient(315deg, #3264F5, #7202BB)"}}>
                    <i className={"mdi mdi-flag font-size-24"}></i>
                  </span>
                </div>
              </Media>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default withNamespaces()(Dashboard);
