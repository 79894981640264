import createReducer from './createReducer'
import {translatePsychedelicApiToObj} from "../lib/translate";

export const Trials = createReducer({
  lastCreatedTrial: 0,
  lastCreatedScanner: 0,
  trials: [],
  tests: [],
  counts: {
    dashboard: {},
    patients: [],
  },
  swabHistory: {}
}, {
  // *** SCANNERS *** //
	'CREATE_SCANNER_SUCCESS': (state, action) => { return { ...state, lastCreatedScanner: action.payload.id || 0 } },
	'UPDATE_SCANNER_SUCCESS': (state) => { return { ...state } },
	'DELETE_SCANNER_SUCCESS': (state) => {return { ...state } },

  // *** LOCATIONS *** //
  'FETCH_SCANNER_LOCATIONS_SUCCESS': (state, action) => { return { ...state,
    scannerLocations: action.payload
  }},
  'FETCH_TRIAL_LOCATIONS_SUCCESS': (state, action) => { return { ...state,
    trialLocations: action.payload
  }},
	'CREATE_TRIAL_LOCATION_SUCCESS': (state) => { return { ...state } },
	'UPDATE_TRIAL_LOCATION_SUCCESS': (state) => { return { ...state } },
  'DELETE_TRIAL_LOCATION': (state) => { return { ...state } },

  // *** TRIALS *** //
  'FETCH_TRIALS_SUCCESS': (state, action) => {
    const trials = action.payload.sort((a, b) => (b.name.toLowerCase() < a.name.toLowerCase()) - (b.name.toLowerCase() > a.name.toLowerCase()));
    return { ...state,
      trials: trials
    }
  },
	'FETCH_TRIAL_SUCCESS': (state, action) => { return { ...state,
    trial: action.payload
  }},
	'CREATE_TRIAL_SUCCESS': (state, action) => { return { ...state, lastCreatedTrial: action.payload.id || 0 } },
	'UPDATE_TRIAL_SUCCESS': (state) => { return { ...state } },
	'DELETE_TRIAL_SUCCESS': (state) => { return { ...state } },

  // *** TESTS *** //
  'FETCH_SWAB_HISTORY_SUCCESS': (state, action) => { return { ...state,
    swabHistory: {
      ...action.payload,
      psychedelicsTests: action.payload.psychedelicsTests.map(st => translatePsychedelicApiToObj(st))
    }
  }},
  'FETCH_ALL_SWAB_HISTORY_SUCCESS': (state, action) => { return { ...state,
    swabHistory: {
      ...action.payload,
      psychedelicsTests: action.payload.psychedelicsTests.map(st => translatePsychedelicApiToObj(st))
    }
  }},
	'DELETE_TEST_SUCCESS': (state) => {return { ...state } },

  'CLEAR_SWAB_HISTORY_SUCCESS': (state, action) => { return { ...state,
    swabHistory: {}
  }},

  // *** OTHER *** //
  'FETCH_DASHBOARD_COUNTS_SUCCESS': (state, action) => { return { ...state,
    counts: {
      ...state.counts,
      dashboard: action.payload
    }
  }},
  'FETCH_PATIENT_COUNTS_SUCCESS': (state, action) => { return { ...state,
    counts: {
      ...state.counts,
      patients: action.payload
    }
  }},
  'RESET_USER_PASSWORD': (state) => { return { ...state } },
  'RESET_LAST_CREATED_TRIAL': (state) => { return { ...state,
    lastCreatedTrial: 0
  }},
  'RESET_LAST_CREATED_SCANNER': (state) => { return { ...state,
    lastCreatedScanner: 0
  }},
});
