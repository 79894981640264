import React from 'react';
import moment from 'moment';
import { mapGenotype } from '../Tables';

export default function CannabisReport({data, trial}) {

  const display = {
    trialName: trial.name,
    patientId: data.patientId,
    reportDate: data.reportDate ? moment(data.reportDate).format("MMM D, YYYY h:mm A") : 'n/a',
    testDate: data.testDate ? moment(data.testDate).format("MMM D, YYYY h:mm A") : 'n/a',
    trialLocation: (data.scannerLocations && data.scannerLocations.trialLocations && data.scannerLocations.trialLocations.name) || '',
    cyp2c9: {
      genotype: mapGenotype(data.cyp2c9),
      phenotype: 'Normal',
      summary: {
        title: 'Normal Metabolism',
        text: 'THC metabolized at a normal rate.'
      },
      image1: '/assets/img/metabolism-chart-duration-11.svg',
      image2: '/assets/img/metabolism-chart-blood-level-11.svg',
    },
    akt1:  {
      genotype: mapGenotype(data.akt1),
      phenotype: 'Average',
      summary: {
        title: 'Normal Mental Health Risk',
        text: 'At a normal risk for both short-term psychotic symptoms and long-term psychosis from THC.'
      },
      image1: '/assets/img/tt-risk.svg',
      image2: '/assets/img/tt-riskofpsychosis.svg',
    },
    comt:  {
      genotype: mapGenotype(data.comt),
      phenotype: 'Higher',
      summary: {
        title: 'Increased risk',
        text: 'At an increased risk for memory impairment when using THC.'
      },
      image1: '/assets/img/memory-chart-working-performance.svg',
    },
    cyp2c19:  {
      genotype: mapGenotype(data.cyp2c19),
      phenotype: 'Normal',
      summary: {
        title: 'Normal Metabolism',
        text: 'CBD metabolized at a normal rate.'
      },
      image1: '/assets/img/cbd-absorption.svg',
    },
  };

  return (<>
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
    />
    <title>Lobo Genetics</title>
    <meta property="og:title" content="Lobo Genetics" />
    <meta
      name="description"
      content="Our cannabis DNA test helps you understand how your body will react to THC and CBD. Discover your cannabis genetic profile now."
    />
    <meta
      property="og:image"
      content="/assets/img/lobo-genetics-social-logo.png?h=505d8c52bde04d551a63ea6238883bc1"
    />
    <meta property="og:type" content="website" />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/assets/img/favicon-32x32.png?h=b2516f01aea9f768fe82651491756bd7"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Barlow"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Nunito+Sans"
    />
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.12.0/css/all.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />
    <link
      rel="stylesheet"
      href="/assets/fonts/fontawesome5-overrides.min.css?h=28fcdefe8a87593f1f43b7d9951e0cba"
    />
    <link
      rel="stylesheet"
      href="/assets/css/styles.min.css?h=7a5c50a5437594f71ba3fc4257a8ffdd"
    />
    <header>
      <div className="container" style={{ height: 100 }}>
        <div className="row justify-content-between mt-1">
          <div className="col-4 mt-3">
              <img src="/assets/img/logo-header.png?h=539b22d4968d7f963a548616fa6b052a" alt="" />
              <div className="mt-3">
                <h4
                  className="text-dark"
                  id="genetic-test"
                  style={{ fontSize: 20 }}
                >
                  Cannabis Genetic test report
                </h4>
              </div>
          </div>
          <div className="col-5">
            <hr />
            <div className="row mt-2">
              <div className="col-6">
                <div>
                  <h5 className="text-uppercase" style={{ fontSize: 14 }}>
                    <strong>Subject id:</strong>
                  </h5>
                </div>
                <h6 style={{ fontSize: 12 }}>Report date: {display.reportDate}</h6>
                <h6 style={{ fontSize: 12 }}>Test date: {display.testDate}</h6>
              </div>
              <div className="col-6">
                <h5 style={{ fontSize: 14 }}>{display.patientId}</h5>
                <h6 style={{ fontSize: 12 }}>Trial: {display.trialName}</h6>
                <h6 style={{ fontSize: 12 }}>Location: {display.trialLocation}</h6>
              </div>
            </div>
            <div />
          </div>
        </div>
        <hr />
      </div>
    </header>
    <div>
      <div className="container">
        <div className="row">
          <div className="col">
            <section className="mt-5">
              <div className="row mt-2">
                <div className="col">
                  <div className="mb-2">
                    <h2 style={{ fontSize: 15 }}>Gene and Phenotype summary</h2>
                  </div>
                </div>
              </div>
              <div
                className="row no-gutters justify-content-center align-items-center mt-2"
                style={{ height: 15 }}
              >
                <div
                  className="col-2 d-flex justify-content-between"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)"
                  }}
                >
                  <div className="text-center align-self-center mb-2">
                    <h6
                      className="text-left mb-1"
                      style={{ color: "var(--research-primary)" }}
                    >
                      <strong>Gene</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-2 d-flex justify-content-between"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)"
                  }}
                >
                  <div className="text-center align-self-center mb-2">
                    <h6
                      className="text-left mb-1"
                      style={{ color: "var(--research-primary)" }}
                    >
                      <strong>Genotype</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-3 d-flex justify-content-between m-auto"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)"
                  }}
                >
                  <div className="text-center align-self-center mb-2">
                    <h6
                      className="text-left mb-1"
                      style={{ color: "var(--research-primary)" }}
                    >
                      <strong>Phenotype</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-5 d-flex justify-content-between m-auto"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)"
                  }}
                >
                  <div className="text-center align-self-center mb-2">
                    <h6
                      className="text-left mb-1"
                      style={{ color: "var(--research-primary)" }}
                    >
                      <strong>Summary</strong>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row no-gutters justify-content-between mb-0 mt-0">
                <div className="col-12">
                  <hr
                    style={{ borderTop: "1px solid rgba(0,107,166)!important" }}
                  />
                </div>
              </div>
              <div
                className="row no-gutters justify-content-between mb-2"
                style={{
                  msFlex: "1 1 auto",
                  flex: "1 1 auto",
                  minHeight: 1,
                  height: 30
                }}
              >
                <div
                  className="col-2 d-flex justify-content-between"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)"
                  }}
                >
                  <div className="text-center align-self-center mb-2">
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>CYP2C9</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-2 d-flex justify-content-between"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)"
                  }}
                >
                  <div className="text-center align-self-center mb-2">
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>{display.cyp2c9.genotype}</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-3 d-flex justify-content-between m-auto"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-center align-self-center mb-2"
                    style={{ fontSize: 12 }}
                  >
                    <h6 style={{ fontSize: 12 }}><strong>{display.cyp2c9.phenotype}</strong></h6>
                  </div>
                </div>
                <div
                  className="col-5 d-flex justify-content-between m-auto"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-left align-self-left mb-2"
                    style={{ fontSize: 12, textAlign: "left!important" }}
                  >
                    <h6
                      style={{
                        fontSize: 12,
                        color: "var(--research-primary)",
                        fontWeight: "bold",
                        height: 12
                      }}
                    >
                      {display.cyp2c9.summary.title}
                    </h6>
                    <p style={{ height: 12 }}>
                      {display.cyp2c9.summary.text}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row no-gutters justify-content-between mb-0 mt-0">
                <div className="col-12">
                  <hr />
                </div>
              </div>
              <div
                className="row no-gutters justify-content-between mb-2"
                style={{
                  msFlex: "1 1 auto",
                  flex: "1 1 auto",
                  minHeight: 1,
                  height: 40
                }}
              >
                <div
                  className="col-2 d-flex justify-content-between"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-center align-self-center mb-2"
                    style={{ fontSize: 12 }}
                  >
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>AKT1</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-2 d-flex justify-content-between"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-center align-self-center mb-2"
                    style={{ fontSize: 12 }}
                  >
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>{display.akt1.genotype}</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-3 d-flex justify-content-between m-auto"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-center align-self-center mb-2"
                    style={{ fontSize: 12 }}
                  >
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>{display.akt1.phenotype}</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-5 d-flex justify-content-between m-auto"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-left align-self-left mb-2"
                    style={{ fontSize: 12, textAlign: "left!important" }}
                  >
                    <h6
                      style={{
                        fontSize: 12,
                        color: "var(--research-primary)",
                        fontWeight: "bold",
                        height: 12
                      }}
                    >
                      {display.akt1.summary.title}
                    </h6>
                    <p style={{ height: 12 }}>
                      {display.akt1.summary.text}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row no-gutters justify-content-between mb-0 mt-0">
                <div className="col-12">
                  <hr />
                </div>
              </div>
              <div
                className="row no-gutters justify-content-between mb-2"
                style={{ height: 30 }}
              >
                <div
                  className="col-2 d-flex justify-content-between"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-center align-self-center mb-2"
                    style={{ fontSize: 12 }}
                  >
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>COMT</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-2 d-flex justify-content-between"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-center align-self-center mb-2"
                    style={{ fontSize: 12 }}
                  >
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>{display.comt.genotype}</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-3 d-flex justify-content-between m-auto"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-center align-self-center mb-2"
                    style={{ fontSize: 12 }}
                  >
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>{display.comt.phenotype}</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-5 d-flex justify-content-between m-auto"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-left align-self-left mb-2"
                    style={{ fontSize: 12, textAlign: "left!important" }}
                  >
                    <h6
                      style={{
                        fontSize: 12,
                        color: "var(--research-primary)",
                        fontWeight: "bold",
                        height: 12
                      }}
                    >
                      {display.comt.summary.title}
                    </h6>
                    <p style={{ height: 12 }}>
                      {display.comt.summary.text}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row no-gutters justify-content-between mb-0 mt-0">
                <div className="col-12">
                  <hr />
                </div>
              </div>
              <div
                className="row no-gutters justify-content-between mb-2"
                style={{ height: 30 }}
              >
                <div
                  className="col-2 d-flex justify-content-between"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-center align-self-center mb-2"
                    style={{ fontSize: 12 }}
                  >
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>CYP2C19</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-2 d-flex justify-content-between"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)",
                    fontSize: 12
                  }}
                >
                  <div
                    className="text-center align-self-center mb-2"
                    style={{ fontSize: 12 }}
                  >
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>{display.cyp2c19.genotype}</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-3 d-flex justify-content-between m-auto"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)"
                  }}
                >
                  <div className="text-center align-self-center mb-2">
                    <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                      <strong>{display.cyp2c19.phenotype}</strong>
                    </h6>
                  </div>
                </div>
                <div
                  className="col-5 d-flex justify-content-between m-auto"
                  style={{
                    borderRadius: 10,
                    border: "23px none rgba(0,107,166,0.88)"
                  }}
                >
                  <div
                    className="text-left align-self-left mb-2"
                    style={{ fontSize: 12, textAlign: "left!important" }}
                  >
                    <h6
                      style={{
                        fontSize: 12,
                        color: "var(--research-primary)",
                        fontWeight: "bold",
                        height: 12
                      }}
                    >
                      {display.cyp2c19.summary.title}
                    </h6>
                    <p style={{ height: 12 }}>
                      {display.cyp2c19.summary.text}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row no-gutters justify-content-between mb-0 mt-0">
                <div className="col-12">
                  <hr />
                </div>
              </div>







      </section>
    </div>
  </div>
</div>
<div className="container pageBreak">
  <div className="row">
    <div className="col">
      <section className="mt-5">










              <div className="row">
                <div className="col">
                  <h4 className="text-dark" style={{ height: 21, fontSize: 15 }}>
                    CYP2C9 Genotype: Metabolism
                  </h4>
                  <p style={{ fontSize: 12 }}>
                    The CYP2C9 gene provides information on an individual’s
                    ability to metabolize THC (the psychoactive compound in
                    cannabis) into its non-psychoactive form. Slower metabolism
                    results in higher THC blood levels which increases the
                    intensity and duration of effects, especially when consuming
                    cannabis in pill or edible form.
                    <br />
                  </p>
                </div>
              </div>
              <div className="row justify-content-center align-items-center mb-2">
                <div className="col-8" style={{ height: "50pxpx" }}>
                  <div
                    className="row no-gutters justify-content-center align-items-center mt-2"
                    style={{ height: 10 }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        borderWidth: 23,
                        borderStyle: "none"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>CYP2C9 Genotype</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>Metabolizer Status</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>THC Blood Levels</strong>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mb-0 mt-0"
                    style={{ height: 21 }}
                  >
                    <div className="col-12" style={{ height: 5 }}>
                      <hr
                        style={{
                          borderTop: "1px solid rgba(0,107,166)!important"
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6
                          className="text-left mb-1"
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          <strong>*1/*1</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6
                          className="text-left mb-1"
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          <strong>Normal</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          Normal
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>*1/*3</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Intermediate</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>Up tp 2x higher</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>*3/*3</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Poor</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>Up tp 3x higher</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Inconclusive</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>N/A</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>N/A</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2">
                  <div>
                    <h4 className="text-primary" style={{ fontSize: 15 }}>
                      CYP2C9 *1/*1
                    </h4>
                    <p style={{ fontSize: 12, height: 5 }}>Normal Metabolizer</p>
                  </div>
                </div>
                <div className="col" style={{ height: 23 }}>
                  <div>
                    <p style={{ fontSize: 12 }}>
                      Can expect normal THC blood levels for an average duration
                      after consuming edibles. Patient reaction should be similar
                      to most people.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="row justify-content-center">
                    <div className="col-4">
                      <div className="mb-4 mb-md-0">
                        <picture className="d-block mx-auto mb-4">
                          <source
                            srcSet={display.cyp2c9.image1}
                            media="(min-width: 601px)"
                          />
                          <img
                            className="img-fluid d-block mx-auto"
                            src={display.cyp2c9.image1}
                            style={{ height: 200 }}
                            alt=""
                            />
                        </picture>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-4 mb-md-0">
                        <picture className="d-block mx-auto mb-4">
                          <source
                            srcSet={display.cyp2c9.image2}
                            media="(min-width: 601px)"
                          />
                          <img
                            className="img-fluid d-block mx-auto"
                            src={display.cyp2c9.image2}
                            style={{ height: 200 }}
                            alt=""
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h4 className="text-dark" style={{ height: 21, fontSize: 15 }}>
                    AKT1 Genotype: Short- and Long-Term Psychosis Risk
                  </h4>
                  <p style={{ fontSize: 12 }}>
                    The AKT1 gene provides information on an individual’s
                    likelihood of heightened short-term psychoactive effects as
                    well as long-term psychosis risk when using cannabis.
                    <br />
                  </p>
                </div>
              </div>
              <div className="row justify-content-center align-items-center mb-2">
                <div className="col-8" style={{ height: "50pxpx" }}>
                  <div
                    className="row no-gutters justify-content-center align-items-center mt-2"
                    style={{ height: 10 }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        borderWidth: 23,
                        borderStyle: "none"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>AKT1 Genotype</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>Short-term Risk</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>Long-term Risk</strong>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mb-0 mt-0"
                    style={{ height: 21 }}
                  >
                    <div className="col-12" style={{ height: 5 }}>
                      <hr
                        style={{
                          borderTop: "1px solid rgba(0,107,166)!important"
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6
                          className="text-left mb-1"
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          <strong>T/T</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6
                          className="text-left mb-1"
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          <strong>Average</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          Average
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>C/T</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Intermediate*</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>Average</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>C/C</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Higher*</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>Higher*^</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Inconclusive</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>N/A</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>N/A</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mb-0 mt-0"
                    style={{ height: 35 }}
                  >
                    <div className="col-12" style={{ height: 25 }}>
                      <p style={{ fontSize: 9 }}>
                        * Genetics is a key risk factor in developing psychosis
                        symptoms. Other risks include environmental factors, such
                        as stressful events or substance use.
                        <br />
                        ^2x higher risk with history of cannabis use and 7x higher
                        risk with daily cannabis use.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Page2 */}
              <div className="row mb-2">
                <div className="col-2">
                  <div>
                    <h4 className="text-primary" style={{ fontSize: 15 }}>
                      AKT1 T/T
                    </h4>
                    <p style={{ fontSize: 12, height: 5 }}>
                      Average Mental Health Risk
                    </p>
                  </div>
                </div>
                <div className="col" style={{ height: 23 }}>
                  <div>
                    <p style={{ fontSize: 12 }}>
                      &nbsp;At a normal risk for both short-term psychotic
                      symptoms and long-term psychosis from THC.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-4">
                  <div className="mb-4 mb-md-0">
                    <picture className="d-block mx-auto mb-4">
                      <source
                        srcSet={display.akt1.image1}
                        media="(min-width: 601px)"
                      />
                      <img
                        className="img-fluid d-block mx-auto"
                        src={display.akt1.image1}
                        style={{ height: 200 }}
                        alt=""
                      />
                    </picture>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-4 mb-md-0">
                    <picture className="d-block mx-auto mb-4">
                      <source
                        srcSet={display.akt1.image2}
                        media="(min-width: 601px)"
                      />
                      <img
                        className="img-fluid d-block mx-auto"
                        src={display.akt1.image2}
                        style={{ height: 200 }}
                        alt=""
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h4 className="text-dark" style={{ height: 21, fontSize: 15 }}>
                    COMT Genotype: Memory Loss
                  </h4>
                  <p style={{ fontSize: 12 }}>
                    The COMT gene provides information on an individual’s
                    likelihood of neurocognitive impairment when using cannabis,
                    such as short-term memory loss.
                    <br />
                  </p>
                </div>
              </div>
              <div className="row justify-content-center align-items-center mb-2">
                <div className="col-5" style={{ height: "50pxpx" }}>
                  <div
                    className="row no-gutters justify-content-center align-items-center mt-2"
                    style={{ height: 10 }}
                  >
                    <div
                      className="col-8 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        borderWidth: 23,
                        borderStyle: "none"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>COMT Genotype</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-4 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>Memory Loss Risk</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mb-0 mt-0"
                    style={{ height: 21 }}
                  >
                    <div className="col-12" style={{ height: 5 }}>
                      <hr
                        style={{
                          borderTop: "1px solid rgba(0,107,166)!important"
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Met/Met</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-4 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Normal</strong>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Val/Met</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-4 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Intermediate</strong>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6
                          className="text-left mb-1"
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          <strong>Val/Val</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-4 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6
                          className="text-left mb-1"
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          <strong>Higher</strong>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>No result</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-4 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>N/A</strong>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2">
                  <div>
                    <h4 className="text-primary" style={{ fontSize: 15 }}>
                      COMT Val/Val
                    </h4>
                    <p style={{ fontSize: 12, height: 5 }}>Higher Memory Risk</p>
                  </div>
                </div>
                <div className="col" style={{ height: 23 }}>
                  <div>
                    <p style={{ fontSize: 12 }}>
                      &nbsp;At an increased risk for memory impairment when using
                      THC.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center" style={{ height: 215 }}>
                <div className="col-4">
                  <div className="mb-4 mb-md-0">
                    <picture className="d-block mx-auto mb-4">
                      <source
                        srcSet={display.comt.image1}
                        media="(min-width: 601px)"
                      />
                      <img
                        className="img-fluid d-block mx-auto"
                        src={display.comt.image1}
                        style={{ height: 200 }}
                        alt=""
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h4 className="text-dark" style={{ height: 21, fontSize: 15 }}>
                    CYP2C19 Genotype: Metabolism
                  </h4>
                  <p style={{ fontSize: 12, height: 25 }}>
                    CYP2C19 is the primary route of metabolism for CBD in the
                    body. The CYP2C19 gene provides information on an individual’s
                    ability to metabolize CBD and correlates with how fast or slow
                    CBD is eliminated from the body.
                    <br />
                  </p>
                  <p style={{ fontSize: 12, height: 25 }}>
                    Slower metabolism most likely results in higher CBD blood
                    levels which prolongs the duration of effects, and may lead to
                    CBD accumulation in the body, increasing the risk of
                    associated side effects and toxicity.
                    <br />
                  </p>
                  <p style={{ fontSize: 12, height: 25 }}>
                    Increased metabolism most likely results in lower CBD blood
                    levels which can reduce the duration of effects. Variation in
                    metabolism of CBD is particularly relevant when consuming
                    cannabis in oral dosage forms.
                    <br />
                  </p>
                  <p style={{ fontSize: 12, height: 25 }}>
                    It is also well known that there are several common drugs that
                    can inhibit or induce CYP2C19 activity. These therapies
                    include, but are not exclusive to anti-platelet therapies,
                    beta blockers, proton pump inhibitors and antiepileptics.
                    <br />
                  </p>
                  <p style={{ fontSize: 12, height: 35 }}>
                    Other studies have shown that drug blood levels were increased
                    by 1.6X for slow metabolizers and 7X for very slow
                    metabolizers, respectively, and decreased by 1.5X for fast
                    metabolizers and 3X for very fast metabolizers.<sup>1,2</sup>
                    <br />
                  </p>
                </div>
              </div>
              <div className="row justify-content-center align-items-center mb-2">
                <div className="col-8" style={{ height: "50pxpx" }}>
                  <div
                    className="row no-gutters justify-content-center align-items-center mt-2"
                    style={{ height: 10 }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        borderWidth: 23,
                        borderStyle: "none"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>CYP2C19 Genotype</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>Metabolizer Status</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1">
                          <strong>THC Blood Levels</strong>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mb-0 mt-0"
                    style={{ height: 21 }}
                  >
                    <div className="col-12" style={{ height: 5 }}>
                      <hr
                        style={{
                          borderTop: "1px solid rgba(0,107,166)!important"
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6
                          className="text-left mb-1"
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          <strong>*1/*1</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6
                          className="text-left mb-1"
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          <strong>Normal</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6
                          style={{
                            fontSize: 12,
                            color: "var(--research-primary)"
                          }}
                        >
                          Normal
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>*1/*2; *1/*3</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Slow</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>Higher than normal</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>*2/*2; *2/*3; *3/*3</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Very Slow</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>Higher than normal</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>*1/*17</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Fast</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>Lower than normal</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>*17/*17</strong>
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Very Fast</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>Lower than normal</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row no-gutters justify-content-between mt-2"
                    style={{
                      msFlex: "1 1 auto",
                      flex: "1 1 auto",
                      minHeight: 1,
                      height: 10
                    }}
                  >
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>Inconclusive</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)"
                      }}
                    >
                      <div className="text-center align-self-center mb-2">
                        <h6 className="text-left mb-1" style={{ fontSize: 12 }}>
                          <strong>N/A</strong>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-between m-auto"
                      style={{
                        borderRadius: 10,
                        border: "23px none rgba(0,107,166,0.88)",
                        fontSize: 12
                      }}
                    >
                      <div
                        className="text-center align-self-center mb-2"
                        style={{ fontSize: 12 }}
                      >
                        <h6 style={{ fontSize: 12 }}>N/A</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters justify-content-between mb-0 mt-0">
                    <div className="col-12" style={{ height: 27 }}>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              {/* Page3 */}
              <div className="row mb-2">
                <div className="col-2">
                  <div>
                    <h4 className="text-primary" style={{ fontSize: 15 }}>
                      CYP2C19 *1/*1
                    </h4>
                    <p style={{ fontSize: 12, height: 5 }}>Normal Metabolism</p>
                  </div>
                </div>
                <div className="col" style={{ height: 23 }}>
                  <div>
                    <p style={{ fontSize: 12 }}>
                      CBD metabolized at a normal rate. <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-4">
                  <div className="mb-4 mb-md-0">
                    <picture className="d-block mx-auto mb-4">
                      <source
                        srcSet={display.cyp2c19.image1}
                        media="(min-width: 601px)"
                      />
                      <img
                        className="img-fluid d-block mx-auto"
                        src={display.cyp2c19.image1}
                        style={{ height: 200 }}
                        alt=""
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    {/* Start: Footer Dark */}
    <div className="footer-dark" style={{backgroundColor: "white"}}>
      <footer>
        <div className="container">
          <div className="row" style={{ height: 55 }}>
            {/* Start: Services */}
            <div className="col-4 text-left item">
              <img
                className="mb-2"
                src="/assets/img/logo-header.png?h=539b22d4968d7f963a548616fa6b052a"
                style={{ height: 30 }}
                alt=""
              />
              <h3 className="text-dark" style={{ fontSize: 12 }}>
                Lobo Genetics Inc.
              </h3>
              <ul />
            </div>
            {/* End: Services */}
            {/* Start: About */}
            <div className="col-4 text-center item">
              <h3 className="text-center text-dark" style={{ fontSize: 12 }}>
                Cannabis Genetic Test
              </h3>
              <ul>
                <li className="text-dark" style={{ fontSize: 12 }}>
                    Lab Report
                </li>
              </ul>
            </div>
            {/* End: About */}
            {/* Start: Footer Text */}
            <div className="col-4 text-right item text">
              <h3 className="text-dark" style={{ fontSize: 12 }}>
                Patient,&nbsp;{display.patientId}
              </h3>
            </div>
            {/* End: Footer Text */}
          </div>
          <div className="row">
            {/* Start: Services */}
            <div
              className="col-12 text-left item"
              style={{ color: "var(--dark)", fontSize: "10PX" }}
            >
              <p>
                This report is being provided for research and informational use
                only, and is not for diagnostic use. The information is provided
                on an "as-is" basis, without representation, warranty or
                condition, whether implied or otherwise.
              </p>
            </div>
            {/* End: Services */}
          </div>
        </div>
      </footer>
    </div>
    {/* End: Footer Dark */}
  </>)
}
