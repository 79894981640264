import React from "react";
import ReactDOM from "react-dom";
import {useLocation} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ActionCreators} from "../../actions/index";
import {pdfRawHtml} from "./pdfRawPsychedelicsHtml";
import Helmet from "react-helmet";

const mapStateToProps = state => ({
  user: state.LoginStatus,
  surveys: state.Surveys,
  testResults: state.TestResults,
  initialLoad: state.InitialLoad
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function PsychedelicsPdfReport() {
  const {search} = useLocation();
  const rawData = new URLSearchParams(search).get('data');
  const data = JSON.parse(rawData);
  const display = {
    kitId: data.swab,
    date: data.date,
    initials: data.initials,
    location: data.location,
    c4a: {
      genotypeDescriptive:
        data.c4a === ('C4A_ZERO') && '0&nbsp;copies' ||
        data.c4a === ('C4A_ONE') && '1&nbsp;copy' ||
        data.c4a === ('C4A_TWO') && '2&nbsp;copies' ||
        data.c4a === ('C4A_THREE') && '3&nbsp;copies' ||
        data.c4a === ('C4A_FOUR') && '4&nbsp;copies' ||
        data.c4a === ('C4A_FIVE') && '5&nbsp;copies' ||
        data.c4a === 'C4A_INCONCLUSIVE' && 'Inconclusive' ||
        data.c4a === 'C4A_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      genotype:
        data.c4a === ('C4A_ZERO') && '0 copies' ||
        data.c4a === ('C4A_ONE') && '1 copy' ||
        data.c4a === ('C4A_TWO') && '2 copies' ||
        data.c4a === ('C4A_THREE') && '3 copies' ||
        data.c4a === ('C4A_FOUR') && '4 copies' ||
        data.c4a === ('C4A_FIVE') && '5 copies' ||
        data.c4a === 'C4A_INCONCLUSIVE' && 'Inconclusive' ||
        data.c4a === 'C4A_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeDescriptive:
        (data.c4a === 'C4A_ZERO' || data.c4a === 'C4A_ONE' || data.c4a === 'C4A_TWO' || data.c4a === 'C4A_THREE') && 'Normal&nbsp;Risk' ||
        (data.c4a === 'C4A_FOUR' || data.c4a === 'C4A_FIVE') && 'Increased&nbsp;Risk' ||
        data.c4a === 'C4A_INCONCLUSIVE' && 'Inconclusive' ||
        data.c4a === 'C4A_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotype:
        (data.c4a === 'C4A_ZERO' || data.c4a === 'C4A_ONE' || data.c4a === 'C4A_TWO' || data.c4a === 'C4A_THREE') && 'Normal' ||
        (data.c4a === 'C4A_FOUR' || data.c4a === 'C4A_FIVE') && 'Increased' ||
        data.c4a === 'C4A_INCONCLUSIVE' && 'Inconclusive' ||
        data.c4a === 'C4A_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeIcon: (data.c4a === 'C4A_FOUR' || data.c4a === 'C4A_FIVE') && '<i class="fa fa-arrow-circle-o-up"></i>' ||
        '',
    },
    cyp2b6: {
      genotypeDescriptive:
        data.cyp2b6 === 'CYP2B6_1_1' && 'CYP2B6&nbsp;1/1' ||
        data.cyp2b6 === 'CYP2B6_1_6' && 'CYP2B6&nbsp;1/6' ||
        data.cyp2b6 === 'CYP2B6_6_6' && 'CYP2B6&nbsp;6/6' ||
        data.cyp2b6 === 'CYP2B6_INCONCLUSIVE' && 'Inconclusive' ||
        data.cyp2b6 === 'CYP2B6_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      genotype:
        data.cyp2b6 === 'CYP2B6_1_1' && '1/1' ||
        data.cyp2b6 === 'CYP2B6_1_6' && '1/6' ||
        data.cyp2b6 === 'CYP2B6_6_6' && '6/6' ||
        data.cyp2b6 === 'CYP2B6_INCONCLUSIVE' && 'Inconclusive' ||
        data.cyp2b6 === 'CYP2B6_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      result:
        data.cyp2b6 === 'CYP2B6_1_1' && 'Normal Metabolism' ||
        data.cyp2b6 === 'CYP2B6_1_6' && 'Slow Metabolism' ||
        data.cyp2b6 === 'CYP2B6_6_6' && 'Very Slow Metabolism' ||
        data.cyp2b6 === 'CYP2B6_INCONCLUSIVE' && 'Inconclusive' ||
        data.cyp2b6 === 'CYP2B6_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotype:
        data.cyp2b6 === 'CYP2B6_1_1' && 'Normal' ||
        data.cyp2b6 === 'CYP2B6_1_6' && 'Slow' ||
        data.cyp2b6 === 'CYP2B6_6_6' && 'Very Slow' ||
        data.cyp2b6 === 'CYP2B6_INCONCLUSIVE' && 'Inconclusive' ||
        data.cyp2b6 === 'CYP2B6_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
        phenotypeDescriptive:
        data.cyp2b6 === 'CYP2B6_1_1' && 'Extensive (Normal) Metabolizer' ||
        data.cyp2b6 === 'CYP2B6_1_6' && 'Intermediate Metabolizer' ||
        data.cyp2b6 === 'CYP2B6_6_6' && 'Poor Metabolizer' ||
        data.cyp2b6 === 'CYP2B6_INCONCLUSIVE' && 'Inconclusive' ||
        data.cyp2b6 === 'CYP2B6_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeIcon: (data.cyp2b6 === 'CYP2B6_1_6' || data.cyp2b6 === 'CYP2B6_6_6') && '<i class="fa fa-arrow-circle-o-down"></i>' ||
        '',
    },
    cyp2d6: {
      genotype: data.cyp2d6.split('-')[1].includes('*') ? data.cyp2d6.split('-')[1].replaceAll('*', '') : '<span class="text-secondary">NO RESULT<span>',
      metabolismDescriptive: data.cyp2d6.split('-')[0] === 'Poor' && 'Very Slow Metabolism' ||
        data.cyp2d6.split('-')[0] === 'Intermediate' && 'Normal Metabolism' ||
        data.cyp2d6.split('-')[0] === 'Normal' && 'Normal Metabolism' ||
        data.cyp2d6.split('-')[0] === 'Ultrarapid' && 'Increased Metabolism' ||
        data.cyp2d6.split('-')[0] === 'CYP2D6_NO_RESULT' && '<span class="text-secondary">NO RESULT<span>' ||
        data.cyp2d6.split('-')[0] === 'CYP2D6_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      metabolism: data.cyp2d6.split('-')[0] === 'Poor' && 'Very Slow' ||
        data.cyp2d6.split('-')[0] === 'Intermediate' && 'Normal' ||
        data.cyp2d6.split('-')[0] === 'Normal' && 'Normal' ||
        data.cyp2d6.split('-')[0] === 'Ultrarapid' && 'Increased' ||
        data.cyp2d6.split('-')[0] === 'CYP2D6_NO_RESULT' && '<span class="text-secondary">NO RESULT<span>' ||
        data.cyp2d6.split('-')[0] === 'CYP2D6_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeDescriptive: data.cyp2d6.split('-')[0] === 'Poor' && 'Poor Metabolizer' ||
        data.cyp2d6.split('-')[0] === 'Intermediate' && 'Intermediate Metabolizer' ||
        data.cyp2d6.split('-')[0] === 'Normal' && 'Extensive (Normal) Metabolizer' ||
        data.cyp2d6.split('-')[0] === 'Ultrarapid' && 'Ultrarapid Metabolizer' ||
        data.cyp2d6.split('-')[0] === 'CYP2D6_NO_RESULT' && '<span class="text-secondary">NO RESULT<span>' ||
        data.cyp2d6.split('-')[0] === 'CYP2D6_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotype: data.cyp2d6.split('-')[0] === 'Poor' && 'Poor' ||
        data.cyp2d6.split('-')[0] === 'Intermediate' && 'Intermediate' ||
        data.cyp2d6.split('-')[0] === 'Normal' && 'Extensive (Normal)' ||
        data.cyp2d6.split('-')[0] === 'Ultrarapid' && 'Ultrarapid' ||
        data.cyp2d6.split('-')[0] === 'CYP2D6_NO_RESULT' && '<span class="text-secondary">NO RESULT<span>' ||
        data.cyp2d6.split('-')[0] === 'CYP2D6_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      metabolismIcon: data.cyp2d6.split('-')[0] === 'Poor' && '<i class="fa fa-arrow-circle-o-down"></i>' ||
        data.cyp2d6.split('-')[0] === 'Ultrarapid' && '<i class="fa fa-arrow-circle-o-up"></i>' ||
        ''
    },
    disc1: {
      genotypeDescriptive:
        data.disc1 === 'DISC1_A_A' && 'DISC1&nbsp;A/A' ||
        data.disc1 === 'DISC1_A_T' && 'DISC1&nbsp;A/T' ||
        data.disc1 === 'DISC1_T_T' && 'DISC1&nbsp;T/T' ||
        data.disc1 === 'DISC1_INCONCLUSIVE' && 'Inconclusive' ||
        data.disc1 === 'DISC1_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      genotype:
        data.disc1 === 'DISC1_A_A' && 'A/A' ||
        data.disc1 === 'DISC1_A_T' && 'A/T' ||
        data.disc1 === 'DISC1_T_T' && 'T/T' ||
        data.disc1 === 'DISC1_INCONCLUSIVE' && 'Inconclusive' ||
        data.disc1 === 'DISC1_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeDescriptive:
        data.disc1 === 'DISC1_A_A' && 'Normal&nbsp;Risk' ||
        data.disc1 === 'DISC1_A_T' && 'Normal&nbsp;Risk' ||
        data.disc1 === 'DISC1_T_T' && 'Increased&nbsp;Risk' ||
        data.disc1 === 'DISC1_INCONCLUSIVE' && 'Inconclusive' ||
        data.disc1 === 'DISC1_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotype:
        data.disc1 === 'DISC1_A_A' && 'Normal' ||
        data.disc1 === 'DISC1_A_T' && 'Normal' ||
        data.disc1 === 'DISC1_T_T' && 'Increased' ||
        data.disc1 === 'DISC1_INCONCLUSIVE' && 'Inconclusive' ||
        data.disc1 === 'DISC1_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeIcon: data.disc1 === 'DISC1_T_T' && '<i class="fa fa-arrow-circle-o-up"></i>' ||
        '',
    },
    htr2a: {
      genotypeDescriptive:
        data.htr2a === 'HTR2A_C_C' && 'HTR2A&nbsp;C/C' ||
        data.htr2a === 'HTR2A_C_T' && 'HTR2A&nbsp;C/T' ||
        data.htr2a === 'HTR2A_T_T' && 'HTR2A&nbsp;T/T' ||
        data.htr2a === 'HTR2A_INCONCLUSIVE' && 'Inconclusive' ||
        data.htr2a === 'HTR2A_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      genotype:
        data.htr2a === 'HTR2A_C_C' && 'C/C' ||
        data.htr2a === 'HTR2A_C_T' && 'C/T' ||
        data.htr2a === 'HTR2A_T_T' && 'T/T' ||
        data.htr2a === 'HTR2A_INCONCLUSIVE' && 'Inconclusive' ||
        data.htr2a === 'HTR2A_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeDescriptive:
        data.htr2a === 'HTR2A_C_C' && 'Reduced&nbsp;Sensitivity' ||
        data.htr2a === 'HTR2A_C_T' && 'Normal&nbsp;Sensitivity' ||
        data.htr2a === 'HTR2A_T_T' && 'Increased&nbsp;Sensitivity' ||
        data.htr2a === 'HTR2A_INCONCLUSIVE' && 'Inconclusive' ||
        data.htr2a === 'HTR2A_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotype:
        data.htr2a === 'HTR2A_C_C' && 'Reduced' ||
        data.htr2a === 'HTR2A_C_T' && 'Normal' ||
        data.htr2a === 'HTR2A_T_T' && 'Increased' ||
        data.htr2a === 'HTR2A_INCONCLUSIVE' && 'Inconclusive' ||
        data.htr2a === 'HTR2A_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      ssriDescriptive:
        data.htr2a === 'HTR2A_C_C' && 'Increased&nbsp;Sensitivity' ||
        data.htr2a === 'HTR2A_C_T' && 'Normal&nbsp;Sensitivity' ||
        data.htr2a === 'HTR2A_T_T' && 'Normal&nbsp;Sensitivity' ||
        data.htr2a === 'HTR2A_INCONCLUSIVE' && 'Inconclusive' ||
        data.htr2a === 'HTR2A_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      ssri:
        data.htr2a === 'HTR2A_C_C' && 'Increased' ||
        data.htr2a === 'HTR2A_C_T' && 'Normal' ||
        data.htr2a === 'HTR2A_T_T' && 'Normal' ||
        data.htr2a === 'HTR2A_INCONCLUSIVE' && 'Inconclusive' ||
        data.htr2a === 'HTR2A_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeIcon: data.htr2a === 'HTR2A_C_C' && '<i class="fa fa-arrow-circle-o-down"></i>' ||
        data.htr2a === 'HTR2A_T_T' && '<i class="fa fa-arrow-circle-o-up"></i>' ||
        '',
      ssriIcon: data.htr2a === 'HTR2A_C_C' && '<i class="fa fa-arrow-circle-o-up"></i>' ||
        ''
    },
    bdnf: {
      genotypeDescriptive:
        data.bdnf === 'BDNF_VAL_VAL' && 'BDNF&nbsp;Val/Val' ||
        data.bdnf === 'BDNF_VAL_MET' && 'BDNF&nbsp;Val/Met' ||
        data.bdnf === 'BDNF_MET_MET' && 'BDNF&nbsp;Met/Met' ||
        data.bdnf === 'BDNF_INCONCLUSIVE' && 'Inconclusive' ||
        data.bdnf === 'BDNF_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      genotype:
        data.bdnf === 'BDNF_VAL_VAL' && 'Val/Val' ||
        data.bdnf === 'BDNF_VAL_MET' && 'Val/Met' ||
        data.bdnf === 'BDNF_MET_MET' && 'Met/Met' ||
        data.bdnf === 'BDNF_INCONCLUSIVE' && 'Inconclusive' ||
        data.bdnf === 'BDNF_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeDescriptive:
        data.bdnf === 'BDNF_VAL_VAL' && 'Normal&nbsp;Response' ||
        data.bdnf === 'BDNF_VAL_MET' && 'Decreased&nbsp;Response' ||
        data.bdnf === 'BDNF_MET_MET' && 'Very&nbsp;Decreased&nbsp;Response' ||
        data.bdnf === 'BDNF_INCONCLUSIVE' && 'Inconclusive' ||
        data.bdnf === 'BDNF_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotype:
        data.bdnf === 'BDNF_VAL_VAL' && 'Normal' ||
        data.bdnf === 'BDNF_VAL_MET' && 'Decreased' ||
        data.bdnf === 'BDNF_MET_MET' && 'Very&nbsp;Decreased' ||
        data.bdnf === 'BDNF_INCONCLUSIVE' && 'Inconclusive' ||
        data.bdnf === 'BDNF_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeIcon: (data.bdnf === 'BDNF_VAL_MET' || data.bdnf === 'BDNF_MET_MET') && '<i class="fa fa-arrow-circle-o-down"></i>' ||
        '',
    },
    nrg1: {
      genotypeDescriptive:
        data.nrg1 === 'NRG1_C_C' && 'NRG1&nbsp;C/C' ||
        data.nrg1 === 'NRG1_C_T' && 'NRG1&nbsp;C/T' ||
        data.nrg1 === 'NRG1_T_T' && 'NRG1&nbsp;T/T' ||
        data.nrg1 === 'NRG1_INCONCLUSIVE' && 'Inconclusive' ||
        data.nrg1 === 'NRG1_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      genotype:
        data.nrg1 === 'NRG1_C_C' && 'C/C' ||
        data.nrg1 === 'NRG1_C_T' && 'C/T' ||
        data.nrg1 === 'NRG1_T_T' && 'T/T' ||
        data.nrg1 === 'NRG1_INCONCLUSIVE' && 'Inconclusive' ||
        data.nrg1 === 'NRG1_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeDescriptive:
        data.nrg1 === 'NRG1_C_C' && 'Normal&nbsp;Risk' ||
        data.nrg1 === 'NRG1_C_T' && 'Normal&nbsp;Risk' ||
        data.nrg1 === 'NRG1_T_T' && 'Increased&nbsp;Risk' ||
        data.nrg1 === 'NRG1_INCONCLUSIVE' && 'Inconclusive' ||
        data.nrg1 === 'NRG1_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotype:
        data.nrg1 === 'NRG1_C_C' && 'Normal' ||
        data.nrg1 === 'NRG1_C_T' && 'Normal' ||
        data.nrg1 === 'NRG1_T_T' && 'Increased' ||
        data.nrg1 === 'NRG1_INCONCLUSIVE' && 'Inconclusive' ||
        data.nrg1 === 'NRG1_PENDING' && '<span class="text-secondary">NO RESULT<span>' ||
        '<span class="text-secondary">NO RESULT<span>',
      phenotypeIcon: data.nrg1 === 'NRG1_T_T' && '<i class="fa fa-arrow-circle-o-up"></i>' ||
        '',
    }
  };

  let html = pdfRawHtml();
  html = html.replaceAll('assets/', '/test/assets/');
  //Replace variable placeholders in html
  html = html.replaceAll('{{{display.kitId}}}', display.kitId);
  html = html.replaceAll('{{{display.date}}}', display.date);
  html = html.replaceAll('{{{display.initials}}}', display.initials);
  html = html.replaceAll('{{{display.location}}}', display.location);

  html = html.replaceAll('{{{display.c4a.genotype}}}', display.c4a.genotype);
  html = html.replaceAll('{{{display.c4a.genotypeDescriptive}}}', display.c4a.genotypeDescriptive);
  html = html.replaceAll('{{{display.c4a.phenotype}}}', display.c4a.phenotype);
  html = html.replaceAll('{{{display.c4a.phenotypeDescriptive}}}', display.c4a.phenotypeDescriptive);
  html = html.replaceAll('{{{display.c4a.phenotypeIcon}}}', display.c4a.phenotypeIcon);

  html = html.replaceAll('{{{display.cyp2b6.genotype}}}', display.cyp2b6.genotype.replace(/(\d+)\/(\d+)/, '*$1/*$2') );
  html = html.replaceAll('{{{display.cyp2b6.genotypeDescriptive}}}', display.cyp2b6.genotypeDescriptive.replace(/(\d+)\/(\d+)/, '*$1/*$2') );
  html = html.replaceAll('{{{display.cyp2b6.phenotype}}}', display.cyp2b6.phenotype);
  html = html.replaceAll('{{{display.cyp2b6.result}}}', display.cyp2b6.result);
  html = html.replaceAll('{{{display.cyp2b6.phenotypeDescriptive}}}', display.cyp2b6.phenotypeDescriptive);
  html = html.replaceAll('{{{display.cyp2b6.phenotypeIcon}}}', display.cyp2b6.phenotypeIcon);

  html = html.replaceAll('{{{display.cyp2d6.genotype}}}', display.cyp2d6.genotype.replace(/(\d+)\/(\d+)/, '*$1/*$2') );
  html = html.replaceAll('{{{display.cyp2d6.metabolism}}}', display.cyp2d6.metabolism);
  html = html.replaceAll('{{{display.cyp2d6.metabolismDescriptive}}}', display.cyp2d6.metabolismDescriptive);
  html = html.replaceAll('{{{display.cyp2d6.phenotype}}}', display.cyp2d6.phenotype);
  html = html.replaceAll('{{{display.cyp2d6.phenotypeDescriptive}}}', display.cyp2d6.phenotypeDescriptive);
  html = html.replaceAll('{{{display.cyp2d6.metabolismIcon}}}', display.cyp2d6.metabolismIcon);

  html = html.replaceAll('{{{display.disc1.genotype}}}', display.disc1.genotype);
  html = html.replaceAll('{{{display.disc1.genotypeDescriptive}}}', display.disc1.genotypeDescriptive);
  html = html.replaceAll('{{{display.disc1.phenotype}}}', display.disc1.phenotype);
  html = html.replaceAll('{{{display.disc1.phenotypeDescriptive}}}', display.disc1.phenotypeDescriptive);
  html = html.replaceAll('{{{display.disc1.phenotypeIcon}}}', display.disc1.phenotypeIcon);

  html = html.replaceAll('{{{display.htr2a.genotype}}}', display.htr2a.genotype);
  html = html.replaceAll('{{{display.htr2a.genotypeDescriptive}}}', display.htr2a.genotypeDescriptive);
  html = html.replaceAll('{{{display.htr2a.phenotype}}}', display.htr2a.phenotype);
  html = html.replaceAll('{{{display.htr2a.phenotypeDescriptive}}}', display.htr2a.phenotypeDescriptive);
  html = html.replaceAll('{{{display.htr2a.phenotypeIcon}}}', display.htr2a.phenotypeIcon);
  html = html.replaceAll('{{{display.htr2a.ssri}}}', display.htr2a.ssri);
  html = html.replaceAll('{{{display.htr2a.ssriDescriptive}}}', display.htr2a.ssriDescriptive);
  html = html.replaceAll('{{{display.htr2a.ssriIcon}}}', display.htr2a.ssriIcon);

  html = html.replaceAll('{{{display.bdnf.genotype}}}', display.bdnf.genotype);
  html = html.replaceAll('{{{display.bdnf.genotypeDescriptive}}}', display.bdnf.genotypeDescriptive);
  html = html.replaceAll('{{{display.bdnf.phenotype}}}', display.bdnf.phenotype);
  html = html.replaceAll('{{{display.bdnf.phenotypeDescriptive}}}', display.bdnf.phenotypeDescriptive);
  html = html.replaceAll('{{{display.bdnf.phenotypeIcon}}}', display.bdnf.phenotypeIcon);

  html = html.replaceAll('{{{display.nrg1.genotype}}}', display.nrg1.genotype);
  html = html.replaceAll('{{{display.nrg1.genotypeDescriptive}}}', display.nrg1.genotypeDescriptive);
  html = html.replaceAll('{{{display.nrg1.phenotype}}}', display.nrg1.phenotype);
  html = html.replaceAll('{{{display.nrg1.phenotypeDescriptive}}}', display.nrg1.phenotypeDescriptive);
  html = html.replaceAll('{{{display.nrg1.phenotypeIcon}}}', display.nrg1.phenotypeIcon);

  ReactDOM.render(
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
        <title>HaluGen Psychedelics Genetic Test Report</title>
        <meta property="og:title" content="HaluGen Psychedelics Genetic Test Report" />
        <meta name="description" content="Psychedelics Genetic Test Report" />
        <meta property="og:type" content="website" />
        <link rel="icon" type="image/png" sizes="80x80" href="/test/assets/img/favicon.ico.png" />
        <link rel="stylesheet" href="/test/assets/bootstrap/css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins&amp;display=swap" />
        <link rel="stylesheet" href="/test/assets/fonts/fontawesome-all.min.css" />
        <link rel="stylesheet" href="/test/assets/fonts/font-awesome.min.css" />
        <link rel="stylesheet" href="/test/assets/fonts/fontawesome5-overrides.min.css" />
        <link rel="stylesheet" href="/test/assets/css/psychedelics-styles-print-report.css" />
        <link rel="stylesheet" href="/test/assets/css/psychedelics-styles.css" />
      </Helmet>
      <div dangerouslySetInnerHTML={{__html: html}} />
    </>
  , document.getElementById('root'));
  return false;
}

const psychedelicsPdf = connect(mapStateToProps, mapDispatchToProps)(PsychedelicsPdfReport);

export default psychedelicsPdf
