import React from 'react';
import {Redirect} from "react-router-dom";
import LoginContainer from '../pages/Authentication/LoginContainer'
import LogoutContainer from '../pages/Authentication/LogoutContainer'
import RegisterContainer from '../pages/Authentication/RegisterContainer'
import ResetPasswordContainer from '../pages/Authentication/ResetPassword/ResetPasswordContainer'
import Dashboard from '../pages/Dashboard/Dashboard'
import Trials from '../pages/Trials/Trials'
import TestResults from '../pages/TestResults/TestResults'
import Scanners from '../pages/Scanners/Scanners'
import PsychedelicsReportPage from '../pages/Reports/Psychedelics';
import CannabisReportPage from '../pages/Reports/Cannabis';
import psychedelicsPdf from "../pages/Pdf/psychedelicsPdf";
import cannabisPdf from "../pages/Pdf/cannabisPdf";

const authProtectedRoutes = [
  {path: "/psychedelics-report/:dataId/:trialId/:type", component: PsychedelicsReportPage},
  {path: "/cannabis-report/:dataId/:trialId/:type", component: CannabisReportPage},
  {path: "/dashboard", component: Dashboard},
  {path: "/groups", component: Trials},
  {path: "/test-results", component: TestResults},
  {path: "/scanners", component: Scanners},
  {path: "/", exact: true, component: () => <Redirect to="/dashboard"/>},
];

const publicRoutes = [
  {path: "/logout", component: LogoutContainer},
  {path: "/login", component: LoginContainer},
  {path: "/register", component: RegisterContainer},
  {path: "/account/reset/finish", component: ResetPasswordContainer},
  {path: "/pdf/psychedelics", component: psychedelicsPdf},
  {path: "/pdf/cannabis", component: cannabisPdf},
];

export {authProtectedRoutes, publicRoutes};
