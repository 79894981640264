import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { confirm } from "../Common/Modals";
import { Alert, Form, Button, Card, CardBody, Media } from 'reactstrap';
import { RefreshDataContext } from '../../App';
import { TextField, SelectField, HiddenField, CheckboxField } from './formikFields';
import { FormButtons } from './formHelpers';
import { trialStatusOptions, scannerStatusOptions, getLabelFromValue } from './selectOptions';
import { actionCreateTrial, actionCreateScanner, actionUpdateScanner, actionDeleteScanner, actionDeleteTest,
         actionCreateTrialLocation, actionUpdateTest, actionUpdateLocation, actionResetUserPassword, actionDeleteTrialLocation
        } from '../../actions/trials';
import { mapGenotype } from '../Trials/Tables';
import { actionTabs } from '../../actions/flags';
import { renderCBDG } from '../../lib/genetics-helper';

export function CreateLocationForm(props) {
  const [ refreshData, setRefreshData ] = useContext(RefreshDataContext);
  const { trialId, closeModal, thisTrialsLocations } = props;
  const existingLocationNames = thisTrialsLocations.map(t => t.label.toLowerCase());
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    location: Yup.string()
      .test("locationUnique", "Location name already used", (val) => !existingLocationNames.includes((val || '').toLowerCase()))
      .required('This is a required field'),
  });
  const serverErrorFields = {};

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ trialId: trialId, location: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(actionCreateTrialLocation(values))
          .then(() => { closeModal(); setRefreshData();} );
          setSubmitting(false);
        }}
      >
        {({values, errors, touched, resetForm, handleChange, handleBlur, handleSubmit, isSubmitting}) => {
          const formVars = {values, errors, handleChange, handleBlur, touched, validationSchema, serverErrorFields};
          return (
            <Form onSubmit={handleSubmit}>
              <HiddenField field="trialId" />
              <TextField key={2} fullWidth name="Location" field="location" placeholder="Enter a location..." {...formVars} />
              <FormButtons submit isSubmiting={isSubmitting} />
            </Form>
          )}}
      </Formik>
    </>
  )
}

export function AssignScannerForm(props) {
  const [ refreshData, setRefreshData ] = useContext(RefreshDataContext);
  const { trialId, locations, closeModal, allScanners } = props;
  const availableLocations = locations.map((l) => { return({label: l.label, value: l.value}) });
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('This is a required field')
      .email('Not a valid email address')
      .test("emailUnique", "This email address has already been used", (val) => !allScanners.includes((val || '').toLowerCase().trim())),
    locations: Yup.array()
      .of(Yup.string())
      .min(1, 'You must select at least one Location')
      .required('This is a required field'),
  });
  const serverErrorFields = {};
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ email: '', trialId: trialId, locations: availableLocations.length === 1 ? [availableLocations[0].value] : [] }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(actionCreateScanner(values))
          .then(() => {
            setRefreshData();
            closeModal();
          });
          setSubmitting(false);
        }}
      >
        {({values, errors, touched, resetForm, handleChange, handleBlur, handleSubmit, isSubmitting}) => {
          const formVars = {values, errors, handleChange, handleBlur, touched, validationSchema, serverErrorFields};
          return (
            <Form onSubmit={handleSubmit}>
              <HiddenField field="trialId" />
              <TextField key={1} fullWidth name="Scanner email address" field="email" placeholder="Email..." {...formVars} />
              <SelectField key={3} fullWidth name="Locations (hold ctrl to select multiple)" field="locations" multiple={true} selectText="" options={availableLocations} {...formVars} />
              <FormButtons submit isSubmiting={isSubmitting} />
            </Form>
          )}}
      </Formik>
    </>
  )
}

export function NewScannerCreatedForm(props) {
  const dispatch = useDispatch();
  const { closeModal, scanner } = props;
  if(scanner) {
    const locations = scanner.scannerLocations.map(sl => sl.trialLocations.name);
    const trialName = scanner.scannerLocations[0].trialLocations.trials.name;
    return (
      <>
        <h5 className="mb-4">Scanner is assigned to {trialName}:</h5>
        <p className="mb-1"><strong>Email:</strong> {scanner.email}</p>
        <p className="mb-1"><strong>Status:</strong> {scanner.scannerStatus}</p>
        <p className="mb-3"><strong>Location{locations.length > 1 ? 's' : ''}:</strong> {locations.join(', ')}</p>
        <p className="mb-2">Your new scanner is added and an activation email is sent to the address given. Please ask your scanner to check their email, and open the email from clinical@lobogene.com titled “Activate your Lobo Scanner account.”</p>
        <p className="mb-2">The scanner needs to activate their account by setting a password.</p>
        <p className="mb-2">If they do not see the email in a few minutes, please ask them to check their “junk mail” folder or “spam” folder. We make every effort to ensure that these emails are delivered.</p>
        <p className="mb-3">If they still don't receive an email, then write to clinical@lobogene.com explaining the problem. We will do our best to answer your request as soon as possible.</p>
        <div className="text-center">
          <Button
            color="primary"
            onClick={() => { closeModal(); }}
            className="actionButton mt-3 mb-2"
          >Close</Button>
        </div>
      </>
    )
  } else {
    return <div>Scanner not available!</div>
  }
}

export function CreateTrialForm(props) {
  const [ refreshData, setRefreshData ] = useContext(RefreshDataContext);
  const { closeModal, trials } = props;
  const existingTrialNames = trials.map(t => t.name.toLowerCase());
  const dispatch = useDispatch();
  const serverErrorFields = {};
  const validationSchema = Yup
    .object()
    .shape({
      trialName: Yup.string()
        .test("nameUnique", "Group name already used", (val) => !existingTrialNames.includes((val || '').toLowerCase()))
        .required('This is a required field'),
      trialStatus: Yup.string()
        .required('This is a required field'),
      psychedelicTesting: Yup.boolean(),
      thcTesting: Yup.boolean(),
      cannabisTesting: Yup.boolean(),
      cbdTesting: Yup.boolean(),
    })
    .test('testTypes', null, (obj) => {
      if(!(obj.psychedelicTesting || obj.cannabisTesting || obj.thcTesting || obj.cbdTesting))
        return new Yup.ValidationError('Please select at least one test type above', null, 'testCheckboxes');
      }
    );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ trialId: '', trialName: '', trialStatus: '',
          thcTesting: false,
          cbdTesting: false,
          cannabisTesting: false,
          psychedelicTesting: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(actionCreateTrial(values))
          .then(() => {
            dispatch(actionTabs('groups', 'main', values.trialName.toLowerCase().replace(/[^a-z0-9 ]/g, '')))
            setRefreshData(); }
          );
          setSubmitting(false);
        }}
      >
        {({values, errors, touched, resetForm, handleChange, handleBlur, handleSubmit, isSubmitting}) => {
          const formVars = {values, errors, handleChange, handleBlur, touched, validationSchema, serverErrorFields};
          return (
            <Form onSubmit={handleSubmit}>
              <TextField key={1} fullWidth name="Group Name" field="trialName" placeholder="Enter the group name..." {...formVars} />
              <SelectField key={2} fullWidth name="Group Status" field="trialStatus" selectText="Please select" options={trialStatusOptions} {...formVars} />
              <div className="my-4 ml-1">
                <CheckboxField key={3} fullWidth name="Cannabis Genetic Test" field="cannabisTesting" {...formVars} />
                <CheckboxField key={4} fullWidth name="Psychedelics Genetic Test" field="psychedelicTesting" {...formVars} />
              </div>
              {errors && errors.testCheckboxes && <div style={{color: '#f46a6a', marginLeft: '30px'}}>{errors.testCheckboxes}</div>}
              <FormButtons submit isSubmiting={isSubmitting} />
            </Form>
          )}}
      </Formik>
    </>
  )
}

export function NewTrialCreatedForm(props) {
  const dispatch = useDispatch();
  const [ refreshData, setRefreshData ] = useContext(RefreshDataContext);
  const { closeModal, trial } = props;
  if(trial.length) {
    const t = trial[0];
    const testsSelected = [];
    t.cbdTesting && testsSelected.push('CBD Genetic Test')
    t.thcTesting && testsSelected.push('THC Genetic Test')
    t.cannabisTesting && testsSelected.push('Cannabis Genetic Test')
    t.psychedelicTesting && testsSelected.push('Psychedelics Genetic Test')
    return (
      <>
        <h5 className="mb-4">The following new group has been created:</h5>
        <p><strong>Name:</strong> {t.name}</p>
        <p><strong>Status:</strong> {t.status}</p>
        <p><strong>Test{testsSelected.length > 1 ? 's' : ''} Selected:</strong> {testsSelected.join(', ')}</p>
        <div className="text-center">
          <Button
            color="primary"
            onClick={() => { closeModal(); } }
            className="actionButton mt-3 mb-2"
          >Close</Button>
        </div>
      </>
    )
  } else {
    return <div>Trial not available!</div>
  }
}

export function EditScannerForm(props) {
  const [ refreshData, setRefreshData ] = useContext(RefreshDataContext);
  const { scannerId, trialId, trials, locations, closeModal } = props;
  const dispatch = useDispatch();
  const [ resetPassword, setResetPassword ] = useState(false);
  const thisScanner = trials.filter(t => t.id == trialId)[0].scanners.filter(s => s.id == scannerId)[0];
  const thisScannersLocationsObj = (thisScanner && thisScanner.scannerLocations.map(l => ({trialLocationId: l.trialLocations.id, trialId: l.trialLocations.trials.id}))) || [];
  const thisScannersLocations = thisScannersLocationsObj.filter(s => s.trialId == trialId).map(s => s.trialLocationId);
  const initialValues = {
    trialId: trialId,
    email: thisScanner && thisScanner.email || '',
    scannerStatus: thisScanner && thisScanner.scannerStatus || '',
    locations: thisScanner && thisScanner.locations || '',
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('This is a required field'),
    locations: Yup.array()
      .of(Yup.string())
      .min(1, 'You must select at least one Location')
      .required('This is a required field'),
  });
  const serverErrorFields = {};
  return (
    <>
      { resetPassword
        ? <Alert className="mx-4">A reset password request has been emailed to: {thisScanner && thisScanner.email}</Alert>
        : <div className="text-right mr-4 mt-1 text-primary" style={{cursor: "pointer"}}><span onClick={() => {
            dispatch(actionResetUserPassword(thisScanner.email)).then(() => setResetPassword(true))
          }}>Reset Password</span></div>
      }
      <Formik
        enableReinitialize={true}
        initialValues={{ scannerId: scannerId, email: initialValues.email, trialId: initialValues.trialId, locations: thisScannersLocations, scannerStatus: initialValues.scannerStatus }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(actionUpdateScanner(values))
          .then(() => { closeModal(); setRefreshData();} );
          setSubmitting(false);
        }}
      >
        {({values, errors, touched, resetForm, handleChange, handleBlur, handleSubmit, isSubmitting}) => {
          const formVars = {values, errors, handleChange, handleBlur, touched, validationSchema, serverErrorFields};
          const availableLocations = locations.map((l) => { return({label: l.label, value: l.value}) });
          return (
            <Form onSubmit={handleSubmit}>
              <HiddenField field="scannerId" />
              <HiddenField field="trialId" />
              <TextField key={1} fullWidth name="Scanner email address" field="email" placeholder="Email..." {...formVars} />
              <SelectField key={2} fullWidth name="Status" field="scannerStatus" selectText="Please select" options={scannerStatusOptions} {...formVars} />
              <SelectField key={3} fullWidth name="Locations (hold ctrl to select multiple)" field="locations" multiple={true} selectText="" options={availableLocations} {...formVars} />
              <FormButtons submit isSubmiting={isSubmitting} />
            </Form>
          )}}
      </Formik>
      <div style={{position: 'relative', height: 0}}>
        <Button
          disabled={!(thisScanner && thisScanner.scannerStatus === 'PENDING')}
          color={(thisScanner && thisScanner.scannerStatus === 'PENDING') ? 'danger' : 'outline-disabled'}
          style={{position: 'absolute', left:'25px', top:'-60px'}}
          title={(thisScanner && thisScanner.scannerStatus === 'PENDING') ? 'Delete Scanner' : 'Delete scanner is only available to scanners in Pending status that have not started scanning test kits yet'}
          onClick={async () => {
            const deleteConfirmed = await confirm({
              size: "sm",
              title: "Delete Scanner Confirmation",
              message: `Are you sure you want to delete scanner: ${thisScanner.email}`,
              confirmText: "Yes",
              confirmColor: "outline-primary",
              cancelText: "No",
              cancelColor: "outline-secondary",
            });
            if(deleteConfirmed) dispatch(actionDeleteScanner(scannerId)).then(() => { closeModal(); setRefreshData();} );
        }}>Delete</Button>
      </div>
    </>
  )
}

export function ViewScannerForm(props) {
  const { scannerId, trialId, trials, closeModal } = props;
  const thisScanner = trials.filter(t => t.id == trialId)[0].scanners.filter(s => s.id == scannerId)[0];
  const thisScannersLocations = (thisScanner && thisScanner.scannerLocations.filter(sl => sl.trialLocations.trials.id == trialId).map(l => l.trialLocations.name)) || [];
  const scannerData = {
    trialId: trialId,
    email: thisScanner && thisScanner.email || '',
    scannerStatus: thisScanner && thisScanner.scannerStatus || '',
    locations: thisScannersLocations.join(", ") || '',
    startDate: thisScanner && thisScanner.createdDate || '',
  };
  return (
    <>
      <Card className="mini-stats-wid m-2" style={{border: "1px solid #F0F0F0"}}>
        <CardBody>
          <Media>
            <Media body>
            <p className="text-muted font-weight-medium mb-1">
                Scanner email:
                <span className="ml-2 text-priimary font-weight-bold">{scannerData.email}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Locations:
                <span className="ml-2 text-priimary font-weight-bold">{scannerData.locations}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Start Date:
                <span className="ml-2 text-priimary font-weight-bold">{scannerData.startDate ? moment(scannerData.startDate).format("MMM D, YYYY h:mm:A") : 'n/a'}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Status:
                <span className="ml-2 text-priimary font-weight-bold">{getLabelFromValue(scannerStatusOptions, scannerData.scannerStatus)}</span>
              </p>
            </Media>
          </Media>
        </CardBody>
      </Card>
      <div className="text-center">
        <Button
          color="primary"
          onClick={() => { closeModal(); }}
          className="actionButton mt-3 mb-2"
        >Close</Button>
      </div>
    </>
  )
}

export function EditPatientForm(props) {
  const { testId, trialId, fetchData, closeModal, locationFilter, locationsData } = props;
  const dispatch = useDispatch();
  const { swabHistory } = useSelector(state => state.Trials);
  const { value:locationId, label:locationName } =  locationFilter.length ? locationFilter[0] : locationsData[0];
  const thisTest = [
    ...(((swabHistory.psychedelicsTests && swabHistory.psychedelicsTests) || []).filter(t => t.id == testId)),
    ...(((swabHistory.cannabisTests && swabHistory.cannabisTests) || []).filter(t => t.id == testId)),
    ...(((swabHistory.cbdCannabisTests && swabHistory.cbdCannabisTests) || []).filter(t => t.id == testId)),
    ...(((swabHistory.thcCannabisTests && swabHistory.thcCannabisTests) || []).filter(t => t.id == testId))
  ][0] || [];
  const initialValues = {
    id: thisTest.id || 0,
    patientId: thisTest.patientId || '',
    type: thisTest.type
  };
  const validationSchema = Yup.object().shape({
    patientId: Yup.string()
      .required('This is a required field'),
  });
  const serverErrorFields = {};
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ id: initialValues.id, patientId: initialValues.patientId, type: initialValues.type }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(actionUpdateTest(values))
          .then(() => { closeModal(); fetchData(trialId, locationId); } );
          setSubmitting(false);
        }}
      >
        {({values, errors, touched, resetForm, handleChange, handleBlur, handleSubmit, isSubmitting}) => {
          const formVars = {values, errors, handleChange, handleBlur, touched, validationSchema, serverErrorFields};
          return (
            <Form onSubmit={handleSubmit}>
              <HiddenField field="id" />
              <TextField key={1} fullWidth name="Subject ID" field="patientId" placeholder="Enter the patient's ID..." {...formVars} />
              <FormButtons submit isSubmiting={isSubmitting} />
            </Form>
          )}}
      </Formik>

      <div style={{position: 'relative', height: 0}}>
        <Button
          disabled={!(thisTest && !thisTest.reportDate)}
          color={(thisTest && !thisTest.reportDate) ? 'danger' : 'outline-disabled'}
          style={{position: 'absolute', left:'25px', top:'-60px'}}
          title={(thisTest && !thisTest.reportDate) ? 'Delete Patient' : 'Delete patient is only available if the patient\'s test results have not been processed yet'}
          onClick={async () => {
            const deleteConfirmed = await confirm({
              size: "sm",
              title: "Delete Patient Confirmation",
              message: `Are you sure you want to delete patient: ${thisTest.patientId}`,
              confirmText: "Yes",
              confirmColor: "outline-primary",
              cancelText: "No",
              cancelColor: "outline-secondary",
            });
            if(deleteConfirmed) dispatch(actionDeleteTest(thisTest.kitId)).then(() => { closeModal(); fetchData(trialId, locationId); } );
        }}>Delete</Button>
      </div>
    </>
  )
}

export function ViewPatientForm(props) {
  const { testId, closeModal } = props;
  const { swabHistory } = useSelector(state => state.Trials);
  const thisTest = [
    ...(((swabHistory.psychedelicsTests && swabHistory.psychedelicsTests) || []).filter(t => t.id == testId)),
    ...(((swabHistory.cannabisTests && swabHistory.cannabisTests) || []).filter(t => t.id == testId)),
    ...(((swabHistory.cbdCannabisTests && swabHistory.cbdCannabisTests) || []).filter(t => t.id == testId)),
    ...(((swabHistory.thcCannabisTests && swabHistory.thcCannabisTests) || []).filter(t => t.id == testId))
  ][0] || [];
  const cyp2c19 = renderCBDG(thisTest).genotype.replace(/<[^>]*>/g, '');
  const testData = {
    id: thisTest.id || 0,
    type: {cbd: "CBD Genetic Test", cannabis: "Cannabis Genetic Test", thc: "THC Genetic Test", psychedelic: "Psychedelics Genetic Test"}[thisTest.type],
    location: (thisTest && thisTest.scannerLocations && thisTest.scannerLocations.trialLocations && thisTest.scannerLocations.trialLocations.name) || '',
    patientId: thisTest.patientId || '',
    testDate: thisTest.testDate ? moment(thisTest.testDate).format("MMM D, YYYY h:mm A") : 'n/a',
    reportDate: thisTest.reportDate ? moment(thisTest.reportDate).format("MMM D, YYYY h:mm A") : 'n/a',
    kitId: thisTest.kitId || '',
    testResults: (thisTest.type === 'cbd' && { cyp2c192: thisTest.cyp2c192, cyp2c193: thisTest.cyp2c193, cyp2c1917: thisTest.cyp2c1917 })
      || (thisTest.type === 'thc' && { comt: thisTest.comt, akt1: thisTest.akt1, cyp2c9: thisTest.cyp2c9 })
      || (thisTest.type === 'cannabis' && { cyp2c19: cyp2c19, comt: thisTest.comt, akt1: thisTest.akt1, cyp2c9: thisTest.cyp2c9 })
      || (thisTest.type === 'psychedelic' && { htr2a: thisTest.htr2a,	bdnf: thisTest.bdnf || "BDNF_PENDING", cyp2b6: thisTest.cyp2b6,
        cyp2d6: ((
          thisTest.cyp2d6 && thisTest.cyp2d6.result && thisTest.cyp2d6.result.phenotype && ((thisTest) => {
            if(!thisTest) return '';
            const cyp2d6CnvNumber = {'ZERO':0,'ONE':1,'TWO':2,'THREE':3,'FOUR':4,'FIVE':5}[thisTest.cyp2d6Cnv.replace('CYP2D6_', '')] || '';
            const cyp2d6Cnv = cyp2d6CnvNumber != '' ? ` (${cyp2d6CnvNumber}N)` : '';
            const cyp2d6Value = `${thisTest.cyp2d6ResultGenotypeFirst}/${thisTest.cyp2d6ResultGenotypeLast}${cyp2d6Cnv}`;
            return cyp2d6Value;
          })(thisTest)
        ) || 'PENDING'),
        c4a: thisTest.c4a, nrg1: thisTest.nrg1, disc1: thisTest.disc1 })
  };

  return (
    <>
      <Card className="mini-stats-wid m-2" style={{border: "1px solid #F0F0F0"}}>
        <CardBody>
          <Media>
            <Media body>
            <p className="text-muted font-weight-medium mb-1">
                Test Type:
                <span className="ml-2 text-priimary font-weight-bold">{testData.type}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Location:
                <span className="ml-2 text-priimary font-weight-bold">{testData.location}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Subject ID:
                <span className="ml-2 text-priimary font-weight-bold">{testData.patientId}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Swab Date:
                <span className="ml-2 text-priimary font-weight-bold">{testData.testDate}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Results Date:
                <span className="ml-2 text-priimary font-weight-bold">{testData.reportDate}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Swab ID:
                <span className="ml-2 text-priimary font-weight-bold">{testData.kitId}</span>
              </p>
            </Media>
          </Media>
        </CardBody>
      </Card>
      <Card className="mini-stats-wid m-2" style={{border: "1px solid #F0F0F0"}}>
        <CardBody>
          <Media>
            <Media body>
              <h5 className="text-muted font-weight-medium mb-3">Test Data:</h5>
              { Object.keys(testData.testResults).map((tk, i) => {
                  let value = testData.testResults[tk];
                  if(tk === 'cyp2d6') {
                    if(value === null || value === undefined || value === '') value = 'PENDING';
                    else if(value.toUpperCase().indexOf('PENDING') >= 0) value = 'PENDING';
                    else if(value.toUpperCase().indexOf('NO_RESULT') >= 0) value = 'INCONCLUSIVE';
                  }
                  else if(tk !== 'cyp2c19') value = mapGenotype(value);
                  return <p key={i} className="text-muted font-weight-medium mb-1 ml-4">
                    {tk.toUpperCase()}:
                    <span className="ml-2 text-priimary font-weight-bold">{value}</span>
                  </p>
                }
              )}
            </Media>
          </Media>
        </CardBody>
      </Card>
      <div className="text-center">
        <Button
          color="primary"
          onClick={() => { closeModal(); }}
          className="actionButton mt-3 mb-2"
        >Close</Button>
      </div>
    </>
  )
}

export function EditLocationForm(props) {
  const [ refreshData, setRefreshData ] = useContext(RefreshDataContext);
  const { locationId, trial, closeModal } = props;
  const { scanners } = trial;
  const thisLocation = trial.trialLocations.filter(tl => tl.id == locationId)[0] || {};
  const dispatch = useDispatch();
  const initialValues = {
    id: thisLocation.id || "",
    name: thisLocation.name || "",
    trialId: trial.id || ""
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('This is a required field'),
  });
  const serverErrorFields = {};
  const locationHasScanners = !!scanners.filter(s => !!s.scannerLocations.filter(sl => sl.trialLocations.id == thisLocation.id).length).length;
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ id: initialValues.id, trialId: initialValues.trialId, name: initialValues.name }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(actionUpdateLocation(values))
          .then(() => { closeModal(); setRefreshData();} );
          setSubmitting(false);
        }}
      >
        {({values, errors, touched, resetForm, handleChange, handleBlur, handleSubmit, isSubmitting}) => {
          const formVars = {values, errors, handleChange, handleBlur, touched, validationSchema, serverErrorFields};
          return (
            <Form onSubmit={handleSubmit}>
              <HiddenField field="id" />
              <HiddenField field="trialId" />
              <TextField key={1} fullWidth name="Location Name" field="name" placeholder="Enter the location's name..." {...formVars} />
              <FormButtons submit="Update" isSubmiting={isSubmitting} />
            </Form>
          )}}
      </Formik>
      <div style={{position: 'relative', height: 0}}>
        <Button
          disabled={locationHasScanners}
          color={!locationHasScanners ? 'danger' : 'outline-disabled'}
          style={{position: 'absolute', left:'25px', top:'-60px'}}
          title={!locationHasScanners ? 'Delete Location' : 'Delete location is only available if the location has not been assigned to a scanner yet'}
          onClick={async () => {
            const deleteConfirmed = await confirm({
              size: "sm",
              title: "Delete Location Confirmation",
              message: `Are you sure you want to delete Location: ${thisLocation.name} ?`,
              confirmText: "Yes",
              confirmColor: "outline-primary",
              cancelText: "No",
              cancelColor: "outline-secondary",
            });
            if(deleteConfirmed) dispatch(actionDeleteTrialLocation(trial.id, thisLocation.id)).then(() => { closeModal(); setRefreshData(); } );
        }}>Delete</Button>
      </div>
    </>
  )
}

export function ViewLocationForm(props) {
  const { locationId, trial, closeModal } = props;
  const thisLocation = trial.trialLocations.filter(tl => tl.id == locationId)[0] || {};
  const { counts } = useSelector(state => state.Trials);
  const { patients } = counts;
  const scanners = trial.scanners;
  const scannersLocations = scanners.map(s => ({scannerId: s.id, locations: s.scannerLocations.map(sl => ({[sl.trialLocations.id]: sl.id}))}));
  const locations = trial.trialLocations.map(l => {
    const scannerLocationIds = [].concat.apply([], scannersLocations.map(sl => sl.locations.filter(loc => loc[l.id]).map(thisLoc => thisLoc[l.id]) ));
    const patientCount = ([].concat.apply([], scannerLocationIds.map(sli => patients.filter(p => p.trialsId == trial.id && p.scannerLocationId == sli) )))
      .map(c => c.patientCount).reduce((a, b) => a + b, 0);
    return {
      id: l.id,
      name: l.name,
      numberOfPatients: patientCount,
      numberOfScanners: scanners.filter(s => s.scannerLocations.filter(sl => sl.trialLocations.id == l.id).length).length
    }
  });
  const thisLocationData = locations.filter(l => l.id == thisLocation.id)[0] || {};
  return (
    <>
      <Card className="mini-stats-wid m-2" style={{border: "1px solid #F0F0F0"}}>
        <CardBody>
          <Media>
            <Media body>
              <p className="text-muted font-weight-medium mb-1">
                Location Name:
                <span className="ml-2 text-priimary font-weight-bold">{thisLocationData.name}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Number Of Patients:
                <span className="ml-2 text-priimary font-weight-bold">{thisLocationData.numberOfPatients}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Number Of Scanners:
                <span className="ml-2 text-priimary font-weight-bold">{thisLocationData.numberOfScanners}</span>
              </p>
            </Media>
          </Media>
        </CardBody>
      </Card>
      <div className="text-center">
        <Button
          color="primary"
          onClick={() => { closeModal(); }}
          className="actionButton mt-3 mb-2"
        >Close</Button>
      </div>
    </>
  )
}
