import React, {Component} from 'react';


class Logout extends Component {
  /**
   * Redirect to login
   */
  componentDidMount = () => {
    // emit the event
    this.props.doLogout(this.props.history);
    this.props.history.push('/login');
  };

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default Logout;
