import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation  } from "react-router-dom";
import { RefreshDataContext } from '../../App';
import moment from 'moment';
import {MultiSelect} from "react-multi-select-component";
import classnames from 'classnames';
import { Table, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { actionTestTabs } from '../../actions/flags';
import { scannerStatusOptions, getLabelFromValue } from '../Form/selectOptions';
import { renderCBDG } from '../../lib/genetics-helper';

const tdStyle = {
  border: "1px solid #eee",
  verticalAlign: "top",
  padding: "10px 2px 10px 2px",
  fontSize: "12px",
};
const tdStyleSnips = {...tdStyle,
  overflowWrap: 'anywhere'
}
const tdStyleWrap = {...tdStyle,
  overflowWrap: 'anywhere',
  paddingLeft: '8px'
}
const tdStyleHeader = {...tdStyle,
  fontWeight: 'bold'
}
const tdStyleHeaderWrap = {...tdStyleHeader,
  overflowWrap: 'anywhere',
  paddingLeft: '8px'
}
const tdStyleHeaderSnips = {...tdStyleHeader,
  overflowWrap: 'anywhere'
}

export function TrialsTable(props) {
  const { trialId, trials, fetchData, openEditLocationModal, openViewLocationModal } = props;
  const { counts } = useSelector(state => state.Trials);
  const { patients } = counts;
  const thisTrial = trials.filter(t => t.id == trialId)[0];
  const scanners = thisTrial.scanners;
  const scannersLocations = scanners.map(s => ({scannerId: s.id, locations: s.scannerLocations.map(sl => ({[sl.trialLocations.id]: sl.id}))}));
  const locations = thisTrial.trialLocations.map(l => {
    const scannerLocationIds = [].concat.apply([], scannersLocations.map(sl => sl.locations.filter(loc => loc[l.id]).map(thisLoc => thisLoc[l.id]) ));
    const patientCount = ([].concat.apply([], scannerLocationIds.map(sli => patients.filter(p => p.trialsId == thisTrial.id && p.scannerLocationId == sli) )))
      .map(c => c.patientCount).reduce((a, b) => a + b, 0);
    return {
      id: l.id,
      name: l.name,
      numberOfPatients: patientCount,
      numberOfScanners: scanners.filter(s => s.scannerLocations.filter(sl => sl.trialLocations.id == l.id).length).length
    }
  });
  useEffect(() => { fetchData(trialId) }, [] );

  return (
    <div style={{overflow: "auto"}}>
      <Table className="mt-4" style={{minWidth: "450px"}}>
        <thead className="thead-light">
          <tr>
            <th style={tdStyleHeaderWrap} className="text-center">Location Name</th>
            <th style={tdStyleHeader} className="text-center">Number Of Subjects</th>
            <th style={tdStyleHeader} className="text-center">Number Of Scanners</th>
            <th style={tdStyleHeader} className="text-center">Edit</th>
          </tr>
        </thead>
        <tbody>
          { locations.map((l, i) => <tr key={i} onClick={() => openViewLocationModal(l.id)}>
                <td style={tdStyleWrap} className="text-center">{l.name}</td>
                <td style={tdStyle} className="text-center">{l.numberOfPatients}</td>
                <td style={tdStyle} className="text-center">{l.numberOfScanners}</td>
                <td style={tdStyle} className="text-center p-0 pt-1" onClick={(e) => {
                  e.stopPropagation();
                  openEditLocationModal(l.id);
                }}>
                  <i className="mdi mdi-pencil-box-outline font-size-18" />
                </td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </div>
  );
}

export function ScannersTable(props) {
  const { trialId, openEditScannerModal, locationFilter, openViewScannerModal, fetchData } = props;
  const data = props.data.filter((d) => {
    if(d.locations.some(l => locationFilter.map(lf => lf.label).includes(l.locationName))) return true;
    else return false;
  }) || [];
  useEffect(() => { fetchData(trialId) }, [] );
  return (<div style={{overflow: "auto"}}>
      <Table className="mt-4" style={{minWidth: "450px"}}>
        <thead className="thead-light">
          <tr>
            <th style={tdStyleHeaderWrap} className="text-center">Scanner email</th>
            <th style={tdStyleHeaderWrap} className="text-center">Locations</th>
            <th style={tdStyleHeader} className="text-center">Start Date</th>
            <th style={tdStyleHeader} className="text-center">Status</th>
            <th style={tdStyleHeader} className="text-center">Edit</th>
          </tr>
        </thead>
        <tbody>
          { data && data.length ?
              data.map((d, i) => <tr key={i} onClick={() => openViewScannerModal(d.id)}>
                <td style={tdStyleWrap} className="text-center">{d.email}</td>
                <td style={tdStyleWrap} className="text-center">{d.locations.filter(l => l.trialId == trialId).map(l => l.locationName).join(', ')}</td>
                <td style={tdStyle} className="text-center">{d.startDate ? moment(d.startDate).format("MMM D, YYYY h:mm A") : 'n/a'}</td>
                <td style={tdStyle} className="text-center">{getLabelFromValue(scannerStatusOptions, d.scannerStatus)}</td>
                <td style={tdStyle} className="text-center p-0 pt-1" onClick={(e) => {
                  e.stopPropagation();
                  openEditScannerModal(d.id);
                }}><i className="mdi mdi-pencil-box-outline font-size-18" /></td>
              </tr>
            ) : <tr><td colSpan="5" className="text-center">There are no scanners assigned to: {locationFilter.map(l => l.label).join(', ')}</td></tr>
          }

        </tbody>
      </Table>
    </div>
  );
}

export function PatientsTable(props) {
  const dispatch = useDispatch();
  const [ refreshData, setRefreshData, reportCanvas, setReportCanvas ] = useContext(RefreshDataContext);
  const { locationFilter, trialId, mainTab, fetchData, openEditPatientModal, openViewPatientModal } = props;
  const { swabHistory, trials } = useSelector(state => state.Trials);
  const [labReportDropdown, setLabReportDropdown] = useState({});
  const pageLocation = useLocation();
  const thisPageName = pageLocation.pathname.toLowerCase().replace(/[^a-z]/g, '');
  const { tabs } = useSelector(state => state.Flags);
  const pageTabs = tabs[thisPageName];
  let tabCount = 0;
  let tabContentCount = 0;
  const thisTrial = trials.filter(t => t.id == trialId)[0];
  const { trialLocations } = thisTrial;
  const selectedTab = (pageTabs && pageTabs.tests && pageTabs.tests[mainTab]) || 1;
  const thisLocationObj = trialLocations.filter(l => locationFilter.map(lf => lf.label).includes(l.name));
  useEffect(() => { fetchData(trialId) }, [] );

  if(!locationFilter.length) return <div className="text-center mt-5 mb-4">Select a location to view trial results</div>;
  if(!Object.keys(swabHistory).length) return <div className="text-center mt-5 mb-4">There are no test results for: {locationFilter.map(l => l.label).join(', ')}</div>;
  else return (<>
    <Nav fill tabs className="mt-5">
    {
      ['psychedelicTesting','cannabisTesting'].map((testType) => {
        if(!swabHistory[testType]) return false;
        tabCount++;
        const tabNumber = tabCount;
        const toggle = tab => {
          dispatch(actionTestTabs(thisPageName, mainTab, tab));
        }
        return <NavItem key={tabNumber}>
          <NavLink
            style={{
              border: 'none',
              borderBottom: `2px solid ${selectedTab === tabNumber ? '#3264F5' : '#D8D8D8'}`,
              color: selectedTab === tabNumber ? '#111' : '#AAA'
            }}
            className={classnames({ active: selectedTab === tabNumber })} onClick={() => { toggle(tabNumber); }}>
            {{psychedelicTesting: "Psychedelics", cbdTesting: "CBD", thcTesting: "THC", cannabisTesting: "Cannabis"}[testType]}
          </NavLink>
        </NavItem>
      })
    }
    </Nav>
    <TabContent activeTab={selectedTab} style={{border: "none", borderTop: "none", overflow: "auto"}}>
    {
      ['psychedelicTesting','cannabisTesting'].map((testType, i) => {
        if(!swabHistory[testType]) return false;
        tabContentCount++;
        const data = (testType === 'psychedelicTesting' && swabHistory['psychedelicsTests']
          || testType === 'cbdTesting' && swabHistory['cbdCannabisTests']
          || testType === 'thcTesting' && swabHistory['thcCannabisTests']
          || testType === 'cannabisTesting' && swabHistory['cannabisTests'])
          .filter(d => thisLocationObj.map(lo => lo.name).includes(d.scannerLocations.trialLocations.name))
          .sort((b, a) => (b.lastModifiedDate < a.lastModifiedDate) - (b.lastModifiedDate > a.lastModifiedDate));
        return <TabPane tabId={tabContentCount} key={tabContentCount} style={{minWidth: "825px"}}>
          <Table key={i} className="mt-4">
            <thead className="thead-light">
              <tr>
                <th style={tdStyleHeader} className="text-center">Subject&nbsp;ID</th>
                <th style={tdStyleHeader} className="text-center">Swab ID</th>
                <th style={tdStyleHeader} className="text-center">Location</th>
                <th style={tdStyleHeader} className="text-center">Swab Date</th>
                <th style={tdStyleHeader} className="text-center">Results Date</th>
                { testType === 'psychedelicTesting' && <>
                  <th style={tdStyleHeaderSnips} className="text-center">HTR2A</th>
                  <th style={tdStyleHeaderSnips} className="text-center">BDNF</th>
                  <th style={tdStyleHeaderSnips} className="text-center">CYP2B6</th>
                  <th style={tdStyleHeaderSnips} className="text-center">CYP2D6</th>
                  <th style={tdStyleHeaderSnips} className="text-center">C4A</th>
                  <th style={tdStyleHeaderSnips} className="text-center">NRG1</th>
                  <th style={tdStyleHeaderSnips} className="text-center">DISC1</th>
                </>}
                { (testType === 'cbdTesting') && <>
                  <th style={tdStyleHeaderSnips} className="text-center">CYP2C192</th>
                  <th style={tdStyleHeaderSnips} className="text-center">CYP2C193</th>
                  <th style={tdStyleHeaderSnips} className="text-center">CYP2C1917</th>
                </>}
                { (testType === 'thcTesting') && <>
                  <th style={tdStyleHeaderSnips} className="text-center">COMT</th>
                  <th style={tdStyleHeaderSnips} className="text-center">AKT1</th>
                  <th style={tdStyleHeaderSnips} className="text-center">CYP2C9</th>
                </>}
                { (testType === 'cannabisTesting') && <>
                  <th style={tdStyleHeaderSnips} className="text-center">CYP2C19</th>
                  <th style={tdStyleHeaderSnips} className="text-center">COMT</th>
                  <th style={tdStyleHeaderSnips} className="text-center">AKT1</th>
                  <th style={tdStyleHeaderSnips} className="text-center">CYP2C9</th>
                </>}
                <th style={tdStyleHeader} className="text-center">Lab Report</th>
                <th style={tdStyleHeader} className="text-center">Edit</th>
              </tr>
            </thead>
            <tbody>
              { data.map((d, i) => {
                  const cyp2c19 = renderCBDG(d).genotype.replace(/<[^>]*>/g, '');

                  let cyp2d6;
                  if(d.cyp2d6Cnv === null || d.cyp2d6Cnv === undefined || d.cyp2d6Cnv === '') cyp2d6 = 'PENDING';
                  else {
                    const cyp2d6CnvNumber = {'ZERO':0,'ONE':1,'TWO':2,'THREE':3,'FOUR':4,'FIVE':5}[d.cyp2d6Cnv.replace('CYP2D6_', '')] || '';
                    const cyp2d6Cnv = cyp2d6CnvNumber != '' ? ` (${cyp2d6CnvNumber}N)` : '';
                    if(d.cyp2d6ResultGenotypeFirst.includes('CYP2D6_')) cyp2d6 = d.cyp2d6ResultGenotypeFirst;
                    else cyp2d6 = `${d.cyp2d6ResultGenotypeFirst}/${d.cyp2d6ResultGenotypeLast}${cyp2d6Cnv}`;
                  }
                  if(cyp2d6 === 'CYP2D6_NO_RESULT') cyp2d6 = 'NO RESULT';
                  return <tr key={i} onClick={() => openViewPatientModal(d.id)}>
                    <td style={tdStyle} className="text-center">{d.patientId}</td>
                    <td style={tdStyle} className="text-center"><a style={{whiteSpace: 'nowrap'}} title={d.kitId}>{d.kitId.length > 10 ? d.kitId.substring(0,6) + '…' : d.kitId}</a></td>
                    <td style={tdStyle} className="text-center">{d.scannerLocations.trialLocations.name}</td>
                    <td style={tdStyle} className="text-center">{d.testDate ? moment(d.testDate).format("MMM D, YYYY h:mm A") : 'n/a'}</td>
                    <td style={tdStyle} className="text-center">{d.reportDate ? moment(d.reportDate).format("MMM D, YYYY h:mm A") : 'n/a'}</td>
                    { testType === 'psychedelicTesting' && <>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.htr2a)}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.bdnf || "BDNF_PENDING")}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.cyp2b6)}</td>
                      <td style={tdStyleSnips} className="text-center">{cyp2d6}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.c4a)}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.nrg1)}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.disc1)}</td>
                    </>}
                    { (testType === 'cbdTesting') && <>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.cyp2c192)}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.cyp2c193)}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.cyp2c1917)}</td>
                    </>}
                    { (testType === 'thcTesting') && <>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.comt)}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.akt1)}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.cyp2c9)}</td>
                    </>}
                    { (testType === 'cannabisTesting') && <>
                      <td style={tdStyleSnips} className="text-center">{cyp2c19}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.comt)}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.akt1)}</td>
                      <td style={tdStyleSnips} className="text-center">{mapGenotype(d.cyp2c9)}</td>
                    </>}
                    <td style={tdStyle} className="text-center p-0 pt-1" onClick={(e) => {
                        e.stopPropagation();
                    }}>
                      { !d.reportDate ? <div>n/a</div> :
                        <div>
                          {(() => {
                            const kitId = d.kitId;
                            const date = d.reportDate ? moment(d.reportDate).format("MMM-D-YYYY") : '';
                            const dateFileName = moment().format("YYYYMMDD");
                            const initials = d.patientId;
                            const location = d.scannerLocations.trialLocations.name;
                            let url;
                            let dataParam;
                            let fileName;
                            if(d.type === "psychedelic") {
                              const cyp2d6CnvNumber = {'ZERO':0,'ONE':1,'TWO':2,'THREE':3,'FOUR':4,'FIVE':5}[d.cyp2d6Cnv.replace('CYP2D6_', '')] || '';
                              const cyp2d6Cnv = cyp2d6CnvNumber != '' ? ` (${cyp2d6CnvNumber}N)` : '';
                              const cyp2d6Value = `${d.cyp2d6ResultPhenotype}-${d.cyp2d6ResultGenotypeFirst}/${d.cyp2d6ResultGenotypeLast}${cyp2d6Cnv}`;
                              url = `${process.env.REACT_APP_REPORT_PDF_URL}/psychedelics?data=`;
                              dataParam = encodeURIComponent(`{"swab":"${kitId}","date":"${date}","initials":"${initials}","location":"${location}","htr2a":"${d.htr2a}","bdnf":"${d.bdnf || 'BDNF_PENDING'}","cyp2b6":"${d.cyp2b6}","cyp2d6":"${cyp2d6Value}","c4a":"${d.c4a}","nrg1":"${d.nrg1}","disc1":"${d.disc1}"}`);
                              fileName = `HaluGen-PSY-Test-Lab-Report-${initials}-${dateFileName}.pdf`;
                            }
                            else {
                              url = `${process.env.REACT_APP_REPORT_PDF_URL}/cannabis?data=`;
                              dataParam = encodeURIComponent(`{"swab":"${kitId}","date":"${date}","initials":"${initials}","location":"${location}","cyp2c192":"${d.cyp2c192}","cyp2c193":"${d.cyp2c193}","cyp2c1917":"${d.cyp2c1917}","comt":"${d.comt}","akt1":"${d.akt1}","cyp2c9":"${d.cyp2c9}"}`);
                              fileName = `Lobo-MJ-Test-Lab-Report-${initials}-${dateFileName}.pdf`;
                            }
                            const pdfUrl = `${url}${dataParam}`;
                            //console.log("pdfUrl", pdfUrl);
                            return(
                              <form action={process.env.REACT_APP_PDF_SERVER} method="POST">
                                <input type="hidden" name="url" value={pdfUrl} />
                                <input type="hidden" name="filename" value={fileName} />
                                <button type="submit" formTarget="_blank" disabled={!isReportReady(d)}
                                  style={{border: "none", backgroundColor: "transparent"}}>
                                  <i className={`fa fa-download font-size-16 mt-1 ${isReportReady(d) ? 'text-black-50' : 'text-light'}`} style={{cursor: isReportReady(d) ? 'pointer' : 'text'}} />
                                </button>
                              </form>
                            )
                          })() }
                          </div>
                      }
                    </td>
                    <td style={tdStyle} className="text-center p-0 pt-1" onClick={(e) => {
                      e.stopPropagation();
                      openEditPatientModal(d.id);
                    }}>
                      <i className="mdi mdi-pencil-box-outline font-size-18" />
                    </td>
                  </tr>
                })
              }
            </tbody>
          </Table>
        </TabPane>
      })
    }
    </TabContent>
  </>
  );
}

export function FilterByLocation(props) {
  const { locationsData, locationFilter, setLocationFilter } = props;
  const pageLocation = useLocation();
  const thisPageName = pageLocation.pathname.toLowerCase().replace(/[^a-z]/g, '');
  useEffect(() => {setLocationFilter(locationFilter)}, []);
  const changeLocations = value =>  {
    setLocationFilter(value);
  };
  return (
    <div style={{padding: "10px", minWidth: "200px"}}>
      <span className="ml-2 font-weight-bold">{thisPageName === 'testresults' ? 'View locations:' : 'Filter results by location:'}</span>
      <MultiSelect
        overrideStrings={{
          allItemsAreSelected: "All locations selected",
          selectAll: "Select All Locations",
          selectSomeItems: "Select Locations..."
        }}
        className="mt-1"
        options={locationsData}
        value={locationFilter}
        onChange={changeLocations}
        disableSearch
      />
    </div>
  );
}

export function mapGenotype(value) {
  if(!value) return '';
  let mappedValue = '';
  const valueParts = value.split('_');
  if(valueParts.length) {
    switch(valueParts[0]) {
      case 'CYP2C19':
        mappedValue = valueParts.length === 3 ? valueParts[2] : `*${valueParts[2]}/*${valueParts[3]}`;
        break;

      case 'COMT':
        mappedValue = valueParts.length === 2 ? valueParts[1] : `${valueParts[1]}/${valueParts[2]}`;
        break;
      case 'AKT1':
        mappedValue = valueParts.length === 2 ? valueParts[1] : `${valueParts[1]}/${valueParts[2]}`;
        break;
      case 'CYP2C9':
        mappedValue = valueParts.length === 2 ? valueParts[1] : `*${valueParts[1]}/*${valueParts[2]}`;
        break;
      case 'HTR2A':
        mappedValue = valueParts.length === 2 ? valueParts[1] : `${valueParts[1]}/${valueParts[2]}`;
        break;
      case 'BDNF':
        mappedValue = valueParts.length === 2 ? valueParts[1] : `${valueParts[1]}/${valueParts[2]}`;
        break;
      case 'CYP2B6':
        mappedValue = valueParts.length === 2 ? valueParts[1] : `*${valueParts[1]}/*${valueParts[2]}`;
        break;
      case 'C4A':
        mappedValue = valueParts.length === 2 ? valueParts[1] : `${valueParts[1]}/${valueParts[2]}`;
        break;
      case 'NRG1':
        mappedValue = valueParts.length === 2 ? valueParts[1] : `${valueParts[1]}/${valueParts[2]}`;
        break;
      case 'DISC1':
        mappedValue = valueParts.length === 2 ? valueParts[1] : `${valueParts[1]}/${valueParts[2]}`;
        break;
      default:
        mappedValue = '';
    }
  }
  return mappedValue;
}

function isReportReady(r) {
  let isReportReady = false;
  const checkFields = r.type === "psychedelic" ? ['c4a', 'cyp2b6', 'disc1', 'htr2a', 'bdnf', 'nrg1', 'cyp2d6ResultPhenotype'] : ['cyp2c192', 'cyp2c193', 'cyp2c1917', 'comt', 'akt1', 'cyp2c9'];
  if(!r || typeof r != 'object' || !(Object.keys(r) && Object.keys(r).length)) return isReportReady;
  checkFields.forEach(f => {
    if(!(
      r[f] === undefined ||
      r[f] === null ||
      r[f] === '' ||
      r[f].toLowerCase().includes('no_result') ||
      r[f].toLowerCase().includes('inconclusive') ||
      r[f].toLowerCase().includes('pending')
    )) isReportReady = true;
  });
  return isReportReady;
}
