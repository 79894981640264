import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { setModal } from '../../components/Common/Modals';
import { TrialTabs } from '../../components/Trials/Tabs';

function Scanners() {
  const dispatch = useDispatch();
  const { trials, lastCreatedScanner } = useSelector(state => state.Trials);
  const { modals } = useSelector(state => state.Flags);
  useEffect(() => { if(lastCreatedScanner && !modals.newScannerCreated) {
    setModal(dispatch, modals, 'newScannerCreated', true);
  } else {return} }, [lastCreatedScanner, modals, dispatch]);
  return (
    <div className="page-content">
      <div className="pt-5">
        <Row>
            <Col>
              <h3 className="mb-5 mt-0">Scanners</h3>
              <TrialTabs trials={trials} tables={['scanners']} formModals={['assignScanner']} />
            </Col>
        </Row>
      </div>
    </div>
  );
}

export default withNamespaces()(Scanners);
