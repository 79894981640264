import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Row, Button, Nav, NavItem, NavLink, TabContent, TabPane, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import classnames from 'classnames';
import moment from 'moment';
import { CSVLink} from 'react-csv';
import { CenteredModal, setModal, confirm } from '../Common/Modals';
import { RefreshDataContext } from '../../App';
import { AssignScannerForm, EditScannerForm, ViewScannerForm, CreateLocationForm, EditPatientForm, ViewPatientForm, EditLocationForm, ViewLocationForm, NewScannerCreatedForm } from '../Form/Forms';
import { TrialsTable, ScannersTable, PatientsTable, FilterByLocation } from './Tables';
import { actionFetchAllSwabHistory, actionUpdateTrial, actionClearSwabHistory, actionDeleteTrial } from '../../actions/trials';
import { actionTabs, actionTabLocations, actionResetLastCreatedScanner } from '../../actions/flags';
import { getScannersLocations } from '../Common/locations';
import { renderCBDG } from '../../lib/genetics-helper';

export function TrialTabs(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ refreshData, setRefreshData ] = useContext(RefreshDataContext);
  const { swabHistory } = useSelector(state => state.Trials);
  const { trials, tables, formModals } = props;
  const pageLocation = useLocation();
  const thisPageName = pageLocation.pathname.toLowerCase().replace(/[^a-z]/g, '');
  const initialTabName = (trials.length && trials[0].name.toLowerCase().replace(/[^a-z0-9 ]/g, '')) || '';
  const { modals, tabs } = useSelector(state => state.Flags);
  const { lastCreatedScanner } = useSelector(state => state.Trials);
  const pageTabs = tabs[thisPageName];
  const [dropdownOpen, setDropdownOpen] = useState({});
  const toggle = tab => {
    dispatch(actionTabs(thisPageName, 'main', tab));
  }
  const locationData = trials.map((t) => { return({trial: t.id, locations: t.trialLocations
    .map((l) => { return({label: l.name, value: l.id}) })
    .sort((a, b) => (b.label.toLowerCase() < a.label.toLowerCase()) - (b.label.toLowerCase() > a.label.toLowerCase()))
  }) });
  const selectedTab = (pageTabs && pageTabs.main && pageTabs.main.selected) || initialTabName;
  const [screenSize, setScreenSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Nav tabs>
        { trials && trials.map((t, i) => {
            const thisTabName = t.name.toLowerCase().replace(/[^a-z0-9 ]/g, '');
            return <NavItem key={i}>
              <NavLink
                className={classnames({ active: selectedTab === thisTabName })}
                onClick={() => {
                  dispatch(actionClearSwabHistory());
                  toggle(thisTabName);
                }}
              >{t.name}</NavLink>
            </NavItem>;
          }
        )}
      </Nav>
      <TabContent activeTab={selectedTab}>
        { trials && trials.map((t, i) => {
              const thisTabName = t.name.toLowerCase().replace(/[^a-z0-9 ]/g, '');
              if(selectedTab === thisTabName && trials) {
              const setLocationFilter = locations => dispatch(actionTabLocations(thisPageName, thisTabName, locations));
              const thisTrialsLocations = locationData.filter(l => l.trial == t.id)[0].locations;
              const locationFilter = (pageTabs && pageTabs.locations && pageTabs.locations[thisTabName]) || thisTrialsLocations;
              const locationCount = thisTrialsLocations.length;
              const scannerTableData = t.scanners.map(s => ({
                id: s.id,
                email: s.email,
                locations: getScannersLocations(s.id, t.scanners),
                scannerStatus: s.scannerStatus,
                startDate: s.createdDate,
              }));

              const testsSelected = [];
              t.cbdTesting && testsSelected.push('CBD Genetic Test')
              t.thcTesting && testsSelected.push('THC Genetic Test')
              t.cannabisTesting && testsSelected.push('Cannabis Genetic Test')
              t.psychedelicTesting && testsSelected.push('Psychedelics Genetic Test')
              const canDeleteTrial = !t.scanners.length && !t.trialLocations.length;
              const numberOfTestResults = (
                (swabHistory.cbdCannabisTests ? swabHistory.cbdCannabisTests.length : 0) +
                (swabHistory.thcCannabisTests ? swabHistory.thcCannabisTests.length : 0) +
                (swabHistory.cannabisTests ? swabHistory.cannabisTests.length : 0) +
                (swabHistory.psychedelicsTests ? swabHistory.psychedelicsTests.length : 0)
              );

              //CSV Data
              const csvHeader = [
                //Scanner
                { label: "Scanner Name", key: "scannerName" },
                //Test
                { label: "Test Type", key: "type" },
                { label: "Location", key: "location" },
                { label: "Subject ID", key: "patientId" },
                { label: "Swab Date", key: "swabDate" },
                { label: "Results Date", key: "resultsDate" },
                { label: "Swab ID", key: "swabId" },
                //THC & Cannabis
                { label: "COMT", key: "comt" },
                { label: "AKT1", key: "akt1" },
                { label: "CYP2C9", key: "cyp2c9" },
                //CBD & Cannabis
                { label: "CYP2C19", key: "cyp2c19" },
                //Psychedelics
                { label: "HTR2A", key: "htr2a" },
                { label: "BDNF", key: "bdnf" },
                { label: "CYP2B6", key: "cyp2b6" },
                { label: "CYP2D6", key: "cyp2d6" },
                { label: "CYP2D6 Copy Number", key: "cyp2d6copynumber" },
                { label: "C4A", key: "c4a" },
                { label: "NRG1", key: "nrg1" },
                { label: "DISC1", key: "disc1" },
              ];
              const csvData = [].concat.apply([], ['cbdCannabisTests', 'thcCannabisTests', 'cannabisTests', 'psychedelicsTests'].map(test =>
                (swabHistory[test] || []).map(d => {
                  const scannerLocationId = d.scannerLocations.id
                  const scannerList = swabHistory.scanners.filter(s => s.scannerLocations.map(sl => sl.id).includes(scannerLocationId));
                  const scannerName = scannerList.map(sl => sl.email).join(', ');
                  let cyp2c19 = renderCBDG(d).genotype.replace(/<[^>]*>/g, '').replace('No Result', '');
                  if(cyp2c19) cyp2c19 = d.type != "psychedelic" && 'CYP2C19_' + cyp2c19.replace('/', '_').replaceAll('*', '');
                  let cyp2d6;
                  if(d.cyp2d6Cnv === null || d.cyp2d6Cnv === undefined || d.cyp2d6Cnv === '') cyp2d6 = d.type === "psychedelic" ? 'CYP2D6_PENDING' : '';
                  else {
                    const cyp2d6CnvNumber = {'ZERO':0,'ONE':1,'TWO':2,'THREE':3,'FOUR':4,'FIVE':5}[d.cyp2d6Cnv.replace('CYP2D6_', '')] || '';
                    const cyp2d6Cnv = cyp2d6CnvNumber != '' ? ` (${cyp2d6CnvNumber}N)` : '';
                    if(d.cyp2d6ResultGenotypeFirst.includes('CYP2D6_')) cyp2d6 = d.cyp2d6ResultGenotypeFirst;
                    else cyp2d6 = `CYP2D6_${d.cyp2d6ResultGenotypeFirst}/${d.cyp2d6ResultGenotypeLast}${cyp2d6Cnv}`.replaceAll('*', '').replace(/[\/ ]/g, '_').replace(/[)(]/g, '');
                  }

                  return ({
                    //Scanner data
                    scannerName: scannerName,
                    //Test data
                    type: d.type,
                    location: (d.scannerLocations.trialLocations && d.scannerLocations.trialLocations.name) || '',
                    patientId: d.patientId,
                    swabDate: d.testDate ? moment(d.testDate).format("MMM D, YYYY h:mm A") : 'n/a',
                    resultsDate: d.reportDate ? moment(d.reportDate).format("MMM D, YYYY h:mm A") : 'n/a',
                    swabId: d.kitId,
                    //THC & Cannabis Data
                    comt: d.comt,
                    akt1: d.akt1,
                    cyp2c9: d.cyp2c9,
                    //CBD & Cannabis Data
                    cyp2c19: cyp2c19 || '',
                    //Psychedelics Data
                    htr2a: d.htr2a,
                    bdnf: test === 'psychedelicsTests' ? d.bdnf || 'BDNF_PENDING' : '',
                    cyp2b6: d.cyp2b6,
                    cyp2d6: (() => {
                      let rVar = test === 'psychedelicsTests' ? ((d.cyp2d6 && d.cyp2d6.result && d.cyp2d6.result.phenotype) || 'Pending').toUpperCase() : '';
                      rVar = rVar && rVar.length && !rVar.includes('CYP2D6_') ? 'CYP2D6_' + rVar : rVar;
                      return rVar;
                    })(),
                    cyp2d6copynumber: cyp2d6,
                    c4a: d.c4a,
                    nrg1: d.nrg1,
                    disc1: d.disc1,
                  })
                })
              ));
              return (
                <TabPane tabId={thisTabName} key={i}>
                  <Row>
                    <Col sm="12">
                      <div style={{border: "1px solid #CED3D9", borderTop: 'none'}}>
                      <Card body className="mb-0">
                        <CardBody>
                          <Row>
                            <Col className="col-6">
                              <h3>{t.name}</h3>
                              <p className="mb-0"><span className="font-weight-bold">Test{testsSelected.length > 1 ? 's' : ''} Selected:</span> {testsSelected.join(', ')}</p>
                              <div className="py-1"><span className="font-weight-bold">Group Status:</span> {t.status}
                                <Dropdown
                                  style={{display: "inline-block", marginLeft: "10px"}}
                                  isOpen={dropdownOpen[t.id]}
                                  toggle={() => setDropdownOpen({...dropdownOpen, [t.id]: !dropdownOpen[t.id]})}
                                >
                                  <DropdownToggle
                                    className="actionButton p-0 m-0"
                                    color="primary"
                                    style={{width: "27px"}}
                                  ><i className="mdi mdi-pencil-box-outline font-size-18" /></DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem key={1} id={t.id + '-1'} onClick={() => {
                                      const data = {id: t.id, trialStatus: 'ACTIVE'};
                                      dispatch(actionUpdateTrial(data)).then(() => { setRefreshData();} );
                                    }}>ACTIVE</DropdownItem>
                                    <DropdownItem key={2} id={t.id + '-1'} onClick={() => {
                                      const data = {id: t.id, trialStatus: 'COMPLETED'};
                                      dispatch(actionUpdateTrial(data)).then(() => { setRefreshData();} );
                                    }}>COMPLETED</DropdownItem>
                                    <DropdownItem key={3} id={t.id + '-1'} onClick={() => {
                                      const data = {id: t.id, trialStatus: 'WITHDRAWN'};
                                      dispatch(actionUpdateTrial(data)).then(() => { setRefreshData();} );
                                    }}>WITHDRAWN</DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                              { thisPageName !== 'testresults' && <div
                                  className={`${numberOfTestResults > 0 ? 'actionLink' : 'disabledActionLink'} mb-2 mt-1`}
                                  onClick={() => {
                                    if(numberOfTestResults > 0) {
                                      dispatch(actionTabs('testresults', 'main', selectedTab));
                                      history.push('/test-results');
                                    }
                                  }}
                                >View Test Results</div>
                              }

                              { !!locationCount && selectedTab === thisTabName ? <>
                                  { (tables.includes("scanners") || tables.includes("patients")) &&
                                    <div className="mt-5">
                                      <FilterByLocation
                                        trialId={t.id}
                                        fetchData={(trialId) => dispatch(actionFetchAllSwabHistory(trialId))}
                                        locationsData={thisTrialsLocations}
                                        locationFilter={locationFilter}
                                        setLocationFilter={setLocationFilter}
                                      />
                                    </div>
                                  }
                                </>
                                : <p>There are currently no locations for this group</p>
                              }
                            </Col>
                            <Col className="col-6">
                            { formModals.includes("createLocation") &&
                              <Row>
                                <Col>
                                  <Button
                                    color="primary"
                                    className="actionButton btn float-right mb-2"
                                    onClick={() => setModal(dispatch, modals, 'createLocation', true, t.id)}
                                  >{screenSize.width > 530 ? 'Create a New Location' : <i className="mdi mdi-map-marker" />}</Button>
                                </Col>
                              </Row>
                            }
                            { !!locationCount && selectedTab === thisTabName && formModals.includes("assignScanner") &&
                              <Row>
                                <Col>
                                  <Button
                                    color="primary"
                                    className={`${thisPageName === 'scanners' ? 'actionButton' : 'secondaryActionButton'} btn float-right mb-2`}
                                    onClick={() => setModal(dispatch, modals, 'assignScanner', true, t.id)}
                                  >{screenSize.width > 530 ? 'Assign a New Scanner' : <i className="mdi mdi-barcode-scan" />}</Button>
                                </Col>
                              </Row>
                            }
                            { !!locationCount && !!Object.keys(swabHistory).length &&
                              <Row>
                                <Col>
                                  <CSVLink
                                    headers={csvHeader}
                                    data={csvData}
                                    className="secondaryActionButton btn float-right mb-2"
                                    filename={`group-data-${t.name.toLowerCase().replace(/[^a-z0-9 ]/i, '').replace(' ', '-')}.csv`}
                                  >{screenSize.width > 530 ? 'Export Group Data' : <i className="fas fa-file-export"></i>}</CSVLink>
                                </Col>
                              </Row>
                            }
                            { thisPageName === 'groups' &&
                              <Row>
                                <Col>
                                  <Button
                                    color={canDeleteTrial ? 'danger' : 'outline-disabled'}
                                    className="btn float-right mb-2"
                                    style={{
                                      borderRadius: "5px",
                                      fontSize: screenSize.width > 530 ? "14px" : "18px",
                                      width: screenSize.width > 530 ? "180px" : "36px",
                                      padding: "3px 8px 2px 8px"
                                    }}
                                    disabled={!canDeleteTrial}
                                    title={canDeleteTrial ? 'Delete this group' : 'Delete group is disabled if the trial has locations assigned, scanners attached or test results scanned'}
                                    onClick={async () => {
                                      const deleteConfirmed = await confirm({
                                        size: "sm",
                                        title: "Delete Group",
                                        message: `Are you sure you want to delete this group?`,
                                        confirmText: "Yes",
                                        confirmColor: "outline-primary",
                                        cancelText: "No",
                                        cancelColor: "outline-secondary",
                                      });
                                      if(deleteConfirmed) dispatch(actionDeleteTrial(t.id)).then(() => {
                                        setRefreshData();
                                        dispatch(actionTabs('groups', 'main', trials[0] ? trials[0].name.toLowerCase().replace(/[^a-z0-9 ]/g, '') : '' ));
                                      });
                                    }}
                                  >{screenSize.width > 530 ? 'Delete This Group' : <i className="mdi mdi-delete-forever" />}</Button>
                                </Col>
                              </Row>
                            }
                            </Col>
                          </Row>
                          { !!locationCount && selectedTab === thisTabName && tables.includes("trials") &&
                            <TrialsTable
                              trialId={t.id}
                              trials={trials}
                              fetchData={(trialId) => dispatch(actionFetchAllSwabHistory(trialId))}
                              openEditLocationModal={(locationId) => setModal(dispatch, modals, 'editLocation', locationId, t.id)}
                              openViewLocationModal={(locationId) => setModal(dispatch, modals, 'viewLocation', locationId, t.id)}
                            /> }
                          { !!locationCount && selectedTab === thisTabName && tables.includes("scanners") &&
                            <ScannersTable
                              trialId={t.id}
                              data={scannerTableData}
                              locationFilter={locationFilter}
                              fetchData={(trialId) => dispatch(actionFetchAllSwabHistory(trialId))}
                              openEditScannerModal={(scannerId) => setModal(dispatch, modals, 'editScanner', scannerId, t.id)}
                              openViewScannerModal={(scannerId) => setModal(dispatch, modals, 'viewScanner', scannerId, t.id)}
                            /> }
                          { !!locationCount && selectedTab === thisTabName && tables.includes("patients") &&
                            <PatientsTable
                              locationFilter={locationFilter}
                              trialId={t.id}
                              mainTab={thisTabName}
                              fetchData={(trialId) => dispatch(actionFetchAllSwabHistory(trialId))}
                              openEditPatientModal={(testId) => setModal(dispatch, modals, 'editPatient', testId, t.id)}
                              openViewPatientModal={(testId) => setModal(dispatch, modals, 'viewPatient', testId, t.id)}
                            /> }
                        </CardBody>
                      </Card>
                      </div>
                    </Col>
                  </Row>
                  <CenteredModal title="Assign a new scanner" modals={modals} setModal={setModal} type="assignScanner" id={t.id}>
                    <AssignScannerForm trialId={t.id} locations={thisTrialsLocations} allScanners={t.scanners.map(s => s.email.toLowerCase().trim())}
                      closeModal={() => setModal(dispatch, modals, 'assignScanner', false, t.id)} />
                  </CenteredModal>
                  <CenteredModal title="New scanner assigned" modals={modals} setModal={() => {
                    dispatch(actionResetLastCreatedScanner());
                    setModal(dispatch, modals, 'newScannerCreated')
                  }} type="newScannerCreated">
                    <NewScannerCreatedForm closeModal={() => {
                      dispatch(actionResetLastCreatedScanner());
                      setModal(dispatch, modals, 'newScannerCreated', false);
                    }} scanner={t.scanners.find(s => s.id === lastCreatedScanner)} />
                  </CenteredModal>
                  <CenteredModal title="Edit an existing scanner" modals={modals} setModal={setModal} type="editScanner" id={t.id}>
                    <EditScannerForm scannerId={modals.editScanner[t.id]} trialId={t.id} trials={trials} locations={thisTrialsLocations}
                      closeModal={() => setModal(dispatch, modals, 'editScanner', false, t.id)} />
                  </CenteredModal>
                  <CenteredModal title="Scanner Details" modals={modals} setModal={setModal} type="viewScanner" id={t.id}>
                    <ViewScannerForm scannerId={modals.viewScanner[t.id]} trialId={t.id} trials={trials} locations={thisTrialsLocations}
                      closeModal={() => setModal(dispatch, modals, 'viewScanner', false, t.id)} />
                  </CenteredModal>
                  <CenteredModal title="Create a new location" modals={modals} setModal={setModal} type="createLocation" id={t.id}>
                    <CreateLocationForm trialId={t.id} closeModal={() => setModal(dispatch, modals, 'createLocation', false, t.id)} thisTrialsLocations={thisTrialsLocations} />
                  </CenteredModal>
                  <CenteredModal title="Edit a patient" modals={modals} setModal={setModal} type="editPatient" id={t.id}>
                    <EditPatientForm trialId={t.id} testId={modals.editPatient[t.id]} locationFilter={locationFilter} locationsData={thisTrialsLocations}
                      closeModal={() => setModal(dispatch, modals, 'editPatient', false, t.id)}
                      fetchData={(trialId) => dispatch(actionFetchAllSwabHistory(trialId))} />
                  </CenteredModal>
                  <CenteredModal title="Subject Details" modals={modals} setModal={setModal} type="viewPatient" id={t.id}>
                    <ViewPatientForm trialId={t.id} testId={modals.viewPatient[t.id]} locationFilter={locationFilter} locationsData={thisTrialsLocations}
                      closeModal={() => setModal(dispatch, modals, 'viewPatient', false, t.id)} />
                  </CenteredModal>
                  <CenteredModal title="Edit location" modals={modals} setModal={setModal} type="editLocation" id={t.id}>
                    <EditLocationForm trial={t} locationId={modals.editLocation[t.id]}
                      closeModal={() => setModal(dispatch, modals, 'editLocation', false, t.id)} />
                  </CenteredModal>
                  <CenteredModal title="Location Details" modals={modals} setModal={setModal} type="viewLocation" id={t.id}>
                    <ViewLocationForm trial={t} locationId={modals.viewLocation[t.id]}
                      closeModal={() => setModal(dispatch, modals, 'viewLocation', false, t.id)} />
                  </CenteredModal>
                </TabPane>
              )
            }
            else return false;
          }
        )}
      </TabContent>
    </>
  );
}
