import { api, action } from '../lib/actions'

// *** SCANNERS *** //
export const actionCreateScanner = (formValues) => {
  const options = {method: 'POST',
    body: {
      email: formValues.email || '',
      scannerStatus: formValues.scannerStatus || 'PENDING',
      trials: [{id: formValues.trialId}],
      scannerLocations: formValues.locations.map((l) => ({trialLocations: {id: Number.parseInt(l)}}))
    }
  };
  return action('CREATE_SCANNER', `${api}/scanners`, options);
};
export const actionUpdateScanner = (formValues) => {
  const options = {method: 'PATCH',
    body: {
      id: formValues.scannerId,
      email: formValues.email || '',
      scannerStatus: formValues.scannerStatus || '',
      trials: [{id: formValues.trialId}],
      scannerLocations: formValues.locations.map((l) => ({trialLocations: {id: Number.parseInt(l)}}))
    }
  };
  return action('UPDATE_SCANNER', `${api}/scanners`, options);
};
export function actionDeleteScanner(id) {
  return action('DELETE_SCANNER', `${api}/scanners/${id}`, {method: 'DELETE'});
};

// *** LOCATIONS *** //
export function actionFetchScannerLocations() {
  return action('FETCH_SCANNER_LOCATIONS', `${api}/scanner-locations`, {method: 'GET'});
};
export function actionFetchTrialLocations() {
  return action('FETCH_TRIAL_LOCATIONS', `${api}/trial-locations`, {method: 'GET'});
};
export const actionCreateTrialLocation = (formValues) => {
  const options = {method: 'POST',
    body: {
      name: formValues.location || '',
      trials: {
        id: formValues.trialId
      }
    }
  };
  return action('CREATE_TRIAL_LOCATION', `${api}/trial-locations`, options);
};
export const actionUpdateLocation = (formValues) => {
  const options = {method: 'PATCH',
    body: {
      id: formValues.id || '',
      name: formValues.name || '',
      trials: {
        id: formValues.trialId
      }
    }
  };
  return action('UPDATE_TRIAL_LOCATION', `${api}/trial-locations`, options);
};
export function actionDeleteTrialLocation(trialId, locationId) {
  return action('DELETE_TRIAL_LOCATION', `${api}/trial-locations/${trialId}/${locationId}`, {method: 'DELETE'});
};

// *** TRIALS *** //
export function actionFetchTrials() {
  return action('FETCH_TRIALS', `${api}/trials`, {method: 'GET'});
}
export const actionCreateTrial = (formValues) => {
  const options = {method: 'POST',
    body: {
      trialId: formValues.trialId || '',
      name: formValues.trialName || '',
      status: formValues.trialStatus || null,
      thcTesting: formValues.thcTesting || false,
      cbdTesting: formValues.cbdTesting || false,
      cannabisTesting: formValues.cannabisTesting || false,
      psychedelicTesting: formValues.psychedelicTesting || false,
    }
  };
  return action('CREATE_TRIAL', `${api}/trials`, options);
};
export const actionUpdateTrial = (formValues) => {
  const options = {method: 'PATCH',
    body: {
      id: formValues.id,
      status: formValues.trialStatus || '',
    }
  };
  return action('UPDATE_TRIAL', `${api}/trials`, options);
};
export function actionDeleteTrial(trialId) {
  return action('DELETE_TRIAL', `${api}/trials/${trialId}`, {method: 'DELETE'});
};

// *** TESTS *** //
export function actionFetchPsychedelicsTests() {
  return action('FETCH_PSYCHEDELICS_TESTS', `${api}/psychedelics-tests`, {method: 'GET'});
};
export const actionUpdateTest = (formValues) => {
  const options = {method: 'PATCH',
    body: {
      id: formValues.id,
      patientId: formValues.patientId || '',
      type: formValues.type
    }
  };
  return action('UPDATE_PSYCHEDELICS_TESTS', `${api}/test`, options);
};
export function actionFetchCannabisTests() {
  return action('FETCH_CANNABIS_TESTS', `${api}/cannabis-tests`, {method: 'GET'});
};
export function actionFetchThcCannabisTests() {
  return action('FETCH_THC_CANNABIS_TESTS', `${api}/cannabis-tests`, {method: 'GET'});
};
export function actionFetchCbdCannabisTests() {
  return action('FETCH_CBD_CANNABIS_TESTS', `${api}/cannabis-tests`, {method: 'GET'});
};
export function actionFetchSwabHistory(trialId, locationId) {
  return action('FETCH_SWAB_HISTORY', `${api}/trials/test/results?trialId=${trialId}&locationId=${locationId}`, {method: 'GET'});
};
export function actionFetchAllSwabHistory(trialId) {
  return action('FETCH_ALL_SWAB_HISTORY', `${api}/trials/test/results?trialId=${trialId}`, {method: 'GET'});
};
export function actionClearSwabHistory() {
  return ({type: 'CLEAR_SWAB_HISTORY_SUCCESS'});
};
export function actionDeleteTest(kitId) {
  return action('DELETE_TEST', `${api}/test/${kitId}`, {method: 'DELETE'});
};

// *** OTHER *** //
export function actionFetchDashboardCounts() {
  return action('FETCH_DASHBOARD_COUNTS', `${api}/clinic-admin/count`, {method: 'GET'});
};
export function actionFetchPatientCounts() {
  return action('FETCH_PATIENT_COUNTS', `${api}/trials/test/patient-count`, {method: 'GET'});
};
export function actionResetUserPassword(email) {
  const options = {method: 'POST',
    body: email
  };
  return action('RESET_USER_PASSWORD', `${api}/account/reset-password/init`, options);
};
