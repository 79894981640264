import React, { Component } from 'react'
import {Link} from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation'
// Redux
// import images
import profileImg from '../../assets/images/profile-img.png'
import { isNullOrUndefined } from '../../lib/helper'
import queryString from 'query-string'

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      processingRegistration: false
    };

    const values = queryString.parse(props.location.search);
    this.state.email = values.email;

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.setState({error: undefined, processingRegistration: true});
    if(values.password !== values.cPassword){
      this.setState({error: 'Password does not match confirm password', processingRegistration: false})
      return;
    }

    this.props.doLogout(this.props.history);

    this.props.registerUser(values.email, values.password)
    .then(res => {
      this.props.history.push('/dashboard')
    }).catch(err => {
      if(
        (err.errors && err.errors && err.errors.detail && err.errors.detail.base && err.errors.detail.base[0] === 'is invalid')
        ||
        (err.errors && err.errors[0] && err.errors[0].detail === 'That email address is already taken.')
      ){ this.setState({processingRegistration: false, error: 'That email address is already taken'}); }
      else if(
        (err.errors && err.errors && err.errors.detail && err.errors.detail.joined_name && err.errors.detail.joined_name[0] === 'has already been taken')
        ||
        (err.errors && err.errors.detail && err.errors.detail.joined_name && err.errors.detail.joined_name[0] === 'has already been taken')
      ){
        this.setState({processingRegistration: false, error: 'That store name is already taken'});
      }else{
        this.setState({processingRegistration: false, error: 'Something went wrong. We are taking a look into it. Please try again later'});
      }
    })
  }

  isValidEmail(email) {
    return false;
  }

  componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Register</h5>
                          <p>Register for a Clinical Dashboard account.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid"/>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <div>
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={require('../../assets/images/favicon.ico')} alt=""
                                 className="rounded-circle" height="34"/>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}
                        {this.props.registrationError || !isNullOrUndefined(this.state.error) ? (
                          <Alert color="danger">
                            {this.props.registrationError || this.state.error}
                          </Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            value={this.state.email}
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="cPassword"
                            label="Confirm Password"
                            type="password"
                            required
                            placeholder="Confirm Password"
                          />
                        </div>

                        {!this.state.processingRegistration ?
                          <div className="mt-4">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit">
                              Register
                            </button>
                          </div>
                          :
                          <div className='w-100 d-flex justify-content-center'>
                            <div className='spinner-border text-primary m-1' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </div>
                          </div>}
                      </AvForm>
                    </div>
                    <div className="text-center pt-3 pb-1">
                      <Link to="/login">Do you have an account? sign in.</Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Register;
