export function translatePsychedelicObjToApi(obj) {
  let api = {
    ...obj,
    cyp2d6Cnv: cnvValueToApi(obj.cyp2d6['cnv']),
    cyp2d6Exon9: rsValueToApi(obj.cyp2d6['Exon 9']),
    cyp2d6ResultActivityScore: obj.cyp2d6.result.activityScore,
    cyp2d6ResultGenotypeFirst: obj.cyp2d6.result.genotypeFirst,
    cyp2d6ResultGenotypeLast: obj.cyp2d6.result.genotypeLast,
    cyp2d6ResultPhenotype: obj.cyp2d6.result.phenotype,
    cyp2d6Rs16947: rsValueToApi(obj.cyp2d6['rs16947']),
    cyp2d6Rs1065852: rsValueToApi(obj.cyp2d6['rs1065852']),
    cyp2d6Rs1135840: rsValueToApi(obj.cyp2d6['rs1135840']),
    cyp2d6Rs3892097: rsValueToApi(obj.cyp2d6['rs3892097']),
    cyp2d6Rs5030655: rsValueToApi(obj.cyp2d6['rs5030655']),
    cyp2d6Rs5030656: rsValueToApi(obj.cyp2d6['rs5030656']),
    cyp2d6Rs28371706: rsValueToApi(obj.cyp2d6['rs28371706']),
    cyp2d6Rs28371725: rsValueToApi(obj.cyp2d6['rs28371725']),
    cyp2d6Rs35742686: rsValueToApi(obj.cyp2d6['rs35742686']),
    cyp2d6Rs59421388: rsValueToApi(obj.cyp2d6['rs59421388'])
  };
  return api;
}

export function translatePsychedelicApiToObj(api) {
  let obj = {
    ...api,
    cyp2d6: {
      result: {
        activityScore: api.cyp2d6ResultActivityScore,
        genotypeFirst: api.cyp2d6ResultGenotypeFirst,
        genotypeLast: api.cyp2d6ResultGenotypeLast,
        phenotype: api.cyp2d6ResultPhenotype,
      },
      cnv: cnvValueToObj(api.cyp2d6Cnv),
      ['Exon 9']: rsValueToObj(api.cyp2d6Exon9),
      rs16947: rsValueToObj(api.cyp2d6Rs16947),
      rs1065852: rsValueToObj(api.cyp2d6Rs1065852),
      rs1135840: rsValueToObj(api.cyp2d6Rs1135840),
      rs3892097: rsValueToObj(api.cyp2d6Rs3892097),
      rs5030655: rsValueToObj(api.cyp2d6Rs5030655),
      rs5030656: rsValueToObj(api.cyp2d6Rs5030656),
      rs28371706: rsValueToObj(api.cyp2d6Rs28371706),
      rs28371725: rsValueToObj(api.cyp2d6Rs28371725),
      rs35742686: rsValueToObj(api.cyp2d6Rs35742686),
      rs59421388: rsValueToObj(api.cyp2d6Rs59421388),
    }
  };
  return obj;
}

function rsValueToApi(value) {
  if(value === '+/+') return 'PLUS_PLUS';
  else if(value === '+/m') return 'PLUS_M';
  else if(value === 'm/m') return 'M_M';
  else if(value === 'Pending') return 'PENDING';
  else if(value === 'No Result') return 'NO_RESULT';
  else return value;
}
function cnvValueToApi(value) {
  if(value == '0') return 'ZERO';
  else if(value == '1') return 'ONE';
  else if(value == '2') return 'TWO';
  else if(value == '3') return 'THREE';
  else if(value == '4') return 'FOUR';
  else if(value === 'Pending') return 'PENDING';
  else if(value === 'No Result') return 'NO_RESULT';
  else return value;
}

function rsValueToObj(value) {
  if(value === 'PLUS_PLUS') return '+/+';
  else if(value === 'PLUS_M') return '+/m';
  else if(value === 'M_M') return 'm/m';
  else if(value === 'PENDING') return 'Pending';
  else if(value === 'NO_RESULT') return 'No Result';
  else return value;
}
function cnvValueToObj(value) {
  if(value === 'ZERO') return '0';
  else if(value === 'ONE') return '1';
  else if(value === 'TWO') return '2';
  else if(value === 'THREE') return '3';
  else if(value === 'FOUR') return '4';
  else if(value === 'PENDING') return 'Pending';
  else if(value === 'NO_RESULT') return 'No Result';
  else return value;
}
