import React from 'react';
import moment from 'moment';
import {Doughnut} from "react-chartjs-2";
import { mapGenotype } from '../Tables';

export default function PsychedelicsReport({data, trial}) {
  const htr2aGenotype = mapGenotype(data.htr2a);
  const htr2a = {
    genotype: htr2aGenotype,
    phenotype:  htr2aGenotype === 'C/C' && 'Normal' ||
      htr2aGenotype === 'C/T' && 'Normal' ||
      htr2aGenotype === 'T/T' && 'Increased' ||
      htr2aGenotype === 'INCONCLUSIVE' && 'Inconclusive' ||
      htr2aGenotype === 'PENDING' && 'PENDING' ||
      '',
    summary: {
      title: htr2aGenotype === 'C/C' && 'Normal Sensitivity' ||
        htr2aGenotype === 'C/T' && 'Normal Sensitivity' ||
        htr2aGenotype === 'T/T' && 'Increased Sensitivity' ||
        htr2aGenotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        htr2aGenotype === 'PENDING' && 'PENDING' ||
        '',
      text: htr2aGenotype === 'C/C' && 'Serotonin Receptors density is normal.' ||
        htr2aGenotype === 'C/T' && 'Serotonin Receptors density is normal.' ||
        htr2aGenotype === 'T/T' && 'Serotonin Receptors density is increased.' ||
        htr2aGenotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        htr2aGenotype === 'PENDING' && 'PENDING' ||
        '',
    },
    description: {
      subTitle: htr2aGenotype === 'C/C' && 'Normal Risk' ||
        htr2aGenotype === 'C/T' && 'Normal Risk' ||
        htr2aGenotype === 'T/T' && 'Increased Risk' ||
        htr2aGenotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        htr2aGenotype === 'PENDING' && 'PENDING' ||
        '',
      description: htr2aGenotype === 'C/C' && 'HTR2A Serotonin Receptors density is normal.' ||
        htr2aGenotype === 'C/T' && 'HTR2A Serotonin Receptors density is normal.' ||
        htr2aGenotype === 'T/T' && 'HTR2A Serotonin Receptors density is increased.' ||
        htr2aGenotype === 'INCONCLUSIVE' && '' ||
        htr2aGenotype === 'PENDING' && '' ||
        '',
    }
  };

  const cyp2b6Genotype = mapGenotype(data.cyp2b6);
  const cyp2b6 = {
    genotype: cyp2b6Genotype,
    phenotype:  cyp2b6Genotype === '*1/*1' && 'Normal' ||
      cyp2b6Genotype === '*1/*6' && 'Slow' ||
      cyp2b6Genotype === '*6/*6' && 'Very Slow' ||
      cyp2b6Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
      cyp2b6Genotype === 'PENDING' && 'PENDING' ||
      '',
    summary: {
      title: cyp2b6Genotype === '*1/*1' && 'Normal Metabolizer' ||
        cyp2b6Genotype === '*1/*6' && 'Slow Metabolizer' ||
        cyp2b6Genotype === '*6/*6' && 'Very Slow Metabolizer' ||
        cyp2b6Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        cyp2b6Genotype === 'PENDING' && 'PENDING' ||
        '',
      text: cyp2b6Genotype === '*1/*1' && 'Metabolized Ketamin at a normal rate.' ||
        cyp2b6Genotype === '*1/*6' && 'Metabolized Ketamin at a slower rate.' ||
        cyp2b6Genotype === '*6/*6' && 'Metabolized Ketamin at a very slow rate.' ||
        cyp2b6Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        cyp2b6Genotype === 'PENDING' && 'PENDING' ||
        '',
    },
    description: {
      subTitle: cyp2b6Genotype === '*1/*1' && 'Normal Metabolizer' ||
        cyp2b6Genotype === '*1/*6' && 'Slow Metabolizer' ||
        cyp2b6Genotype === '*6/*6' && 'Very Slow Metabolizer' ||
        cyp2b6Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        cyp2b6Genotype === 'PENDING' && 'PENDING' ||
        '',
      description: cyp2b6Genotype === '*1/*1' && 'Normal Metabolizer of Ketamin.' ||
        cyp2b6Genotype === '*1/*6' && 'Slow Metabolizer of Ketamin.' ||
        cyp2b6Genotype === '*6/*6' && 'Very Slow Metabolizer of Ketamin.' ||
        cyp2b6Genotype === 'INCONCLUSIVE' && '' ||
        cyp2b6Genotype === 'PENDING' && '' ||
        '',
    }
  };

  const cyp2d6Genotype = data.cyp2d6ResultGenotypeFirst & '/' & data.cyp2d6ResultGenotypeLast;
  const cyp2d6 = {
    genotype: cyp2d6Genotype,
    phenotype:  data.cyp2d6ResultPhenotype === 'Poor' && 'Poor' ||
      data.cyp2d6ResultPhenotype === 'Normal' && 'Intermediate/Normal' ||
      data.cyp2d6ResultPhenotype === 'Intermediate' && 'Intermediate/Normal' ||
      data.cyp2d6ResultPhenotype === 'Ultrarapid' && 'Ultrarapid' ||
      data.cyp2d6ResultPhenotype.toUpperCase() === 'INCONCLUSIVE' && 'Inconclusive' ||
      data.cyp2d6ResultPhenotype.toUpperCase() === 'PENDING' && 'No Result' ||
      '',
    summary: {
      title: data.cyp2d6ResultPhenotype === 'Poor' && 'Very Slow Metabolizer' ||
        data.cyp2d6ResultPhenotype === 'Normal' && 'Normal Metabolizer' ||
        data.cyp2d6ResultPhenotype === 'Intermediate' && 'Normal Metabolizer' ||
        data.cyp2d6ResultPhenotype === 'Ultrarapid' && 'Increased Metabolizer' ||
        data.cyp2d6ResultPhenotype.toUpperCase() === 'INCONCLUSIVE' && 'Inconclusive' ||
        data.cyp2d6ResultPhenotype.toUpperCase() === 'PENDING' && 'No Result' ||
        '',
      text: cyp2b6Genotype === '*1/*1' && 'Metabolized Ketamin at a normal rate.' ||
        cyp2b6Genotype === '*1/*6' && 'Metabolized Ketamin at a slower rate.' ||
        cyp2b6Genotype === '*6/*6' && 'Metabolized Ketamin at a very slow rate.' ||
        cyp2b6Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        cyp2b6Genotype === 'PENDING' && 'PENDING' ||
        '',
    },
    description: {
      subTitle: cyp2b6Genotype === '*1/*1' && 'Normal Metabolizer' ||
        cyp2b6Genotype === '*1/*6' && 'Slow Metabolizer' ||
        cyp2b6Genotype === '*6/*6' && 'Very Slow Metabolizer' ||
        cyp2b6Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        cyp2b6Genotype === 'PENDING' && 'PENDING' ||
        '',
      description: cyp2b6Genotype === '*1/*1' && 'Normal Metabolizer of Ketamin.' ||
        cyp2b6Genotype === '*1/*6' && 'Slow Metabolizer of Ketamin.' ||
        cyp2b6Genotype === '*6/*6' && 'Very Slow Metabolizer of Ketamin.' ||
        cyp2b6Genotype === 'INCONCLUSIVE' && '' ||
        cyp2b6Genotype === 'PENDING' && '' ||
        '',
    }
  };

  const c4aGenotype = mapGenotype(data.c4a);
  const c4a = {
    genotype: c4aGenotype === 'ZERO' && '0 copies' ||
      c4aGenotype === 'ONE' && '1 copy' ||
      c4aGenotype === 'TWO' && '2 copies' ||
      c4aGenotype === 'THREE' && '3 copies' ||
      c4aGenotype === 'FOUR' && '4 copies' ||
      c4aGenotype === 'FIVE' && '5 copies' ||
      c4aGenotype === 'INCONCLUSIVE' && 'Inconclusive' ||
      c4aGenotype === 'PENDING' && 'PENDING' ||
      '',
    phenotype: (c4aGenotype === 'ZERO' || c4aGenotype === 'ONE' || c4aGenotype === 'TWO' || c4aGenotype === 'THREE') && 'Normal' ||
      (c4aGenotype === 'FOUR' || c4aGenotype === 'FIVE') &&  'Increased' ||
      c4aGenotype === 'INCONCLUSIVE' && 'Inconclusive' ||
      c4aGenotype === 'PENDING' && 'PENDING' ||
      '',
    summary: {
      title: (c4aGenotype === 'ZERO' || c4aGenotype === 'ONE' || c4aGenotype === 'TWO' || c4aGenotype === 'THREE') && 'Normal Risk' ||
        (c4aGenotype === 'FOUR' || c4aGenotype === 'FIVE') && 'Increased Risk' ||
        c4aGenotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        c4aGenotype === 'PENDING' && 'PENDING' ||
        '',
      text: c4aGenotype === 'ZERO' && 'At a normal risk for synaptic pruning and mental health effects.' ||
        c4aGenotype === 'ONE' && 'At a normal risk for synaptic pruning and mental health effects.' ||
        c4aGenotype === 'TWO' && 'At a normal risk for synaptic pruning and mental health effects.' ||
        c4aGenotype === 'THREE' && 'At a normal risk for synaptic pruning and mental health effects.' ||
        c4aGenotype === 'FOUR' && 'At an increased risk for synaptic pruning and mental health effects.' ||
        c4aGenotype === 'FIVE' && 'At an increased risk for synaptic pruning and mental health effects.' ||
        c4aGenotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        c4aGenotype === 'PENDING' && 'PENDING' ||
        '',
    },
    description: {
      subTitle: (c4aGenotype === 'ZERO' || c4aGenotype === 'ONE' || c4aGenotype === 'TWO' || c4aGenotype === 'THREE') && 'Normal Risk' ||
        (c4aGenotype === 'FOUR' || c4aGenotype === 'FIVE') && 'Increased Risk' ||
        c4aGenotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        c4aGenotype === 'PENDING' && 'PENDING' ||
        '',
      description: c4aGenotype === 'ZERO' && 'A normal number of C4A gene copies (0).' ||
        c4aGenotype === 'ONE' && 'A normal number of C4A gene copies (1).' ||
        c4aGenotype === 'TWO' && 'A normal number of C4A gene copies (2).' ||
        c4aGenotype === 'THREE' && 'A normal number of C4A gene copies (3).' ||
        c4aGenotype === 'FOUR' && 'An increased number of C4A gene copies (4).' ||
        c4aGenotype === 'FIVE' && 'An increased number of C4A gene copies (5).' ||
        c4aGenotype === 'INCONCLUSIVE' && '' ||
        c4aGenotype === 'PENDING' && '' ||
        '',
      copiesText: c4aGenotype === 'ZERO' && '0 COPIES' ||
        c4aGenotype === 'ONE' && '1 COPY' ||
        c4aGenotype === 'TWO' && '2 COPIES' ||
        c4aGenotype === 'THREE' && '3 COPIES' ||
        c4aGenotype === 'FOUR' && '4 COPIES' ||
        c4aGenotype === 'FIVE' && '5 COPIES' ||
        c4aGenotype === 'INCONCLUSIVE' && '' ||
        c4aGenotype === 'PENDING' && '' ||
        '',
    }
  };

  const nrg1Genotype = mapGenotype(data.nrg1);
  const nrg1 = {
    genotype: nrg1Genotype,
    phenotype:  nrg1Genotype === 'C/C' && 'Normal' ||
      nrg1Genotype === 'C/T' && 'Normal' ||
      nrg1Genotype === 'T/T' && 'Increased' ||
      nrg1Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
      nrg1Genotype === 'PENDING' && 'PENDING' ||
      '',
    summary: {
      title: nrg1Genotype === 'C/C' && 'Normal Risk' ||
        nrg1Genotype === 'C/T' && 'Normal Risk' ||
        nrg1Genotype === 'T/T' && 'Increased Risk' ||
        nrg1Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        nrg1Genotype === 'PENDING' && 'PENDING' ||
        '',
      text: nrg1Genotype === 'C/C' && 'At a normal risk for reduced synaptic plasticity and mental health disorders.' ||
        nrg1Genotype === 'C/T' && 'At a normal risk for reduced synaptic plasticity and mental health disorders.' ||
        nrg1Genotype === 'T/T' && 'At an increased risk for reduced synaptic plasticity and mental health disorders.' ||
        nrg1Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        nrg1Genotype === 'PENDING' && 'PENDING' ||
        '',
    },
    description: {
      subTitle: nrg1Genotype === 'C/C' && 'Normal Risk' ||
        nrg1Genotype === 'C/T' && 'Normal Risk' ||
        nrg1Genotype === 'T/T' && 'Increased Risk' ||
        nrg1Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        nrg1Genotype === 'PENDING' && 'PENDING' ||
        '',
      description: nrg1Genotype === 'C/C' && 'Normal Mental health risk.' ||
        nrg1Genotype === 'C/T' && 'Normal Mental health risk.' ||
        nrg1Genotype === 'T/T' && 'Increased Mental health risk.' ||
        nrg1Genotype === 'INCONCLUSIVE' && '' ||
        nrg1Genotype === 'PENDING' && '' ||
        '',
    }
  };

  const disc1Genotype = mapGenotype(data.disc1);
  const disc1 = {
    genotype: disc1Genotype,
    phenotype:  disc1Genotype === 'A/A' && 'Increased' ||
      disc1Genotype === 'A/T' && 'Normal' ||
      disc1Genotype === 'T/T' && 'Increased' ||
      disc1Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
      disc1Genotype === 'PENDING' && 'PENDING' ||
      '',
    summary: {
      title: disc1Genotype === 'A/A' && 'Normal Risk' ||
        disc1Genotype === 'A/T' && 'Normal Risk' ||
        disc1Genotype === 'T/T' && 'Increased Risk' ||
        disc1Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        disc1Genotype === 'PENDING' && 'PENDING' ||
        '',
      text: disc1Genotype === 'A/A' && 'At a normal risk for mental health disorders such psychosis, bipolar and schizophernia.' ||
        disc1Genotype === 'A/T' && 'At a normal risk for mental health disorders such psychosis, bipolar and schizophernia.' ||
        disc1Genotype === 'T/T' && 'At an increased risk for mental health disorders such psychosis, bipolar and schizophernia.' ||
        disc1Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        disc1Genotype === 'PENDING' && 'PENDING' ||
        '',
    },
    description: {
      subTitle: disc1Genotype === 'A/A' && 'Normal Risk' ||
        disc1Genotype === 'A/T' && 'Normal Risk' ||
        disc1Genotype === 'T/T' && 'Increased Risk' ||
        disc1Genotype === 'INCONCLUSIVE' && 'Inconclusive' ||
        disc1Genotype === 'PENDING' && 'PENDING' ||
        '',
      description: disc1Genotype === 'A/A' && 'At a normal risk for mental health disorders.' ||
        disc1Genotype === 'A/T' && 'At a normal risk for mental health disorders.' ||
        disc1Genotype === 'T/T' && 'Having the DISC1 T/T genotype has been shown to increase the risk for mental health disorders such as psychosis, bipolar and schizophrenia.' ||
        disc1Genotype === 'INCONCLUSIVE' && '' ||
        disc1Genotype === 'PENDING' && '' ||
        '',
    }
  };

  const display = {
    trialName: trial.name,
    patientId: data.patientId,
    kitId: data.kitId,
    reportDate: data.reportDate ? moment(data.reportDate).format("MMM D, YYYY h:mm A") : 'n/a',
    testDate: data.testDate ? moment(data.testDate).format("MMM D, YYYY h:mm A") : 'n/a',
    trialLocation: (data.scannerLocations && data.scannerLocations.trialLocations && data.scannerLocations.trialLocations.name) || '',
    htr2a: htr2a,
    cyp2b6: cyp2b6,
    cyp2d6: cyp2d6,
    c4a: c4a,
    nrg1: nrg1,
    disc1: disc1
  };

  return (<>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
    <title>HaluGen Psychedelics Genetic Test Report</title>
    <meta property="og:title" content="HaluGen Psychedelics Genetic Test Report" />
    <meta name="description" content="Psychedelics Genetic Test Report" />
    <meta property="og:type" content="website" />
    <link rel="icon" type="image/png" sizes="80x80" href="/report-assets/img/favicon.ico.png" />
    <link rel="stylesheet" href="/report-assets/bootstrap/css/bootstrap.min.css" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins&amp;display=swap" />
    <link rel="stylesheet" href="/report-assets/fonts/fontawesome-all.min.css" />
    <link rel="stylesheet" href="/report-assets/fonts/font-awesome.min.css" />
    <link rel="stylesheet" href="/report-assets/fonts/fontawesome5-overrides.min.css" />
    <link rel="stylesheet" href="/report-assets/css/styles-print-report.css" />
    <link rel="stylesheet" href="/report-assets/css/styles.css" />
    <div>
      <div id="print-header" className="print-container print-header">
          <div className="row justify-content-between mt-4">
              <div className="col-7 mt-2">
                  <div><img src="/report-assets/img/halugen-logo.png" height="50px" />
                      <div className="mt-3">
                          <h3 id="genetic-test" style={{color: 'var(--survey-secondary)'}}>Psychedelics Genetic Test</h3>
                      </div>
                  </div>
              </div>
              <div className="col-5">
                  <div className="row mt-2">
                      <div className="col-5">
                          <h6><strong>Subject ID:</strong></h6>
                          <h6><strong>Swab ID:</strong></h6>
                          <h6><strong>Report Date:</strong></h6>
                          <h6><strong>Location:</strong></h6>
                      </div>
                      <div className="col-7">
                          <h6>{display.patientId}</h6>
                          <h6>{display.kitId}</h6>
                          <h6>{display.testDate}</h6>
                          <h6>{display.trialLocation}</h6>
                      </div>
                  </div>
              </div>
          </div>
          <hr style={{borderWidth: '1px', borderColor: 'var(--survey-secondary)'}} />
      </div>
      <div id="print-footer" className="print-container print-footer">
          <hr style={{borderWidth: '1px', borderColor: 'var(--survey-secondary)'}} />
          <div className="row">
              <div className="col-4 text-left">
                  <div className="row">
                      <div className="col-auto align-self-center" style={{padding: '0px 0px 10px 10px'}}><img src="/report-assets/img/favicon.ico.png" style={{height: '35px'}} /></div>
                      <div className="col">
                          <h6 className="text-uppercase text-primary">HaluGen Life Sciences Inc.</h6>
                          <h6 className="text-secondary">© 2021. All rights reserved.</h6>
                      </div>
                  </div>
              </div>
              <div className="col-4 text-center">
                  <h6 className="text-uppercase text-center text-primary">Psychedelics Genetic Test</h6>
                  <h6 className="text-center text-secondary">Report v1.2W</h6>
              </div>
              <div className="col-4 text-right">
                  <h6 className="text-uppercase text-primary">Subject ID:&nbsp;{display.patientId}</h6>
                  <h6 className="text-secondary">Location:&nbsp;{display.trialLocation}</h6>
              </div>
          </div>
      </div>
      <div className="table-responsive">
          <table className="table print-report-table">
              <thead>
                  <tr>
                      <th style={{borderStyle: 'none', padding: '0px'}}>
                          <div className="page-header-space"></div>
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td style={{borderStyle: 'none', padding: '0px'}}>
                          <div id="print-body">
                              <div id="report-summary-page" className="print-container page">
                                  <section className="mt-0 mb-5">
                                      <h3 className="text-uppercase mb-3">Report Summary</h3>
                                      <p>The HaluGen Psychedelics Genetic Test is a pharmacogenomic test to help identify how an individual's DNA profile can affect their response to hallucinogenic drugs. The information contained in this report is based on research related to specific genetic polymorphisms and their influence on pharmacodynamics, pharmacokinetics and mental health risk factors for psychedelics use.</p>
                                  </section>
                                  <section className="mt-1 mb-4">
                                      <h4 className="text-uppercase mb-3" style={{textAlign: 'center', background: 'var(--genetics-primary)', color: 'var(--white)', padding: '12px', borderRadius: '5px'}}>PharmacoDYNAMIC Genes</h4>
                                      <p className="mb-2">Genes that influence pharmacodynamics can alter gene receptors resulting in different responses to drugs such as sensitivity and adverse side effects. Your report includes a genetic marker for serotonin receptor density which can influence sensitivity to classical psychedelics and SSRIs.</p>
                                      <div className="table-responsive">
                                          <table className="table gene-table">
                                              <thead>
                                                  <tr>
                                                      <th style={{width: '10%'}}>Gene</th>
                                                      <th style={{width: '10%'}}>Genotype</th>
                                                      <th style={{width: '20%'}}>Phenotype</th>
                                                      <th style={{width: '60%'}}>Description</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td>HTR2A</td>
                                                      <td>{display.htr2a.genotype}</td>
                                                      <td>{display.htr2a.summary.title}</td>
                                                      <td>The HTR2A gene influences serotonin receptor density.</td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </section>
                                  <section className="mt-1 mb-4">
                                      <h4 className="text-uppercase mb-3" style={{textAlign: 'center', background: 'var(--primary)', color: 'var(--white)', padding: '12px', borderRadius: '5px'}}>Pharmacokinetic Genes</h4>
                                      <p className="mb-2">Genes that influence pharmacokenetics can alter drug metabolism resulting in variability in drug blood concentration levels and therapeutic response. Your report includes metabolism genes for ketamine, LSD, and MDMA.</p>
                                      <div className="table-responsive">
                                          <table className="table gene-table">
                                              <thead>
                                                  <tr>
                                                      <th style={{width: '10%'}}>Gene</th>
                                                      <th style={{width: '10%'}}>Genotype</th>
                                                      <th style={{width: '20%'}}>Phenotype</th>
                                                      <th style={{width: '60%'}}>Description</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td>CYP2B6</td>
                                                      <td>{display.cyp2b6.genotype}</td>
                                                      <td>{display.cyp2b6.summary.title}</td>
                                                      <td>The CYP2B6 gene is responsible for ketamine metabolism.</td>
                                                  </tr>
                                                  <tr>
                                                      <td>CYP2D6</td>
                                                      <td>{display.cyp2d6.phenotype}</td>
                                                      <td>{display.cyp2d6.summary.title}</td>
                                                      <td>The CYP2D6 gene is responsible for LSD and MDMA metabolism.</td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </section>
                                  <section className="mt-1 mb-4">
                                      <h4 className="text-uppercase mb-3" style={{textAlign: 'center', background: 'var(--survey-secondary)', color: 'var(--white)', padding: '12px', borderRadius: '5px'}}>MENTAL HEALTH&nbsp;RISK Genes</h4>
                                      <p className="mb-2">Genes can influence short- and long-term risk factors with psychedelics drug use. Your report includes genetic risk markers for mental health effects.</p>
                                      <div className="table-responsive">
                                          <table className="table gene-table">
                                              <thead>
                                                  <tr>
                                                      <th style={{width: '10%'}}>Gene</th>
                                                      <th style={{width: '10%'}}>Genotype</th>
                                                      <th style={{width: '20%'}}>Phenotype</th>
                                                      <th style={{width: '60%'}}>Description</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td>C4A</td>
                                                      <td>{display.c4a.genotype}</td>
                                                      <td>{display.c4a.summary.title}</td>
                                                      <td>The C4A gene is a mental health risk factor.</td>
                                                  </tr>
                                                  <tr>
                                                      <td>NRG1</td>
                                                      <td>{display.nrg1.genotype}</td>
                                                      <td>{display.nrg1.summary.title}</td>
                                                      <td>The NRG1 gene is a mental health risk factor.</td>
                                                  </tr>
                                                  <tr>
                                                      <td>DISC1</td>
                                                      <td>{display.disc1.genotype}</td>
                                                      <td>{display.disc1.summary.title}</td>
                                                      <td>The DISC1 gene is a mental health risk factor.</td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </section>
                              </div>
                              <div id="pharmacodynamic-page" className="print-container page page-header-space">
                                  <section className="mb-5">
                                      <h4 className="text-uppercase mb-3" style={{textAlign: 'center', background: 'var(--genetics-primary)', color: 'var(--white)', padding: '12px', borderRadius: '5px'}}>HTR2A Gene · Serotonin Sensitivity</h4>
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>Description</h4>
                                              <p className="mb-0">The way your body responds to classical psychedelics such as psilocybin, LSD and DMT, is influenced by the HTR2A gene. This gene influences the baseline expression of the 5-HTR2A serotonin receptor, which is activated by both serotonin and psychedelic drugs. This binding activation releases neurotransmitters in the brain which can induce potential hallucinations and other mental effects.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Genotype Result</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>HTR2A {display.htr2a.genotype}</h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p className="mb-0">Approximately 20% of people carry an HTR2A genetic variant which can increase serotonin receptor density, potentially making them more susceptible to stronger hallucinogenic effects. These individuals should be more cautious when considering classical psychedelic therapies.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Psychedelics Sensitivity</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>{display.htr2a.phenotype}&nbsp;{
                                                display.htr2a.phenotype === 'Increased' ? <i class="fa fa-arrow-circle-o-up" /> : ''
                                              }
                                              </h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-4">
                                              <h4 className="mb-3">How do I compare?</h4>
                                              <div className="table-responsive text-left">
                                                  <table className="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>HTR2A Genotype</th>
                                                              <th>HTR2A Serotonin Receptors</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>C/C</td>
                                                              <td>Reduced</td>
                                                          </tr>
                                                          <tr>
                                                              <td>C/T</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>T/T</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div className="col-8">
                                              <div className="row">
                                                  <div className="col-4">
                                                      <div>
                                                          <div className="chart-area">
                                                            <GeneDoughnut title="Caucasians" data={[
                                                              {value: 34, text: 'Reduced', colour: '#395bef'},
                                                              {value: 47, text: 'Normal', colour: '#5a3397'},
                                                              {value: 18, text: 'Increased', colour: '#fc3b3b'}
                                                            ]} />
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-4">
                                                      <div>
                                                        <GeneDoughnut title="East Asians" data={[
                                                          {value: 23, text: 'Reduced', colour: '#395bef'},
                                                          {value: 54, text: 'Normal', colour: '#5a3397'},
                                                          {value: 23, text: 'Increased', colour: '#fc3b3b'}
                                                        ]} />
                                                      </div>
                                                  </div>
                                                  <div className="col-4">
                                                      <div>
                                                        <GeneDoughnut title="Indians" data={[
                                                          {value: 22, text: 'Reduced', colour: '#395bef'},
                                                          {value: 54, text: 'Normal', colour: '#5a3397'},
                                                          {value: 24, text: 'Increased', colour: '#fc3b3b'}
                                                        ]} />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>How do I respond to SSRIs?</h4>
                                              <p className="mb-0">Selective Serotonin Reuptake Inhibitors (SSRIs) are a common treatment for depression and anxiety. They are thought to increase serotonin levels in the brain by blocking the reabsorption ("reuptake") of serotonin. Studies have shown that individuals with reduced serotonin receptor density (HTR2A C/C genotype) are more prone to adverse drug reactions (ADRs) when taking SSRIs.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">SSRI&nbsp;Sensitivity</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>{display.htr2a.phenotype}</h4>
                                          </div>
                                      </div>
                                      <hr />
                                  </section>
                              </div>
                              <div id="pharmacokinetic-page-1" className="print-container page page-header-space-large">
                                  <section className="mb-5">
                                      <h4 className="text-uppercase mb-3" style={{textAlign: 'center', background: 'var(--purple)', color: 'var(--white)', padding: '12px', borderRadius: '5px'}}>CYP2B6 Gene · Ketamine Metabolism</h4>
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>Description</h4>
                                              <p className="mb-0">The way your body metabolizes ketamine, a fast-acting anesthetic with hallucinogenic properties, is influenced by the CYP2B6 gene. This gene encodes a liver enzyme that helps metabolize ketamine in your bloodstream. 10–20% of people carry the CYP2B6*6 genetic variant which causes them to metabolize ketamine up to 2x to 3x slower than normal.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Genotype Result</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>CYP2B6 {display.cyp2b6.genotype}</h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p className="mb-0">‘Slow metabolizers’ should be more cautious when being dosed with ketamine, as they will likely experience an increased duration and intensity of effect, especially when taking ketamine in intravenous or subcutaneous form.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Ketamine Metabolism</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>{display.cyp2b6.phenotype}&nbsp;{
                                                display.cyp2b6.phenotype === 'Very Slow' || display.cyp2b6.phenotype === 'Slow' ? <i class="fa fa-arrow-circle-o-down" /> : ''
                                              }
                                              </h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-4">
                                              <h4 className="mb-3">How do I compare?</h4>
                                              <div className="table-responsive text-left">
                                                  <table className="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>CYP2B6 Genotype</th>
                                                              <th>CYP2B6 Metabolizer Status</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>*1/*1</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>*1/*6</td>
                                                              <td>Slow</td>
                                                          </tr>
                                                          <tr>
                                                              <td>*6/*6</td>
                                                              <td>Very Slow</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div className="col-8">
                                              <div className="row">
                                                  <div className="col-4">
                                                    <GeneDoughnut title="Caucasians" data={[
                                                      {value: 48, text: 'Normal', colour: '#395bef'},
                                                      {value: 43, text: 'Slow', colour: 'rgba(252,57,57,0.7)'},
                                                      {value: 9, text: 'Very Slow', colour: '#fc3b3b'}
                                                    ]} />
                                                  </div>
                                                  <div className="col-4">
                                                    <GeneDoughnut title="Africans" data={[
                                                      {value: 32, text: 'Normal', colour: '#395bef'},
                                                      {value: 46, text: 'Slow', colour: 'rgba(252,57,57,0.7)'},
                                                      {value: 22, text: 'Very Slow', colour: '#fc3b3b'}
                                                    ]} />
                                                  </div>
                                                  <div className="col-4">
                                                    <GeneDoughnut title="East Asians" data={[
                                                      {value: 46, text: 'Normal', colour: '#395bef'},
                                                      {value: 41, text: 'Slow', colour: 'rgba(252,57,57,0.7)'},
                                                      {value: 13, text: 'Very Slow', colour: '#fc3b3b'}
                                                    ]} />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr />
                                  </section>
                              </div>
                              <div id="pharmacokinetic-page-2" className="print-container page page-header-space-vlarge">
                                  <section>
                                      <h4 className="text-uppercase mb-3" style={{textAlign: 'center', background: 'var(--purple)', color: 'var(--white)', padding: '12px', borderRadius: '5px'}}>CYP2D6 Gene · LSD &amp; MDMA Metabolism</h4>
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>Description</h4>
                                              <p className="mb-0">The way your body metabolizes LSD and MDMA, is influenced by the CYP2D6 gene. This gene encodes a liver enzyme that helps metabolize these drugs in your bloodstream. 5–10% of people carry the CYP2D6 genetic variant which causes them to metabolize LSD and MDMA up to 2x slower than normal.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Phenotype Result</h4>
                                              <h4 className="text-center" style={{color: display.cyp2d6.summary.title === 'No Result' ? 'rgb(149, 123, 187)' : 'var(--genetics-primary)'}}>{display.cyp2d6.phenotype}</h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p className="mb-0">Individuals with the ‘poor metabolizer’ phenotype should be more cautious when being dosed with LSD or MDMA, as they will likely experience an increased duration and intensity of effect when taken in oral form.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Metabolism</h4>
                                              <h4 className="text-center" style={{color: display.cyp2d6.summary.title === 'No Result' ? 'rgb(149, 123, 187)' : 'var(--genetics-primary)'}}>{display.cyp2d6.summary.title}&nbsp;{
                                                display.cyp2d6.phenotype === 'Poor' ? <i class="fa fa-arrow-circle-o-down" /> :
                                                display.cyp2d6.phenotype === 'Ultrarapid' ? <i class="fa fa-arrow-circle-o-up" /> : ''
                                              }
                                              </h4>
                                              No Result
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-4">
                                              <h4 className="mb-3">How do I compare?</h4>
                                              <div className="table-responsive text-left">
                                                  <table className="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>CYP2D6&nbsp; Phenotype</th>
                                                              <th>CYP2D6 Metabolizer Status</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>Ultrarapid</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                          <tr>
                                                              <td>Intermediate /Normal</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>Poor</td>
                                                              <td>Very Slow</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div className="col-8">
                                              <div className="row">
                                                  <div className="col-4">
                                                    <GeneDoughnut title="Caucasians" data={[
                                                      {value: 2, text: 'Increased', colour: '#395bef'},
                                                      {value: 91, text: 'Normal', colour: '#5a3397'},
                                                      {value: 7, text: 'Very Slow', colour: '#fc3b3b'}
                                                    ]} />
                                                  </div>
                                                  <div className="col-4">
                                                    <GeneDoughnut title="Increased" data={[
                                                      {value: 3, text: 'Increased', colour: '#395bef'},
                                                      {value: 95, text: 'Normal', colour: '#5a3397'},
                                                      {value: 2, text: 'Very Slow', colour: '#fc3b3b'}
                                                    ]} />
                                                  </div>
                                                  <div className="col-4">
                                                    <GeneDoughnut title="East Asians" data={[
                                                      {value: 2, text: 'Increased', colour: '#395bef'},
                                                      {value: 97, text: 'Normal', colour: '#5a3397'},
                                                      {value: 1, text: 'Very Slow', colour: '#fc3b3b'}
                                                    ]} />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr />
                                  </section>
                              </div>
                              <div id="mental-health-risk-page-1" className="print-container page page-header-space-vlarge">
                                  <section className="mb-5">
                                      <h4 className="text-uppercase mb-3" style={{textAlign: 'center', background: 'var(--survey-secondary)', color: 'var(--white)', padding: '12px', borderRadius: '5px'}}>C4A GENE Copy Number Variation · Mental Health Risk</h4>
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>Description</h4>
                                              <p className="mb-0">The C4A gene expresses a protein that is responsible for synaptic pruning in the brain. This is a normal process by which extra synapses, starting in childhood and adolescence, are eliminated to make way for more complex synapses in adulthood. An individual can have different C4A gene copy number variations (CNV), typically from 0 to 5 copies.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">CNV Result</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>{display.c4a.genotype}</h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p className="mb-0">Having an increased number of C4A gene copies (4 or 5) causes more C4A protein expression that is responsible for synaptic pruning. It is believed that increased disorderly synaptic pruning can be a contributing factor to a higher risk of mental health disorders such as psychosis, bipolar and schizophrenia.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Risk</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>{display.c4a.phenotype}&nbsp;{
                                                display.c4a.phenotype === 'Increased' ? <i class="fa fa-arrow-circle-o-up" /> : ''
                                              }
                                              </h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-4">
                                              <h4 className="mb-3">How do I compare?</h4>
                                              <div className="table-responsive text-left">
                                                  <table className="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>C4A CNV</th>
                                                              <th>Mental Health Risk</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>0-1</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>2-3</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>4-5</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div className="col-6">
                                              <div className="row">
                                                  <div className="col-6">
                                                      <div>
                                                        <GeneDoughnut title="Caucasians" data={[
                                                          {value: 22, text: '0-1 Copies', colour: '#395bef'},
                                                          {value: 75, text: '2-3 Copies', colour: '#5a3397'},
                                                          {value: 3, text: '4-5 Copies', colour: '#fc3b3b'}
                                                        ]} />
                                                      </div>
                                                  </div>
                                                  <div className="col-6">
                                                      <div>
                                                        <GeneDoughnut title="East Asians" data={[
                                                          {value: 7, text: '0-1 Copies', colour: '#395bef'},
                                                          {value: 89, text: '2-3 Copies', colour: '#5a3397'},
                                                          {value: 4, text: '4-5 Copies', colour: '#fc3b3b'}
                                                        ]} />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr />
                                  </section>
                              </div>
                              <div id="mental-health-risk-page-2" className="print-container page page-header-space-vlarge">
                                  <section>
                                      <h4 className="text-uppercase mb-3" style={{textAlign: 'center', background: 'var(--survey-secondary)', color: 'var(--white)', padding: '12px', borderRadius: '5px'}}>NRG1 Gene · Mental Health Risk</h4>
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>Description</h4>
                                              <p className="mb-0">The neuregulin 1 (NRG1) gene expresses NRG1 proteins that stimulates the EGFR receptors which promotes neuron cell development as well as myelin production. NRG1 plays an important role in synaptic plasticity of the brain, which influences short-term learning and long-term memory.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Genotype Result</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>NRG1 {display.nrg1.genotype}</h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p className="mb-0">Having the NRG1 T/T genotype can cause an over-expression of NRG1 proteins leading to reduced synaptic plasticity and disruption in neuron connections in the brain. The T/T genotype has been linked as a risk factor for mental health disorders such as psychosis, bipolar and schizophrenia.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Risk</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>{display.nrg1.phenotype}&nbsp;{
                                                display.nrg1.phenotype === 'Increased' ? <i class="fa fa-arrow-circle-o-up" /> : ''
                                              }
                                              </h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-4">
                                              <h4 className="mb-3">How do I compare?</h4>
                                              <div className="table-responsive text-left">
                                                  <table className="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>NRG1 Genotype</th>
                                                              <th>Mental Health Risk</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>C/C</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>C/T</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>T/T</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div className="col-6">
                                              <div className="row">
                                                  <div className="col-6">
                                                      <div>
                                                        <GeneDoughnut title="Caucasians" data={[
                                                          {value: 37, text: 'C/C Normal', colour: '#395bef'},
                                                          {value: 48, text: 'C/T Normal', colour: '#5a3397'},
                                                          {value: 15, text: 'T/T Increased', colour: '#fc3b3b'}
                                                        ]} />
                                                      </div>
                                                  </div>
                                                  <div className="col-6">
                                                      <div>
                                                        <GeneDoughnut title="Africans" data={[
                                                          {value: 40, text: 'C/C Normal', colour: '#395bef'},
                                                          {value: 46, text: 'C/T Normal', colour: '#5a3397'},
                                                          {value: 14, text: 'T/T Increased', colour: '#fc3b3b'}
                                                        ]} />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr />
                                  </section>
                              </div>
                              <div id="mental-health-risk-page-3" className="print-container page-header-space-huge">
                                  <section>
                                      <h4 className="text-uppercase mb-3" style={{textAlign: 'center', background: 'var(--survey-secondary)', color: 'var(--white)', padding: '12px', borderRadius: '5px'}}>DISC1 Gene · Mental Health Risk</h4>
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>Description</h4>
                                              <p className="mb-0">The disrupted in schizophrenia (DISC1) gene expresses DISC1 proteins that are involved in important processes that regulate nervous system and brain development. It is a well-established genetic risk factor amongst different populations and ethnicities for psychiatric disorders.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Genotype Result</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>DISC1 {display.disc1.genotype}</h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p className="mb-0">Having the DISC1 T/T genotype has been shown to increase the risk for mental health disorders such as psychosis, bipolar and schizophrenia.</p>
                                          </div>
                                          <div className="col-4">
                                              <h4 className="text-center">Risk</h4>
                                              <h4 className="text-center" style={{color: 'var(--genetics-primary)'}}>{display.disc1.phenotype}&nbsp;{
                                                display.disc1.phenotype === 'Increased' ? <i class="fa fa-arrow-circle-o-up" /> : ''
                                              }
                                              </h4>
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-4">
                                              <h4 className="mb-3">How do I compare?</h4>
                                              <div className="table-responsive text-left">
                                                  <table className="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>DISC1 Genotype</th>
                                                              <th>Mental Health Risk</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>A/T</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>A/T</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>T/T</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div className="col-8">
                                              <div className="row">
                                                  <div className="col-4">
                                                      <div>
                                                        <GeneDoughnut title="Caucasians" data={[
                                                          {value: 52, text: 'A/A Normal', colour: '#395bef'},
                                                          {value: 40, text: 'A/T Normal', colour: '#5a3397'},
                                                          {value: 8, text: 'T/T Increased', colour: '#fc3b3b'}
                                                        ]} />
                                                      </div>
                                                  </div>
                                                  <div className="col-4">
                                                      <div>
                                                        <GeneDoughnut title="Africans" data={[
                                                          {value: 45, text: 'A/A Normal', colour: '#395bef'},
                                                          {value: 44, text: 'A/T Normal', colour: '#5a3397'},
                                                          {value: 11, text: 'T/T Increased', colour: '#fc3b3b'}
                                                        ]} />
                                                      </div>
                                                  </div>
                                                  <div className="col-4">
                                                      <div>
                                                        <GeneDoughnut title="East Asians" data={[
                                                          {value: 69, text: 'A/A Normal', colour: '#395bef'},
                                                          {value: 28, text: 'A/T Normal', colour: '#5a3397'},
                                                          {value: 3, text: 'T/T Increased', colour: '#fc3b3b'}
                                                        ]} />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr />
                                  </section>
                                  <section className="mb-0" style={{paddingTop: '5px'}}>
                                      <h5 className="text-uppercase">Disclaimer</h5>
                                      <p>This report is for informational purposes only and does not and is not intended to convey medical advice and does not constitute the practice of medicine. You should not rely on this information as a substitute for, nor does it replace, professional medical advice, diagnosis, or treatment. HaluGen is not responsible for any actions or inaction on a user’s part based on the information that is presented in this report.</p>
                                      <p>The use of any psychedelic drug can come with potential health risks and legal consequences. All individuals should do their own research as well as consult with a healthcare professional prior to considering the use of any psychedelic drug to ensure the appropriateness and legality of any psychedelic therapy within your jurisdiction.<br /></p>
                                  </section>
                              </div>
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>

      <script src="/report-assets/js/jquery.min.js"></script>
      <script src="/report-assets/bootstrap/js/bootstrap.min.js"></script>
      <script src="/report-assets/js/chart.min.js"></script>
      <script src="/report-assets/js/bs-init.js"></script>
      <script src="/report-assets/js/chart.js"></script>
      <script src="/report-assets/js/halugen.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.15/jquery.mask.min.js"></script>
    </div>
  </>)
}

function GeneDoughnut(props) {
  const {title, data, colour} = props;
  return(
    <div style={{width: 200}}>
      <div className='d-flex justify-content-center mb-2'><b>{title}</b></div>
      <Doughnut
        options={{ legend: { display: false}}}
        data={{
          labels: data.map(d => d.text),
          datasets: [{
            data: data.map(d => d.value),
            backgroundColor: data.map(d => d.colour),
            hoverBackgroundColor: data.map(d => d.colour)
          }],
          text: '23%'
        }}/>
      <div className="text-center small mt-2">
        { data.map((d, i) =>
          <div key={i}>
            <span>
              <i className="fa fa-circle" style={{color: d.colour}}/>
              &nbsp;{d.text} ({d.value}%)
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
