export const actionModals = (modals) => ({
  type: 'MODALS',
  payload: modals
});

export const actionTabs = (page, tabs, tab) => ({
  type: 'TABS',
  page: page,
  tabs: tabs,
  tab: tab,
});

export const actionTestTabs = (page, mainTab, testTab) => ({
  type: 'TEST_TABS',
  page: page,
  mainTab: mainTab,
  testTab: testTab,
});

export const actionLoadTabs = (tabs) => ({
  type: 'LOAD_TABS',
  tabs: tabs,
});

export const actionTabLocations = (page, tab, locations) => ({
  type: 'TAB_LOCATIONS',
  page: page,
  tab: tab,
  locations: locations
});

export const actionClearAllTabs = () => ({
  type: 'CLEAR_ALL_TABS',
});

export const actionResetLastCreatedTrial = () => ({
    type: 'RESET_LAST_CREATED_TRIAL',
    payload: false
});

export const actionResetLastCreatedScanner = () => ({
  type: 'RESET_LAST_CREATED_SCANNER',
  payload: false
});
