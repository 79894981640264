import React, { useEffect, useState, createContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import { actionFetchTrials, actionFetchPatientCounts, actionFetchDashboardCounts } from './actions/trials';
import { actionLoadTabs } from './actions/flags';
import {authProtectedRoutes, publicRoutes} from "./routes/";
import AppRoute from "./routes/route";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";

export const RefreshDataContext = createContext([{}, () => {}]);

function App() {
  const dispatch = useDispatch();
  const sessionTabs = JSON.parse(window.sessionStorage.getItem("tabs"));
  const [refreshData, setRefreshData] = useState(0);
  const [reportCanvas, setReportCanvas] = useState("");
  const [triggerNewTrialModal, setTriggerNewTrialModal] = useState(false);
  const userAccessToken = useSelector(state => state.UserAccessToken);
  let token;
  if (userAccessToken && typeof userAccessToken === 'object') token = userAccessToken.token;
  else token = userAccessToken;
  useEffect(() => { if(token) {dispatch(actionFetchTrials())} else {return} }, [dispatch, token, refreshData]);
  useEffect(() => { if(token) {dispatch(actionFetchDashboardCounts())} else {return} }, [dispatch, token, refreshData]);
  useEffect(() => { if(token) {dispatch(actionFetchPatientCounts())} else {return} }, [dispatch, token, refreshData]);
  useEffect(() => { if(token && sessionTabs) {dispatch(actionLoadTabs(sessionTabs))} else {return} }, []);
  return (
    <RefreshDataContext.Provider value={[
      refreshData,
      () => setRefreshData(refreshData+1),
      reportCanvas,
      (val) => setReportCanvas(val),
      triggerNewTrialModal,
      (val) => setTriggerNewTrialModal(val),
    ]}>
      <BrowserRouter>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={HorizontalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
        </Switch>
      </BrowserRouter>
    </RefreshDataContext.Provider>
  );
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, null)(App);
