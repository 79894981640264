import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Logout from './Logout'
import {ActionCreators} from '../../actions'

const mapStateToProps = state => ({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const LogoutContainer = connect(mapStateToProps, mapDispatchToProps)(Logout);

export default LogoutContainer
