import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../../actions'
import ProfileMenu from "./ProfileMenu";

const mapStateToProps = state => ({
  user: state.LoginStatus.userProfile,
  AdminStore: state.AdminStore,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const ProfileMenuContainer = connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);

export default ProfileMenuContainer
