export const getLabelFromValue = (obj, value) => {
  return (obj.find((o) => o.value === value) || {}).label;
}

export const trialStatusOptions = [
  {label: 'Active', value: 'ACTIVE'},
  {label: 'Completed', value: 'COMPLETED'},
  {label: 'Withdrawn', value: 'WITHDRAWN'},
];

export const scannerStatusOptions = [
  {label: 'Pending', value: 'PENDING'},
  {label: 'Enabled', value: 'ACTIVE'},
  {label: 'Disabled', value: 'INACTIVE'},
];
