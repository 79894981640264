import {isNullOrUndefined, isNullOrUndefinedOrEmpty} from "./helper";

const defaultText = '<span class="text-secondary">No Result</span>';
const inconclusive = '<span class="text-secondary">INCONCLUSIVE</span>';

export function renderCBDG(props) {
  if (isNullOrUndefinedOrEmpty(props)) {
    return {short: '', descriptive: '', phenotype: '', genotype: '', genotypeFull: ''};
  }
  let c2 = props.cyp2c192 || '';
  let c3 = props.cyp2c193 || '';
  let c17 = props.cyp2c1917 || '';
  if (c2.indexOf('PENDING') >= 0 || c3.indexOf('PENDING') >= 0 || c17.indexOf('PENDING') >= 0) {
    return {short: 'PENDING', descriptive: 'PENDING', phenotype: 'PENDING', genotype: 'PENDING', genotypeFull: 'PENDING'};
  }

  switch (c2){
    case 'CYP2C19_2_1_1':
      c2 = '1/1';
      break;
    case 'CYP2C19_2_2_2':
      c2 = '2/2';
      break;
    case 'CYP2C19_2_1_2':
      c2 = '1/2';
      break;
  }
  switch (c3){
    case 'CYP2C19_3_1_1':
      c3 = '1/1';
      break;
    case 'CYP2C19_3_1_3':
      c3 = '1/3';
      break;
    case 'CYP2C19_3_3_3':
      c3 = '3/3';
      break;
  }
  switch (c17){
    case 'CYP2C19_17_1_1':
      c17 = '1/1';
      break;
    case 'CYP2C19_17_1_17':
      c17 = '1/17';
      break;
    case 'CYP2C19_17_17_17':
      c17 = '17/17';
      break;
  }

  if (c2 === '1/1' && c3 === '1/1' && c17 === '1/1') {
    return {
      genotype: '*1/*1',
      genotypeFull: 'CYP2C19 *1/*1',
      phenotype:'Extensive (Normal) Metabolizer',
      short: 'Normal',
      descriptive: 'Normal Metabolism'
    }
  } else if (c2 === '1/1' && c3 === '1/1' && c17 === '1/17') {
    return {
      genotype: '*1/*17',
      genotypeFull: 'CYP2C19 *1/*17',
      phenotype:'Rapid Metabolizer',
      short: 'Increased',
      descriptive: 'Increased Metabolism'
    }
  } else if (c2 === '1/1' && c3 === '1/1' && c17 === '17/17') {
    return {
      genotype: '*17/*17',
      genotypeFull: 'CYP2C19 *17/*17',
      phenotype:'Ultrarapid Metabolizer',
      short: 'Increased',
      descriptive: 'Increased Metabolism'
    }
  } else if (c2 === '1/2' && c3 === '1/1' && c17 === '1/1') {
    return {
      genotype: '*1/*2',
      genotypeFull: 'CYP2C19 *1/*2',
      phenotype:'Intermediate Metabolizer',
      short: 'Slow',
      descriptive: 'Slow Metabolism'
    }
  } else if (c2 === '1/1' && c3 === '1/3' && c17 === '1/1') {
    return {
      genotype: '*1/*3',
      genotypeFull: 'CYP2C19 *1/*3',
      phenotype:'Intermediate Metabolizer',
      short: 'Slow',
      descriptive: 'Slow Metabolism'
    }
  } else if (c2 === '1/2' && c3 === '1/1' && c17 === '1/17') {
    return {
      genotype: '*2/*17',
      genotypeFull: 'CYP2C19 *2/*17',
      phenotype:'Intermediate Metabolizer',
      short: 'Slow',
      descriptive: 'Slow Metabolism'
    }
  } else if (c2 === '1/1' && c3 === '1/3' && c17 === '1/17') {
    return {
      genotype: '*3/*17',
      genotypeFull: 'CYP2C19 *3/*17',
      phenotype:'Intermediate Metabolizer',
      short: 'Slow',
      descriptive: 'Slow Metabolism'
    }
  } else if (c2 === '1/1' && c3 === '3/3' && c17 === '1/1') {
    return {
      genotype: '*3/*3',
      genotypeFull: 'CYP2C19 *3/*3',
      phenotype:'Poor Metabolizer',
      short: 'Very Slow',
      descriptive: 'Very Slow Metabolism'
    }
  } else if (c2 === '1/2' && c3 === '1/3' && c17 === '1/1') {
    return {
      genotype: '*2/*3',
      genotypeFull: 'CYP2C19 *2/*3',
      phenotype:'Poor Metabolizer',
      short: 'Very Slow',
      descriptive: 'Very Slow Metabolism'
    }
  } else if (c2 === '2/2' && c3 === '1/1' && c17 === '1/1') {
    return {
      genotype: '*2/*2',
      genotypeFull: 'CYP2C19 *2/*2',
      phenotype:'Poor Metabolizer',
      short: 'Very Slow',
      descriptive: 'Very Slow Metabolism'
    }
  } else {
    return {short: defaultText, descriptive: defaultText, phenotype: defaultText, genotype: inconclusive, genotypeFull: inconclusive};
  }
}

export function getMetabolismString(data) {
  if(!data) return {short: '', descriptive: '', phenotype: '', genotype: '', genotypeFull: ''};
  const value = data.cyp2c9;
  switch (value) {
    case 'CYP2C9_1_1':
    case '1/1':
      return {short: 'Normal', descriptive: 'Normal Metabolism', phenotype: 'Extensive (Normal) Metabolizer', genotype: '*1/*1', genotypeFull: 'CYP2C9 *1/*1'};
    case 'CYP2C9_1_3':
    case '1/3':
      return {short: 'Slow', descriptive: 'Slow Metabolism', phenotype: 'Intermediate Metabolizer', genotype: '*1/*3', genotypeFull: 'CYP2C9 *1/*3'};
    case 'CYP2C9_3_3':
    case '3/3':
      return {short: 'Very Slow', descriptive: 'Very Slow Metabolism', phenotype: 'Poor Metabolizer', genotype: '*3/*3', genotypeFull: 'CYP2C9 *3/*3'};
    case 'CYP2C9_INCONCLUSIVE':
    case 'Inconclusive':
    case 'inconclusive':
      return {short: 'Inconclusive', long: 'Inconclusive', phenotype: 'Inconclusive', genotype: 'Inconclusive', genotypeFull: 'Inconclusive'};
    default:
      return {short: defaultText, descriptive: defaultText, phenotype: defaultText, genotype: defaultText, genotypeFull: defaultText};
  }
}

export function getAkt1String(data) {
  if(!data) return {short: '', descriptive: '', genotype: '', genotypeFull: ''};
  const value = data.akt1;
  switch (value.replace('AKT1_', ''). replace('_','/')) {
    case 'AKT1_T_T':
    case 'T/T':
      return {
        short: 'Normal',
        descriptive: 'Normal Risk'
        , genotype: 'T/T'
        , genotypeFull: 'AKT1 T/T'
      };
    case 'AKT1_C_T':
    case 'C/T':
      return {
        short: 'Intermediate',
        descriptive: 'Intermediate Risk'
        , genotype: 'C/T'
        , genotypeFull: 'AKT1 C/T'
      };
    case 'AKT1_C_C':
    case 'C/C':
      return {
        short: 'Higher',
        descriptive: 'Higher Risk'
        , genotype: 'C/C'
        , genotypeFull: 'AKT1 C/C'
      };
    case 'INCONCLUSIVE':
    case 'inconclusive':
      return {
        short: 'Inconclusive',
        descriptive: 'Inconclusive'
        , genotype: 'Inconclusive'
        , genotypeFull: 'Inconclusive'
      };
    default:
      return {short: defaultText, descriptive: defaultText, genotype: defaultText, genotypeFull: defaultText};
  }
}

export function getMemoryString(data) {
  if(!data) return { short: '', descriptive: '', genotype: '', genotypeFull: '' };
  const value = data.comt;
  switch (value) {
    case 'COMT_MET_MET':
    case 'Met/Met':
      return { short: 'Normal', descriptive: 'Normal Risk', genotype: 'Met/Met', genotypeFull: 'COMT Met/Met' };
    case 'COMT_VAL_MET':
    case 'Val/Met':
      return { short: 'Intermediate', descriptive: 'Intermediate Risk', genotype: 'Val/Met', genotypeFull: 'COMT Val/Met' };
    case 'COMT_VAL_VAL':
    case 'Val/Val':
      return { short: 'Higher', descriptive: 'Higher Risk', genotype: 'Val/Val', genotypeFull: 'COMT Val/Val' };
    case 'COMT_INCONCLUSIVE':
    case 'inconclusive':
      return { short: 'Inconclusive', descriptive: 'Inconclusive', genotype: 'Inconclusive', genotypeFull: 'Inconclusive' };
    default:
      return { short: defaultText, descriptive: defaultText, genotype: defaultText, genotypeFull: defaultText };
  }
}

export function userHasGenetics(userProfile) {
  if (isNullOrUndefined(userProfile)) {
    return false;
  }

  if (!isNullOrUndefined(userProfile.akt1) || !isNullOrUndefined(userProfile.comt) || !isNullOrUndefined(userProfile.cyp2c9) ||
    !isNullOrUndefined(userProfile.cyp2c192) || !isNullOrUndefined(userProfile.cyp2c193) || !isNullOrUndefined(userProfile.cyp2c1917)) {
    return true;
  }
}
