export function pdfRawHtml() {
  return `
      <div id="print-header" class="print-container print-header">
          <div class="row justify-content-between mt-4">
              <div class="col-7 mt-2">
                  <div><img src="assets/img/halugen-logo.png" height="50px">
                      <div class="mt-3">
                          <h3 id="genetic-test" style="color: var(--survey-secondary);">Psychedelics Genetic Test</h3>
                      </div>
                  </div>
              </div>
              <div class="col-5">
                  <div class="row mt-2">
                      <div class="col-5">
                        <h6><strong>Subject ID:</strong></h6>
                        <h6><strong>Swab ID:</strong></h6>
                        <h6><strong>Test Date:</strong></h6>
                        <h6><strong>Location:</strong></h6>
                      </div>
                      <div class="col-7">
                        <h6>{{{display.initials}}}</h6>
                        <h6>{{{display.kitId}}}</h6>
                        <h6>{{{display.date}}}</h6>
                        <h6>{{{display.location}}}</h6>
                      </div>
                  </div>
              </div>
          </div>
          <hr style="border-width: 1px;border-color: var(--survey-secondary);">
      </div>
      <div id="print-footer" class="print-container print-footer">
          <hr style="border-width: 1px;border-color: var(--survey-secondary);">
          <div class="row">
              <div class="col-4 text-left">
                  <div class="row">
                      <div class="col-auto align-self-center" style="padding: 0px 0px 10px 10px;"><img src="assets/img/favicon.ico.png" style="height: 35px;"></div>
                      <div class="col">
                          <h6 class="text-uppercase text-primary">HaluGen Life Sciences Inc.</h6>
                          <h6 class="text-secondary">© 2022. All rights reserved.</h6>
                      </div>
                  </div>
              </div>
              <div class="col-4 text-center">
                  <h6 class="text-uppercase text-center text-primary">Psychedelics Genetic Test</h6>
                  <h6 class="text-center text-secondary">Report v1.4L</h6>
              </div>
              <div class="col-4 text-right">
                  <h6 class="text-uppercase text-primary">Subject ID:&nbsp;{{{display.initials}}}</h6>
                  <h6 class="text-right text-secondary">Location:&nbsp;{{{display.location}}}</h6>
              </div>
          </div>
      </div>
      <div class="table-responsive">
          <table class="table print-report-table">
              <thead>
                  <tr>
                      <th style="border-style: none;padding: 0px;">
                          <div class="page-header-space" style="margin-top: 175px"></div>
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td style="border-style: none;padding: 0px;">
                          <div id="print-body">
                              <div id="report-summary-page" class="print-container page">
                                  <section class="mt-0" style="margin-bottom: 2.7em">
                                      <h3 class="text-uppercase mb-3">Report Summary</h3>
                                      <p>The HaluGen Psychedelics Genetic Test is a pharmacogenomic test to help identify how an individual's DNA profile can affect their response to hallucinogenic drugs. The information contained in this report is based on research related to specific genetic polymorphisms and their influence on pharmacodynamics, pharmacokinetics and mental health risk factors for psychedelics use.</p>
                                  </section>
                                  <section class="mt-1 mb-3">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--genetics-primary);color: var(--white);padding: 12px;border-radius: 5px;">PharmacoDYNAMIC Genes</h4>
                                      <p class="mb-3">Genes that influence pharmacodynamics can alter gene receptors resulting in different responses to drugs such as sensitivity and adverse side effects. Your report includes a genetic marker for serotonin receptor density which can influence sensitivity to classical psychedelics and SSRIs. It also includes a genetic marker for ketamine response.</p>
                                      <div class="table-responsive">
                                          <table class="table gene-table">
                                              <thead>
                                                  <tr>
                                                      <th style="width: 10%;">Gene</th>
                                                      <th style="width: 10%;">Genotype</th>
                                                      <th style="width: 20%;">Result</th>
                                                      <th class="width: 60%;">Description</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td>HTR2A</td>
                                                      <td>{{{display.htr2a.genotype}}}</td>
                                                      <td>{{{display.htr2a.phenotypeDescriptive}}}</td>
                                                      <td>The HTR2A gene influences serotonin receptor density.</td>
                                                  </tr>
                                                  <tr>
                                                    <td>BDNF</td>
                                                    <td>{{{display.bdnf.genotype}}}</td>
                                                    <td>{{{display.bdnf.phenotypeDescriptive}}}</td>
                                                    <td>The BDNF gene influences ketamine response.</td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </section>
                                  <section class="mt-1 mb-3">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--primary);color: var(--white);padding: 12px;border-radius: 5px;">Pharmacokinetic Genes</h4>
                                      <p class="mb-3">Genes that influence pharmacokenetics can alter drug metabolism resulting in variability in drug blood concentration levels and therapeutic response. Your report includes metabolism genes for ketamine, LSD, MDMA and ayahuasca.</p>
                                      <div class="table-responsive">
                                          <table class="table gene-table">
                                              <thead>
                                                  <tr>
                                                  <th style="width: 10%;">Gene</th>
                                                  <th style="width: 10%;">Genotype</th>
                                                  <th>Phenotype</th>
                                                  <th style="width: 20%;">Result</th>
                                                  <th class="width: 60%;">Description</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td>CYP2B6</td>
                                                      <td>{{{display.cyp2b6.genotype}}}</td>
                                                      <td>{{{display.cyp2b6.phenotypeDescriptive}}}</td>
                                                      <td>{{{display.cyp2b6.result}}}</td>
                                                      <td>The CYP2B6 gene influences ketamine metabolism.</td>
                                                  </tr>
                                                  <tr>
                                                      <td>CYP2D6</td>
                                                      <td>{{{display.cyp2d6.genotype}}}</td>
                                                      <td>{{{display.cyp2d6.phenotypeDescriptive}}}</td>
                                                      <td>{{{display.cyp2d6.metabolismDescriptive}}}</td>
                                                      <td>The CYP2D6 gene influences LSD, MDMA and ayahuasca metabolism.</td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </section>
                                  <section class="mt-1 mb-3">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--survey-secondary);color: var(--white);padding: 12px;border-radius: 5px;">MENTAL HEALTH&nbsp;RISK Genes</h4>
                                      <p class="mb-3">Genes can influence short- and long-term risk factors with psychedelics drug use. Your report includes genetic risk markers for mental health effects.</p>
                                      <div class="table-responsive">
                                          <table class="table gene-table">
                                              <thead>
                                                  <tr>
                                                      <th style="width: 10%;">Gene</th>
                                                      <th style="width: 10%;">Genotype</th>
                                                      <th style="width: 20%;">Result</th>
                                                      <th class="width: 60%;">Description</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td>C4A</td>
                                                      <td>{{{display.c4a.genotype}}}</td>
                                                      <td>{{{display.c4a.phenotypeDescriptive}}}</td>
                                                      <td>The C4A gene is a mental health risk factor.</td>
                                                  </tr>
                                                  <tr>
                                                      <td>NRG1</td>
                                                      <td>{{{display.nrg1.genotype}}}</td>
                                                      <td>{{{display.nrg1.phenotypeDescriptive}}}</td>
                                                      <td>The NRG1 gene is a mental health risk factor.</td>
                                                  </tr>
                                                  <tr>
                                                      <td>DISC1</td>
                                                      <td>{{{display.disc1.genotype}}}</td>
                                                      <td>{{{display.disc1.phenotypeDescriptive}}}</td>
                                                      <td>The DISC1 gene is a mental health risk factor.</td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </section>
                              </div>
                              <div id="pharmacodynamic-page" class="print-container page page-header-space">
                                  <section class="mb-5">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--genetics-primary);color: var(--white);padding: 12px;border-radius: 5px;">HTR2A Gene · Serotonin Sensitivity</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">The way your body responds to classical psychedelics such as psilocybin, LSD and DMT, is influenced by the HTR2A gene. This gene influences the baseline expression of the 5-HTR2A serotonin receptor, which is activated by both serotonin and psychedelic drugs. This binding activation releases neurotransmitters in the brain which can induce potential hallucinations and other mental effects.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Genotype Result</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.htr2a.genotypeDescriptive}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">Approximately 20% of people carry an HTR2A genetic variant which can increase serotonin receptor density, potentially making them more susceptible to stronger hallucinogenic effects. These individuals should be more cautious when considering classical psychedelic therapies.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Psychedelics Sensitivity</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.htr2a.phenotype}}}&nbsp;{{{display.htr2a.phenotypeIcon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>HTR2A Genotype</th>
                                                              <th>HTR2A Serotonin Receptors</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>C/C</td>
                                                              <td>Reduced</td>
                                                          </tr>
                                                          <tr>
                                                              <td>C/T</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>T/T</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-8">
                                              <div class="row">
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Reduced&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;34&quot;,&quot;47&quot;,&quot;18&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;Reduced (34%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (47%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Increased (18%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Reduced&quot;,&quot;&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;East Asians&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;23&quot;,&quot;54&quot;,&quot;23&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;East Asians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;Reduced (23%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (54%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Increased (23%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Reduced&quot;,&quot;&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Indians&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;22&quot;,&quot;54&quot;,&quot;24&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Indians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;Reduced (22%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (54%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Increased (24%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>How do I respond to SSRIs?</h4>
                                              <p class="mb-0">Selective Serotonin Reuptake Inhibitors (SSRIs) are a common treatment for depression and anxiety. They are thought to increase serotonin levels in the brain by blocking the reabsorption ("reuptake") of serotonin. Studies have shown that individuals with reduced serotonin receptor density (HTR2A C/C genotype) are more prone to adverse drug reactions (ADRs) when taking SSRIs.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">SSRI&nbsp;Sensitivity</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.htr2a.ssri}}}&nbsp;{{{display.htr2a.ssriIcon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="pharmacodynamic-page1" class="print-container page page-header-space">
                                  <section class="mb-5">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--genetics-primary);color: var(--white);padding: 12px;border-radius: 5px;">BDNF GENE · KETAMINE RESPONSE</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">The way your body responds to ketamine is influenced by the BDNF gene. This gene influences the secretion of brain-derived neurotrophic factor (BDNF) protein, which is released when being dosed with ketamine. BDNF helps with the growth and maintenance of new neurons and synapses that are necessary for normal mood and brain connectivity. Stress and anxiety inhibit the production of BDNF which can lead to mood disorders such as PTSD and depression.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Genotype Result</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.bdnf.genotypeDescriptive}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">Individuals that carry the BDNF 'Met' genetic variant can have impaired secretion of BDNF. These individuals are more likely to have a decreased response to the antidepressant effects of ketamine therapy.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Ketamine Response</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.bdnf.phenotype}}}&nbsp;{{{display.bdnf.phenotypeIcon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>BDNF Genotype</th>
                                                              <th>Ketamine Response</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>Val/Val</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>Val/Met</td>
                                                              <td>Decreased</td>
                                                          </tr>
                                                          <tr>
                                                              <td>Met/Met</td>
                                                              <td>Very Decreased</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-8">
                                              <div class="row">
                                                  <div class="col-4">
                                                      <div>
                                                        <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Decreased&quot;,&quot;Very Decr&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#593196&quot;,&quot;rgba(252,57,57,0.7)&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;67&quot;,&quot;30&quot;,&quot;3&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                        <div class="text-center small mt-2">
                                                            <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal  (67%)</span></div>
                                                            <div><span><i class="fas fa-circle" style="color: rgba(252,57,57,0.7);"></i>&nbsp;Decreased  (30%)</span></div>
                                                            <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Decreased (3%)</span></div>
                                                        </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Decreased&quot;,&quot;Very Decr&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Africans&quot;,&quot;backgroundColor&quot;:[&quot;#593196&quot;,&quot;rgba(252,57,57,0.7)&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;93&quot;,&quot;7&quot;,&quot;0&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Africans&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (93%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: rgba(252,57,57,0.7);"></i>&nbsp;Decreased (7%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Decreased (0%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Decreased&quot;,&quot;Very Decr&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;East Asians&quot;,&quot;backgroundColor&quot;:[&quot;#593196&quot;,&quot;rgba(252,57,57,0.7)&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;26&quot;,&quot;50&quot;,&quot;24&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;East Asians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (26%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: rgba(252,57,57,0.7);"></i>&nbsp;Decreased (50%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Decreased (24%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="pharmacokinetic-page-1" class="print-container page page-header-space">
                                  <section class="mb-5">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--purple);color: var(--white);padding: 12px;border-radius: 5px;">CYP2B6 Gene · Ketamine Metabolism</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">The way your body metabolizes ketamine, a fast-acting anesthetic with hallucinogenic properties, is influenced by the CYP2B6 gene. This gene encodes a liver enzyme that helps metabolize ketamine in your bloodstream. 10–20% of people carry the CYP2B6*6 genetic variant which causes them to metabolize ketamine up to 2x to 3x slower than normal.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Genotype Result</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.cyp2b6.genotypeDescriptive}}}</h4>
                                              <h4 class="text-center">Phenotype Result</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.cyp2b6.phenotypeDescriptive}}}</h4>
                                            </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">‘Slow metabolizers’ should be more cautious when being dosed with ketamine, as they can experience an increased duration, intensity of effect and averse drug reactions, especially when taking ketamine in intravenous or subcutaneous form.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Ketamine Metabolism</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.cyp2b6.phenotype}}}&nbsp;{{{display.cyp2b6.phenotypeIcon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>CYP2B6 Genotype</th>
                                                              <th>CYP2B6 Metabolism Status</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>*1/*1</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>*1/*6</td>
                                                              <td>Slow</td>
                                                          </tr>
                                                          <tr>
                                                              <td>*6/*6</td>
                                                              <td>Very Slow</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-8">
                                              <div class="row">
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Slow&quot;,&quot;Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#593196&quot;,&quot;rgba(252,57,57,0.7)&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;48&quot;,&quot;43&quot;,&quot;9&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (48%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: rgba(252,57,57,0.7);"></i>&nbsp;Slow (43%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Slow (9%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Slow&quot;,&quot;Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Africans&quot;,&quot;backgroundColor&quot;:[&quot;#593196&quot;,&quot;rgba(252,57,57,0.7)&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;32&quot;,&quot;46&quot;,&quot;22&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Africans&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                      <div class="text-center small mt-2">
                                                          <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (32%)</span></div>
                                                          <div><span><i class="fas fa-circle" style="color: rgba(252,57,57,0.7);"></i>&nbsp;Slow (46%)</span></div>
                                                          <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Slow (22%)</span></div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Slow&quot;,&quot;Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;East Asians&quot;,&quot;backgroundColor&quot;:[&quot;#593196&quot;,&quot;rgba(252,57,57,0.7)&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;46&quot;,&quot;41&quot;,&quot;13&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;East Asians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                      <div class="text-center small mt-2">
                                                          <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (46%)</span></div>
                                                          <div><span><i class="fas fa-circle" style="color: rgba(252,57,57,0.7);"></i>&nbsp;Slow (41%)</span></div>
                                                          <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Slow (13%)</span></div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="pharmacokinetic-page-2" class="print-container page page-header-space">
                                  <section>
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--purple);color: var(--white);padding: 12px;border-radius: 5px;">CYP2D6 Gene · LSD, MDMA &amp; AYAHUASCA Metabolism</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">The way your body metabolizes LSD, MDMA and ayahuasca, is influenced by the CYP2D6 gene. This gene encodes a liver enzyme that helps metabolize these drugs in your bloodstream. 5–10% of people carry the CYP2D6 genetic variant which causes them to metabolize LSD, MDMA and ayahuasca up to 2x slower than normal.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Genotype Result</h4>
                                              <h4 class="text-center mb-4" style="color: var(--genetics-primary);">{{{display.cyp2d6.genotype}}}</h4>
                                              <h4 class="text-center">Phenotype Result</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.cyp2d6.phenotypeDescriptive}}}</h4>
                                            </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">Individuals with the ‘poor metabolizer’ phenotype should be more cautious when being dosed with LSD, MDMA and ayahuasca, as they can experience an increased duration, intensity of effect and adverse drug reactions when taken in oral form.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Metabolism</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.cyp2d6.metabolism}}}&nbsp;{{{display.cyp2d6.metabolismIcon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>CYP2D6&nbsp; Phenotype</th>
                                                              <th>CYP2D6 Metabolism Status</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>Ultrarapid</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                          <tr>
                                                              <td>Intermediate /Normal</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>Poor</td>
                                                              <td>Very Slow</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-8">
                                              <div class="row">
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;2&quot;,&quot;91&quot;,&quot;7&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;Increased(2%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (91%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Slow (7%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Very Slow&quot;,&quot;&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Africans&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;3&quot;,&quot;95&quot;,&quot;2&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Africans&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;Increased (3%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (95%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Slow (2%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Reduced&quot;,&quot;&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;East Asians&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;2&quot;,&quot;97&quot;,&quot;1&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;East Asians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;Increased (2%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;Normal (97%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Slow (1%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="mental-health-risk-page-1" class="print-container page page-header-space">
                                  <section class="mb-5">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--survey-secondary);color: var(--white);padding: 12px;border-radius: 5px;">C4A GENE Copy Number Variation · Mental Health Risk</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">The C4A gene expresses a protein that is responsible for synaptic pruning in the brain. This is a normal process by which extra synapses, starting in childhood and adolescence, are eliminated to make way for more complex synapses in adulthood. An individual can have different C4A gene copy number variations (CNV), typically from 0 to 5 copies.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">CNV Result</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.c4a.genotypeDescriptive}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">Having an increased number of C4A gene copies (4 or 5) causes more C4A protein expression that is responsible for synaptic pruning. It is believed that increased disorderly synaptic pruning can be a contributing factor to a higher risk of mental health disorders such as psychosis, bipolar and schizophrenia.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Risk</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.c4a.phenotype}}}&nbsp;{{{display.c4a.phenotypeIcon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>C4A CNV</th>
                                                              <th>Mental Health Risk</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>0-1</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>2-3</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>4-5</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-6">
                                              <div class="row">
                                                  <div class="col-6">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;22&quot;,&quot;75&quot;,&quot;3&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;0-1 Copies (22%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;2-3 Copies (75%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;4-5 Copies (3%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-6">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Reduced&quot;,&quot;&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;East Asians&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;7&quot;,&quot;89&quot;,&quot;4&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;East Asians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;0-1 Copies (7%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;2-3 Copies (89%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;4-5 Copies (4%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="mental-health-risk-page-2" class="print-container page page-header-space">
                                  <section>
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--survey-secondary);color: var(--white);padding: 12px;border-radius: 5px;">NRG1 Gene · Mental Health Risk</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">The neuregulin 1 (NRG1) gene expresses NRG1 proteins that stimulates the EGFR receptors which promotes neuron cell development as well as myelin production. NRG1 plays an important role in synaptic plasticity of the brain, which influences short-term learning and long-term memory.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Genotype Result</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.nrg1.genotypeDescriptive}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">Having the NRG1 T/T genotype can cause an over-expression of NRG1 proteins leading to reduced synaptic plasticity and disruption in neuron connections in the brain. The T/T genotype has been linked as a risk factor for mental health disorders such as psychosis, bipolar and schizophrenia.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Risk</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.nrg1.phenotype}}}&nbsp;{{{display.nrg1.phenotypeIcon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>NRG1 Genotype</th>
                                                              <th>Mental Health Risk</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>C/C</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>C/T</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>T/T</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-6">
                                              <div class="row">
                                                  <div class="col-6">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;37&quot;,&quot;48&quot;,&quot;15&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:true,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;C/C Normal (37%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;C/T Normal (48%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;T/T Increased (15%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-6">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Reduced&quot;,&quot;&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Africans&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;40&quot;,&quot;46&quot;,&quot;14&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Africans&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;C/C Normal (40%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;C/T Normal (46%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;T/T Increased (14%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="mental-health-risk-page-3" class="print-container page page-header-space">
                                  <section>
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--survey-secondary);color: var(--white);padding: 12px;border-radius: 5px;">DISC1 Gene · Mental Health Risk</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">The disrupted in schizophrenia (DISC1) gene expresses DISC1 proteins that are involved in important processes that regulate nervous system and brain development. It is a well-established genetic risk factor amongst different populations and ethnicities for psychiatric disorders.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Genotype Result</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.disc1.genotypeDescriptive}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">Having the DISC1 T/T genotype has been shown to increase the risk for mental health disorders such as psychosis, bipolar and schizophrenia.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Risk</h4>
                                              <h4 class="text-center" style="color: var(--genetics-primary);">{{{display.disc1.phenotype}}}&nbsp;{{{display.disc1.phenotypeIcon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>DISC1 Genotype</th>
                                                              <th>Mental Health Risk</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>A/T</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>A/T</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>T/T</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-8">
                                              <div class="row">
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Reduced&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;52&quot;,&quot;40&quot;,&quot;8&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:true,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#444&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;A/A Normal (52%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;A/T Normal (40%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;T/T Increased (8%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Reduced&quot;,&quot;&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Africans&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;45&quot;,&quot;44&quot;,&quot;11&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Africans&quot;,&quot;fontColor&quot;:&quot;#444&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;A/A Normal (45%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;A/T Normal (44%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;T/T Increased (11%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-5"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Reduced&quot;,&quot;&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;East Asians&quot;,&quot;backgroundColor&quot;:[&quot;#385aef&quot;,&quot;#593196&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;69&quot;,&quot;28&quot;,&quot;3&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;East Asians&quot;,&quot;fontColor&quot;:&quot;#444&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;Poppins, sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: var(--blue);"></i>&nbsp;A/A Normal (69%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--primary);"></i>&nbsp;A/T Normal (28%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;T/T Increased (3%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="disclaimer-page" class="print-container page-header-space">
                                  <section class="mt-0 mb-5">
                                      <h5 class="text-uppercase">TESTING METHODOLOGY</h5>
                                      <p>Analytical results were produced using tests developed and validated by HaluGen Life Sciences Inc. These tests have not be cleared or approved by the U.S. Food and Drug Administration or Health Canada. Genetic testing was completed at a laboratory located in Canada at 8-5288 General Road, Mississauga, Ontario, L4W 1Z8.</p>
                                      <p>For Single Nucleotide Polymorphism (SNP) and Copy Number Variation (CNV) status results, Genomic DNA was isolated and analyzed using PCR techniques with Thermo Fisher TaqMan® probe-based methods. Testing does not detect for all genetic variations nor does absence of a detectable variant rule out the presence of other, non-detected variants. *1 is the reference allele for pharmacokinetic genes and is reported by default if other alleles are not detected. In cases where observed variants are associated with more than one haplotype and/or phenotype, HaluGen may infer and report the most likely diplotype and/or phenotype based on published allele frequency and/or ethnicity data.<br></p>
                                      <p>The following genetic variants may be detected: HTR1A (rs6295); HTR2A (rs6311, rs6313); BDNF (rs6265); CYP2B6 *6; CYP2D6 *2, *3, *4, *5, *6, *9, *10, *17, *29, *41, CNV (intron 6, exon 9); C4A CNV; NRG1 (rs6994992); DISC1 (rs821616).</p>
                                  </section>
                                  <section class="mb-0">
                                      <h5 class="text-uppercase">Disclaimer</h5>
                                      <p>This report is for informational purposes only and does not and is not intended to convey medical advice and does not constitute the practice of medicine. You should not rely on this information as a substitute for, nor does it replace, professional medical advice, diagnosis, or treatment. HaluGen is not responsible for any actions or inaction on an individuals’s part based on the information that is presented in this report.</p>
                                      <p>At the time of the generation of this report, the information is believed to be current and based on published research available to the scientific community. However, research, and the scientific consensus of the interpretation of such research data, can evolve and change over time. This report is therefore dependent on the date it was created. The information and data in this report is provided "as is", without warranties of any kind, express or implied, including without limitation, the implied warranties of merchantability and fitness for a particular purpose.</p>
                                      <p>The use of any psychedelic drug can come with potential health risks and legal consequences. All individuals should do their own research as well as consult with a healthcare professional prior to considering the use of any psychedelic drug to ensure the appropriateness and legality of any psychedelic therapy within your jurisdiction.<br></p>
                                  </section>
                              </div>
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  `;
}
