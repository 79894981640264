import React from 'react'
import { Field } from 'formik';
import { Col, Label, Input } from 'reactstrap';
import { requiredStar, requiredField } from './formHelpers';

const fieldErrorClass = 'is-invalid';
const fieldSuccessClass = 'is-valid';
const fieldClass = 'border border-secondary';
const errorTextClass = 'text-danger';
const fieldValidationClasses = (field, touched, errors, values, serverErrorFields) => {
  return(`form-control ${
    serverErrorFields && serverErrorFields[field] ? fieldErrorClass :
      touched[field] ? (
        errors[field] ? fieldErrorClass : (
          values[field] ? fieldSuccessClass : fieldClass
        )
      ) : fieldClass
    }`
  )
};
const xs = 12;
const sm = 6;
const md = 4;
const lg = 4;

export function TextField(props) {
  const {name, field, placeholder, errors, touched, fullWidth, halfWidth, values, validationSchema, requiredOverride, serverErrorFields} = props;
  const required = requiredField(validationSchema, field, requiredOverride);
  return (
    <Col xs={xs} sm={fullWidth ? 12 : halfWidth ? 6 : sm} md={fullWidth ? 12 : halfWidth ? 6 : md} lg={fullWidth ? 12 : halfWidth ? 6 : lg}>
      <label htmlFor={field} className="col-form-label text-secondary">{name}{required && requiredStar}</label>
      <Field type="text" color="primary" id={field} name={field} placeholder={placeholder} className={fieldValidationClasses(field, touched, errors, values, serverErrorFields)} autoComplete="off" />
      { touched[field] && errors[field] && <div className={errorTextClass}>{errors[field]}</div> }
      { serverErrorFields && serverErrorFields[field] && <div className={errorTextClass}>{serverErrorFields[field]}</div> }
    </Col>
  )
}

export function HiddenField(props) {
  const {field} = props;
  return (
    <Field type="hidden" id={field} name={field} />
  )
}

export function PasswordField(props) {
  const {name, field, placeholder, errors, touched, fullWidth, halfWidth, values, validationSchema, requiredOverride, serverErrorFields} = props;
  const required = requiredField(validationSchema, field, requiredOverride);
  return (
    <Col xs={xs} sm={fullWidth ? 12 : halfWidth ? 6 : sm} md={fullWidth ? 12 : halfWidth ? 6 : md} lg={fullWidth ? 12 : halfWidth ? 6 : lg}>
      <label htmlFor={field} className="col-form-label text-secondary">{name}{required && requiredStar}</label>
      <Field type="password" id={field} name={field} placeholder={placeholder} className={fieldValidationClasses(field, touched, errors, values, serverErrorFields)} />
      { touched[field] && errors[field] && <div className={errorTextClass}>{errors[field]}</div> }
      { serverErrorFields && serverErrorFields[field] && <div className={errorTextClass}>{serverErrorFields[field]}</div> }
    </Col>
  )
}

export function FileField(props) {
  const {name, field, errors, touched, fullWidth, halfWidth, setFieldValue} = props;
  return (
    <Col xs={xs} sm={fullWidth ? 12 : halfWidth ? 6 : sm} md={fullWidth ? 12 : halfWidth ? 6 : md} lg={fullWidth ? 12 : halfWidth ? 6 : lg}>
      <label htmlFor={field} className="col-form-label text-secondary">{name}</label><br />
      <Input
        type="file" id={field} name={field}
        onChange={(e) => setFieldValue(field, e.target.files[0])}
        className={`${touched[field] && errors[field] ? fieldErrorClass : fieldClass}`}
      />
      { touched[field] && errors[field] && <div className={errorTextClass}>{errors[field]}</div> }
    </Col>
  )
}

export function TimeField(props) {
  const {name, field, errors, touched, values, validationSchema, requiredOverride, serverErrorFields} = props;
  const required = requiredField(validationSchema, field, requiredOverride);
  return (
    <Col style={{paddingRight: '2em'}}>
      <label htmlFor={field} className="col-form-label text-secondary">{name}{required && requiredStar}</label>
      <Field type="time" id={field} name={field} className={fieldValidationClasses(field, touched, errors, values, serverErrorFields)} style={{minWidth: 100}}/>
      { touched[field] && errors[field] && <div className={errorTextClass}>{errors[field]}</div> }
      { serverErrorFields && serverErrorFields[field] && <div className={errorTextClass}>{serverErrorFields[field]}</div> }
    </Col>
  )
}

export function ReadonlyField(props) {
  const {name, value, fullWidth, halfWidth} = props;
  return (
    <Col xs={xs} sm={fullWidth ? 12 : halfWidth ? 6 : sm} md={fullWidth ? 12 : halfWidth ? 6 : md} lg={fullWidth ? 12 : halfWidth ? 6 : lg}>
      <label className="col-form-label text-muted">{name}</label>
      <input type="text" readOnly value={value} className="form-control text-muted" />
    </Col>
  )
}

export function SelectField(props) {
  const {name, field, selectText, options, errors, touched, fullWidth, halfWidth, values, validationSchema, requiredOverride, serverErrorFields, multiple=false} = props;
  const required = requiredField(validationSchema, field, requiredOverride);
  return (
    <Col xs={xs} sm={fullWidth ? 12 : halfWidth ? 6 : sm} md={fullWidth ? 12 : halfWidth ? 6 : md} lg={fullWidth ? 12 : halfWidth ? 6 : lg}>
      <label htmlFor={field} className="col-form-label text-secondary">{name}{required && requiredStar}</label>
      <Field multiple={multiple} as="select" id={field} name={field} className={fieldValidationClasses(field, touched, errors, values, serverErrorFields) + (!values[field].length ? ' notSelected' : '')}>
        {selectText.length && <option value="" className="notSelected" label={selectText} key={0} />}
        {options.map((option, i) => (<option value={option.value} label={option.label} key={i} />))}
      </Field>
      { touched[field] && errors[field] && <div className={errorTextClass}>{errors[field]}</div> }
      { serverErrorFields && serverErrorFields[field] && <div className={errorTextClass}>{serverErrorFields[field]}</div> }
    </Col>
  )
}

export function CheckboxField(props) {
  const {name, field, fullWidth, halfWidth, inline=true} = props;
  return (
    <Col xs={xs} sm={fullWidth ? 12 : halfWidth ? 6 : sm} md={fullWidth ? 12 : halfWidth ? 6 : md} lg={fullWidth ? 12 : halfWidth ? 6 : lg} className="form-check">
      { inline ?
        <Label check className="col-form-label text-secondary" style={{height: '50px'}}>
          <Field type="checkbox" name={field} className="form-check-input m-0" style={{margin: '10px 0 0 1px', width: '28px', height: '28px'}} />
          <span style={{marginLeft: '36px', lineHeight: '30px'}}>{name}</span>
        </Label>
        :
        <Label check className="col-form-label text-secondary" style={{height: '70px'}}>
          {name}<br />
          <Field type="checkbox" name={field} className="form-check-input" style={{margin: '10px 0 0 1px', width: '28px', height: '28px'}} />
        </Label>
      }
      </Col>
  )
}

export function TextareaField(props) {
  const {name, field, rows=8, errors, touched, fullWidth, halfWidth, values, validationSchema, requiredOverride, serverErrorFields} = props;
  const required = requiredField(validationSchema, field, requiredOverride);
  return (
    <Col xs={xs} sm={fullWidth ? 12 : halfWidth ? 6 : sm} md={fullWidth ? 12 : halfWidth ? 6 : md} lg={fullWidth ? 12 : halfWidth ? 6 : lg}>
      <label htmlFor={field} className="col-form-label text-secondary">{name}{required && requiredStar}</label>
      <Field component="textarea" rows={rows} id={field} name={field} className={fieldValidationClasses(field, touched, errors, values, serverErrorFields)} />
      { touched[field] && errors[field] && <div className={errorTextClass}>{errors[field]}</div> }
      { serverErrorFields && serverErrorFields[field] && <div className={errorTextClass}>{serverErrorFields[field]}</div> }
    </Col>
  )
}

export function DateField(props) {
  const {name, field, errors, touched, fullWidth, halfWidth, values, validationSchema, requiredOverride, serverErrorFields} = props;
  const required = requiredField(validationSchema, field, requiredOverride);
  return (
    <Col xs={xs} sm={fullWidth ? 12 : halfWidth ? 6 : sm} md={fullWidth ? 12 : halfWidth ? 6 : md} lg={fullWidth ? 12 : halfWidth ? 6 : lg}>
      <label htmlFor={field} className="col-form-label text-secondary">{name}{required && requiredStar}</label>
      <Field type="date" id={field} name={field} className={fieldValidationClasses(field, touched, errors, values, serverErrorFields)} />
      { touched[field] && errors[field] && <div className={errorTextClass}>{errors[field]}</div> }
      { serverErrorFields && serverErrorFields[field] && <div className={errorTextClass}>{serverErrorFields[field]}</div> }
    </Col>
  )
}
