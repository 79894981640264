import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {withNamespaces} from 'react-i18next';
import {toggleRightSidebar} from '../../actions/layout'
import ProfileMenuContainer from "../CommonForBoth/TopbarDropdown/ProfileMenuContainer";

const maxMobileWidth = 992;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      screenSize: {
        width: undefined,
        height: undefined,
      }
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  handleResize() {
    this.setState({screenSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    }});
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.handleResize(this.setState));
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toggleSearch = () => {
    this.setState({isSearch: !this.state.isSearch});
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
   toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    const isMobile = this.state.screenSize.width <= maxMobileWidth;
    return (
      <React.Fragment>
        <header id="page-topbar" style={
          isMobile ? {height: "70px", background: "linear-gradient(to bottom, #7202BB, #3264F5)"}
          : {height: "140px", background: "linear-gradient(to bottom, #7202BB, #3264F5)"}
        }>
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span style={{color: 'white', marginTop: "140px", fontSize: "24px", whiteSpace: "nowrap"}}>
                    Clinical Dashboard
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>
            <div className="d-flex">

              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.props.actionClearAllTabs}
                  data-toggle="fullscreen"
                  title="Reset all tabs and filters"
                >
                  <i className="mdi mdi-reload"></i>
                </button>
              </div>

              <div className="dropdown d-none d-lg-inline-block ml-1" style={{marginTop: "2px"}}>
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                  title="Full screen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>

              <div className="dropdown d-lg-inline-block ml-1" style={{marginTop: "1px"}}>
                <Link to="/logout" style={{background: "none"}}>
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Logout"
                  >
                    <i className="mdi mdi-logout"></i>
                  </button>
                </Link>
              </div>

            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const {layoutType} = state.Layout;
  return {layoutType};
};

export default connect(mapStatetoProps, {toggleRightSidebar})(withNamespaces()(Header));
