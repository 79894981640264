const initialState = {
  modals: {
    assignScanner: {},
    editScanner: {},
    viewScanner: {},
    editPatient: {},
    viewPatient: {},
    editLocation: {},
    viewLocation: {},
    trials: {},
    locations: {},
    newTrialCreated: false,
    newScannerCreated: false
  },
  tabs: {
    dashboard: {},
    groups: {},
    testresults: {},
    scanners: {},
  }
};

export const Flags = (state = initialState, action) => {
  switch (action.type) {
    case 'MODALS':
      return {
        ...state,
        modals: action.payload
      };
    case 'TABS':
      /*
      const newTabsState = {
        ...state.tabs,
        [action.page]: {
          ...state.tabs[action.page],
          [action.tabs]: {
            ...state.tabs[action.page][action.tabs],
            selected: action.tab
          }
        }
      };
      */
      const newTabsState = {
        ...state.tabs,
        groups: {
          ...state.tabs[action.page],
          [action.tabs]: {
            ...state.tabs[action.page][action.tabs],
            selected: action.tab
          }
        },
        scanners: {
          ...state.tabs[action.page],
          [action.tabs]: {
            ...state.tabs[action.page][action.tabs],
            selected: action.tab
          }
        },
        testresults: {
          ...state.tabs[action.page],
          [action.tabs]: {
            ...state.tabs[action.page][action.tabs],
            selected: action.tab
          }
        },
      };
      window.sessionStorage.setItem("tabs", JSON.stringify(newTabsState));
      return { ...state, tabs: newTabsState };
    case 'TEST_TABS':
      const newTestTabsState = {
        ...state.tabs,
        [action.page]: {
          ...state.tabs[action.page],
          tests: {
            ...state.tabs[action.page].tests,
            [action.mainTab]: action.testTab
          }
        }
      };
      window.sessionStorage.setItem("tabs", JSON.stringify(newTestTabsState));
      return { ...state, tabs: newTestTabsState };
    case 'TAB_LOCATIONS':
      const newTabLocationsState = {
        ...state.tabs,
        [action.page]: {
          ...state.tabs[action.page],
          locations: {
            ...state.tabs[action.page].locations,
            [action.tab]: action.locations
          }
        }
      };
      window.sessionStorage.setItem("tabs", JSON.stringify(newTabLocationsState));
      return { ...state, tabs: newTabLocationsState };
    case 'LOAD_TABS':
      return { ...state, tabs: action.tabs };
    case 'CLEAR_ALL_TABS':
      window.sessionStorage.setItem("tabs", JSON.stringify(initialState.tabs));
      return { ...state, tabs: initialState.tabs };
    case 'RESET_LAST_CREATED_TRIAL':
      return {
        ...state,
        modals: {
          ...state.modals,
          newTrialCreated: false
        }
      };
    case 'RESET_LAST_CREATED_SCANNER':
      return {
        ...state,
        modals: {
          ...state.modals,
          newScannerCreated: false
        }
      };
    default:
      return state;
  }
};
