import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CannabisReport from '../../components/Trials/Reports/Cannabis';
import { actionFetchAllSwabHistory } from '../../actions/trials';

export default function CannabisReportPage() {
  const dispatch = useDispatch();
  const { dataId, trialId, type } = useParams();
  const { trials, swabHistory } = useSelector(state => state.Trials);
  const thisTrial = trials.find((t) => t.id == trialId) || {};
  const types = {
    psychedelic: 'psychedelicsTests',
    cbd: 'cbdCannabisTests',
    thc: 'thcCannabisTests',
  };
  const data = (swabHistory && swabHistory[types[type]] && swabHistory[types[type]].find((d) => d.id == dataId)) || {};
  useEffect(() => { dispatch(actionFetchAllSwabHistory(trialId)) }, [trialId] );
  return (
    <div className="page-content" style={{backgroundColor: "white"}}>
      <div className="pt-4">
        <CannabisReport data={data} trial={thisTrial} />
      </div>
    </div>
  );
}
