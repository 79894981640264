import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next'
import { Col, Row, Button } from 'reactstrap'
import { CenteredModal, setModal } from '../../components/Common/Modals';
import { CreateTrialForm, NewTrialCreatedForm } from '../../components/Form/Forms';
import { TrialTabs } from '../../components/Trials/Tabs';
import { actionResetLastCreatedTrial } from '../../actions/flags';
import { RefreshDataContext } from '../../App';

function Trials() {
  const dispatch = useDispatch();
  const [ refreshData, setRefreshData, reportCanvas, setReportCanvas, triggerNewTrialModal, setTriggerNewTrialModal ] = useContext(RefreshDataContext);
  const { trials, lastCreatedTrial, lastCreatedScanner } = useSelector(state => state.Trials);
  const { modals } = useSelector(state => state.Flags);
  useEffect(() => { if(lastCreatedTrial && modals.createTrial) {
    setModal(dispatch, modals, 'createTrial', false);
  } else {return} }, [lastCreatedTrial, modals, dispatch]);
  useEffect(() => { if(lastCreatedTrial && !modals.newTrialCreated) {
    setModal(dispatch, modals, 'newTrialCreated', true);
  } else {return} }, [lastCreatedTrial, modals, dispatch]);
  useEffect(() => { if(lastCreatedScanner && !modals.newScannerCreated) {
    setModal(dispatch, modals, 'newScannerCreated', true);
  } else {return} }, [lastCreatedScanner, modals, dispatch]);
  const [screenSize, setScreenSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => { if(triggerNewTrialModal) {
    setTriggerNewTrialModal(false);
    setModal(dispatch, modals, 'createTrial', true);
  } else {return} }, []);

  return (
    <div className="page-content">
      <div className="pt-5">
        <Row>
          <Col xl="6">
            <h3 className="mb-3 mt-0">Groups and Locations</h3>
          </Col>
          <Col xl="6">
            <Button
              color="primary"
              className="actionButton btn float-right"
              onClick={() => setModal(dispatch, modals, 'createTrial', true)}
            >{screenSize.width > 530 ? 'Add a New Group' : <i className="mdi mdi-star-outline"></i>}</Button>
          </Col>
        </Row>
        <Row>
          <Col xl="12" className="mt-4">
            <TrialTabs trials={trials} tables={['trials']} formModals={['createLocation','assignScanner']} />
          </Col>
        </Row>
      </div>
      <CenteredModal title="Create a new group" modals={modals} setModal={setModal} type="createTrial">
        <CreateTrialForm closeModal={() => setModal(dispatch, modals, 'createTrial', false)} trials={trials} />
      </CenteredModal>
      <CenteredModal title="New group created" modals={modals} setModal={() => {
        dispatch(actionResetLastCreatedTrial());
        setModal(dispatch, modals, 'newTrialCreated')
      }} type="newTrialCreated">
        <NewTrialCreatedForm closeModal={() => {
          dispatch(actionResetLastCreatedTrial());
          setModal(dispatch, modals, 'newTrialCreated', false);
        }} trial={trials.filter(t => t.id === lastCreatedTrial)} />
      </CenteredModal>
    </div>
  );
}

export default withNamespaces()(Trials);
